import { useState } from "react";
import OnePageComponent from "./OnePageComponent";
import { pdfjs, Document, Page } from "react-pdf";
import mainPDF from "src/assets/pdf.pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const OnePageContainer = ({
  nextPage,
  companyName,
  isUpdate,
  currentPage,
}: any) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <OnePageComponent />
    </div>
  );
};

export default OnePageContainer;
