import React from "react";
import { FourPageTypes } from "gita";

interface FourPageComponentProps {
  fourPageData: FourPageTypes;
  setFourPageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

const FourPageComponent = ({
  fourPageData,
  setFourPageData,
  setisUpdate,
}: FourPageComponentProps) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "297mm" }}>
        <div
          className='hpN'
          style={{
            left: "99.27mm",
            top: "282mm",
            width: "11.46mm",
            height: "3.53mm",
          }}
        >
          <span className='hrt cs0'>- 4 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps52'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.19mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                6. 귀사가 융자사업을 신청한 과제는 다음 중 어느 유형에
                해당됩니까?
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "4.86mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>&nbsp;&nbsp;(</span>
              <span className='hrt cs134'>
                ※선정 과제가 2개 이상일 경우는 가장 최근 과제를 기준으로 유형을
                판단)
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "11.46mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>&nbsp;</span>
              <span className='hrt cs1'>
                <label>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);
                      setFourPageData({
                        ...fourPageData,
                        six: "1",
                      });
                    }}
                    checked={fourPageData.six === "1"}
                    name='question_6'
                  />{" "}
                  기존 제품의 개선 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </label>
                <label>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);
                      setFourPageData({
                        ...fourPageData,
                        six: "2",
                      });
                    }}
                    checked={fourPageData.six === "2"}
                    name='question_6'
                  />
                  &nbsp; 기존 서비스의 개선 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </label>
                <label>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);
                      setFourPageData({
                        ...fourPageData,
                        six: "3",
                      });
                    }}
                    checked={fourPageData.six === "3"}
                    name='question_6'
                  />
                  &nbsp; 기존 공정의 개선 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </label>
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "18.07mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;
                <label>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);
                      setFourPageData({
                        ...fourPageData,
                        six: "4",
                      });
                    }}
                    checked={fourPageData.six === "4"}
                    name='question_6'
                  />{" "}
                  새로운 제품의 개발 &nbsp;&nbsp;&nbsp;
                </label>
                <label>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);
                      setFourPageData({
                        ...fourPageData,
                        six: "5",
                      });
                    }}
                    checked={fourPageData.six === "5"}
                    name='question_6'
                  />{" "}
                  새로운 서비스의 개발 &nbsp;&nbsp;&nbsp;&nbsp;
                </label>
                <label>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);
                      setFourPageData({
                        ...fourPageData,
                        six: "6",
                      });
                    }}
                    checked={fourPageData.six === "6"}
                    name='question_6'
                  />
                  &nbsp; 새로운 공정의 개발
                </label>
              </span>
            </div>
            <div
              className='hls ps65'
              style={{
                lineHeight: "2.63mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "24.69mm",
                height: "3.35mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "30.36mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>7</span>
              <span className='hrt cs1'>. 귀사는&nbsp;</span>
              <span className='hrt cs119'>동종업계와 비교</span>
              <span className='hrt cs1'>하여&nbsp;</span>
              <span className='hrt cs9'>국내기관과의 기술협력활동</span>
              <span className='hrt cs25'>을</span>
              <span className='hrt cs1'>&nbsp;얼마나 활발하게 수행하</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "36.97mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                고 있는지 응답해 주시기 바랍니다(5점 척도를 기준으로 응답).
              </span>
            </div>
            <div
              className='hls ps111'
              style={{
                lineHeight: "30.8mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "43.77mm",
                height: "30.8mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "140.78mm",
                  top: "0.5mm",
                  height: "30.8mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 145.78 35.80'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "145.78mm",
                    height: "35.8mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_30'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_30)'
                    d='M0,0L68.72,0L68.72,8.34L0,8.34L0,0Z '
                  />
                  <path
                    fill='url(#w_30)'
                    d='M68.72,0L82.93,0L82.93,8.34L68.72,8.34L68.72,0Z '
                  />
                  <path
                    fill='url(#w_30)'
                    d='M82.93,0L97.15,0L97.15,8.34L82.93,8.34L82.93,0Z '
                  />
                  <path
                    fill='url(#w_30)'
                    d='M97.15,0L111.36,0L111.36,8.34L97.15,8.34L97.15,0Z '
                  />
                  <path
                    fill='url(#w_30)'
                    d='M111.36,0L125.57,0L125.57,8.34L111.36,8.34L111.36,0Z '
                  />
                  <path
                    fill='url(#w_30)'
                    d='M125.57,0L139.79,0L139.79,8.34L125.57,8.34L125.57,0Z '
                  />
                  <path
                    d='M0,0 L0,29.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M68.72,0 L68.72,29.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M139.79,0 L139.79,29.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L139.99,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,8.34 L139.99,8.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,15.49 L139.99,15.49'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,22.65 L139.99,22.65'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,29.81 L139.99,29.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M139.79,0 L139.79,29.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,29.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,29.81 L139.99,29.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L139.99,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "68.72mm",
                    height: "8.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.91mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "67.73mm",
                        }}
                      >
                        <span className='hrt cs129'>항목</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "68.72mm",
                    top: "0mm",
                    width: "14.21mm",
                    height: "8.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.5mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "13.22mm",
                        }}
                      >
                        <span className='hrt cs60'>매우</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "13.22mm",
                        }}
                      >
                        <span className='hrt cs60'>적음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.93mm",
                    top: "0mm",
                    width: "14.21mm",
                    height: "8.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.08mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "13.22mm",
                        }}
                      >
                        <span className='hrt cs60'>적음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "97.15mm",
                    top: "0mm",
                    width: "14.21mm",
                    height: "8.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.08mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "13.22mm",
                        }}
                      >
                        <span className='hrt cs60'>보통</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "111.36mm",
                    top: "0mm",
                    width: "14.21mm",
                    height: "8.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.08mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "13.22mm",
                        }}
                      >
                        <span className='hrt cs60'>활발</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "125.57mm",
                    top: "0mm",
                    width: "14.21mm",
                    height: "8.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.5mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "13.22mm",
                        }}
                      >
                        <span className='hrt cs60'>매우</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "13.22mm",
                        }}
                      >
                        <span className='hrt cs60'>활발</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "8.34mm",
                    width: "68.72mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps98'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "67.73mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          국내 연구소와 기술협력활동
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "68.72mm",
                    top: "8.34mm",
                    width: "71.07mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.5mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "70.08mm",
                        }}
                      >
                        <span className='hrt cs135'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                seven: {
                                  ...fourPageData.seven,
                                  one: "1",
                                },
                              });
                            }}
                            checked={fourPageData.seven.one === "1"}
                            name='question_7_lab'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                seven: {
                                  ...fourPageData.seven,
                                  one: "2",
                                },
                              });
                            }}
                            checked={fourPageData.seven.one === "2"}
                            name='question_7_lab'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                seven: {
                                  ...fourPageData.seven,
                                  one: "3",
                                },
                              });
                            }}
                            checked={fourPageData.seven.one === "3"}
                            name='question_7_lab'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                seven: {
                                  ...fourPageData.seven,
                                  one: "4",
                                },
                              });
                            }}
                            checked={fourPageData.seven.one === "4"}
                            name='question_7_lab'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                seven: {
                                  ...fourPageData.seven,
                                  one: "5",
                                },
                              });
                            }}
                            checked={fourPageData.seven.one === "5"}
                            name='question_7_lab'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "15.49mm",
                    width: "68.72mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps98'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "67.73mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          국내 기업과 기술협력활동
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "68.72mm",
                    top: "15.49mm",
                    width: "71.07mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.5mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "70.08mm",
                        }}
                      >
                        <span className='hrt cs135'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                seven: {
                                  ...fourPageData.seven,
                                  two: "1",
                                },
                              });
                            }}
                            checked={fourPageData.seven.two === "1"}
                            name='question_7_company'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                seven: {
                                  ...fourPageData.seven,
                                  two: "2",
                                },
                              });
                            }}
                            checked={fourPageData.seven.two === "2"}
                            name='question_7_company'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                seven: {
                                  ...fourPageData.seven,
                                  two: "3",
                                },
                              });
                            }}
                            checked={fourPageData.seven.two === "3"}
                            name='question_7_company'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                seven: {
                                  ...fourPageData.seven,
                                  two: "4",
                                },
                              });
                            }}
                            checked={fourPageData.seven.two === "4"}
                            name='question_7_company'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                seven: {
                                  ...fourPageData.seven,
                                  two: "5",
                                },
                              });
                            }}
                            checked={fourPageData.seven.two === "5"}
                            name='question_7_company'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "22.65mm",
                    width: "68.72mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps98'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "67.73mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          국내 대학교와 기술협력활동
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "68.72mm",
                    top: "22.65mm",
                    width: "71.07mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.5mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "70.08mm",
                        }}
                      >
                        <span className='hrt cs135'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                seven: {
                                  ...fourPageData.seven,
                                  three: "1",
                                },
                              });
                            }}
                            checked={fourPageData.seven.three === "1"}
                            name='question_7_univ'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                seven: {
                                  ...fourPageData.seven,
                                  three: "2",
                                },
                              });
                            }}
                            checked={fourPageData.seven.three === "2"}
                            name='question_7_univ'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                seven: {
                                  ...fourPageData.seven,
                                  three: "3",
                                },
                              });
                            }}
                            checked={fourPageData.seven.three === "3"}
                            name='question_7_univ'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                seven: {
                                  ...fourPageData.seven,
                                  three: "4",
                                },
                              });
                            }}
                            checked={fourPageData.seven.three === "4"}
                            name='question_7_univ'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                seven: {
                                  ...fourPageData.seven,
                                  three: "5",
                                },
                              });
                            }}
                            checked={fourPageData.seven.three === "5"}
                            name='question_7_univ'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "77.1mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "83.7mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>8</span>
              <span className='hrt cs1'>. 귀사는&nbsp;</span>
              <span className='hrt cs119'>동종업계와 비교</span>
              <span className='hrt cs1'>하여&nbsp;</span>
              <span className='hrt cs9'>해외기관과의 기술협력활동</span>
              <span className='hrt cs119'>을&nbsp;</span>
              <span className='hrt cs1'>얼마나 활발하게 수행하</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "90.31mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                고 있는지 응답해 주시기 바랍니다(5점 척도를 기준으로 응답).
              </span>
            </div>
            <div
              className='hls ps36'
              style={{
                lineHeight: "30.8mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "97.11mm",
                height: "30.8mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "137.79mm",
                  top: "0.5mm",
                  height: "30.8mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 142.79 35.80'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "142.79mm",
                    height: "35.8mm",
                  }}
                >
                  <path
                    fill='url(#w_30)'
                    d='M0,0L65.73,0L65.73,8.34L0,8.34L0,0Z '
                  />
                  <path
                    fill='url(#w_30)'
                    d='M65.73,0L79.94,0L79.94,8.34L65.73,8.34L65.73,0Z '
                  />
                  <path
                    fill='url(#w_30)'
                    d='M79.94,0L94.15,0L94.15,8.34L79.94,8.34L79.94,0Z '
                  />
                  <path
                    fill='url(#w_30)'
                    d='M94.15,0L108.37,0L108.37,8.34L94.15,8.34L94.15,0Z '
                  />
                  <path
                    fill='url(#w_30)'
                    d='M108.37,0L122.58,0L122.58,8.34L108.37,8.34L108.37,0Z '
                  />
                  <path
                    fill='url(#w_30)'
                    d='M122.58,0L136.79,0L136.79,8.34L122.58,8.34L122.58,0Z '
                  />
                  <path
                    d='M0,0 L0,29.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M65.73,0 L65.73,29.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M136.79,0 L136.79,29.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L137,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,8.34 L137,8.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,15.49 L137,15.49'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,22.65 L137,22.65'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,29.81 L137,29.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M136.79,0 L136.79,29.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,29.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,29.81 L137,29.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L137,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "65.73mm",
                    height: "8.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.91mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "64.73mm",
                        }}
                      >
                        <span className='hrt cs129'>항목</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "65.73mm",
                    top: "0mm",
                    width: "14.21mm",
                    height: "8.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.5mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "13.22mm",
                        }}
                      >
                        <span className='hrt cs60'>매우</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "13.22mm",
                        }}
                      >
                        <span className='hrt cs60'>적음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "79.94mm",
                    top: "0mm",
                    width: "14.21mm",
                    height: "8.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.08mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "13.22mm",
                        }}
                      >
                        <span className='hrt cs60'>적음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "94.15mm",
                    top: "0mm",
                    width: "14.21mm",
                    height: "8.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.08mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "13.22mm",
                        }}
                      >
                        <span className='hrt cs60'>보통</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "108.37mm",
                    top: "0mm",
                    width: "14.21mm",
                    height: "8.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.08mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "13.22mm",
                        }}
                      >
                        <span className='hrt cs60'>활발</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "122.58mm",
                    top: "0mm",
                    width: "14.21mm",
                    height: "8.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.5mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "13.22mm",
                        }}
                      >
                        <span className='hrt cs60'>매우</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "13.22mm",
                        }}
                      >
                        <span className='hrt cs60'>활발</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "8.34mm",
                    width: "65.73mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps98'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "64.73mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          해외 연구소와 기술협력활동
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "65.73mm",
                    top: "8.34mm",
                    width: "71.07mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.5mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "70.08mm",
                        }}
                      >
                        <span className='hrt cs135'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                eight: {
                                  ...fourPageData.eight,
                                  one: "1",
                                },
                              });
                            }}
                            checked={fourPageData.eight.one === "1"}
                            name='question_8_lab'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                eight: {
                                  ...fourPageData.eight,
                                  one: "2",
                                },
                              });
                            }}
                            checked={fourPageData.eight.one === "2"}
                            name='question_8_lab'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                eight: {
                                  ...fourPageData.eight,
                                  one: "3",
                                },
                              });
                            }}
                            checked={fourPageData.eight.one === "3"}
                            name='question_8_lab'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                eight: {
                                  ...fourPageData.eight,
                                  one: "4",
                                },
                              });
                            }}
                            checked={fourPageData.eight.one === "4"}
                            name='question_8_lab'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                eight: {
                                  ...fourPageData.eight,
                                  one: "5",
                                },
                              });
                            }}
                            checked={fourPageData.eight.one === "5"}
                            name='question_8_lab'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "15.49mm",
                    width: "65.73mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps98'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "64.73mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          해외 기업과 기술협력활동
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "65.73mm",
                    top: "15.49mm",
                    width: "71.07mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.5mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "70.08mm",
                        }}
                      >
                        <span className='hrt cs135'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                eight: {
                                  ...fourPageData.eight,
                                  two: "1",
                                },
                              });
                            }}
                            checked={fourPageData.eight.two === "1"}
                            name='question_8_company'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                eight: {
                                  ...fourPageData.eight,
                                  two: "2",
                                },
                              });
                            }}
                            checked={fourPageData.eight.two === "2"}
                            name='question_8_company'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                eight: {
                                  ...fourPageData.eight,
                                  two: "3",
                                },
                              });
                            }}
                            checked={fourPageData.eight.two === "3"}
                            name='question_8_company'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                eight: {
                                  ...fourPageData.eight,
                                  two: "4",
                                },
                              });
                            }}
                            checked={fourPageData.eight.two === "4"}
                            name='question_8_company'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                eight: {
                                  ...fourPageData.eight,
                                  two: "5",
                                },
                              });
                            }}
                            checked={fourPageData.eight.two === "5"}
                            name='question_8_company'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "22.65mm",
                    width: "65.73mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps98'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "64.73mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          해외 대학교와 기술협력활동
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "65.73mm",
                    top: "22.65mm",
                    width: "71.07mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.5mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "70.08mm",
                        }}
                      >
                        <span className='hrt cs135'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                eight: {
                                  ...fourPageData.eight,
                                  three: "1",
                                },
                              });
                            }}
                            checked={fourPageData.eight.three === "1"}
                            name='question_8_univ'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                eight: {
                                  ...fourPageData.eight,
                                  three: "2",
                                },
                              });
                            }}
                            checked={fourPageData.eight.three === "2"}
                            name='question_8_univ'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                eight: {
                                  ...fourPageData.eight,
                                  three: "3",
                                },
                              });
                            }}
                            checked={fourPageData.eight.three === "3"}
                            name='question_8_univ'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                eight: {
                                  ...fourPageData.eight,
                                  three: "4",
                                },
                              });
                            }}
                            checked={fourPageData.eight.three === "4"}
                            name='question_8_univ'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFourPageData({
                                ...fourPageData,
                                eight: {
                                  ...fourPageData.eight,
                                  three: "5",
                                },
                              });
                            }}
                            checked={fourPageData.eight.three === "5"}
                            name='question_8_univ'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps36'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "130.44mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourPageComponent;
