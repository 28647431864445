import { action } from "src/store/reducers/user";
export const {
  login,
  logout,
  setIsValidCheck,
  setIsError,
  setMessageApi,
  setLoading,
  setTargetPage,
} = action;
