import EightPageComponent from "./EightPageComponent";

import React, { useState, useEffect } from "react";
import _ from "lodash";
import VoteAPI from "src/helpers/apis/vote/vote";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import { useDispatch } from "react-redux";
import { login } from "src/store/actions/user";
import { EightPageTypes } from "gita";
import useUnitSave from "../../../../../hooks/useUnitSave";
import { checkSubmitForm } from "src/ts/formSubmit";
import useSubmit from "src/hooks/useSubmit";

const EightPageContainer = ({
  voteData,
  isUpdate,
  setisUpdate,
  nextPage,
  companyName,
  serverUpdate,
  unitSave,
  setUnitSave,
  setServerUpdate,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const [eightPageData, setEightPageData] = useState<EightPageTypes>();

  useEffect(() => {
    const {
      vote: { eightPage },
    } = voteData;
    setEightPageData(_.cloneDeep(eightPage));
  }, [voteData]);

  useEffect(() => {}, [eightPageData]);

  useEffect(() => {
    if (serverUpdate) {
      console.log("제출");
      VoteAPI.updateVote({
        companyName,
        vote: {
          ...voteData,
          eightPage: {
            ...eightPageData,
          },
        },
      })
        .then((res) => {
          navigate(`/vote/${nextPage}/${companyName}`);
        })
        .catch((err) => {
          if (err.response.status < 500) {
            dispatch(login(false));
            messageApi.info(
              "로그인 정보가 만료 됐습니다. 다시 로그인 해 주세요."
            );
            const token = localStorage.getItem("vote_token");
          } else {
            messageApi.error("관리자에게 문의해 주세요.");
          }
        });
    }
  }, [serverUpdate]);

  useUnitSave({
    companyName,
    messageApi,
    unitSave,
    setUnitSave,
    setServerUpdate,
    setisUpdate,
    vote: {
      ...voteData,
      eightPage: {
        ...eightPageData,
      },
    },
  });

  useSubmit({
    companyName,
    setUnitSave,
    setServerUpdate,
    setisUpdate,
    vote: {
      ...voteData.vote,
      eightPage: {
        ...eightPageData,
      },
    },
  });

  return (
    <div>
      {contextHolder}
      {eightPageData && (
        <EightPageComponent
          eightPageData={eightPageData}
          setEightPageData={setEightPageData}
          setisUpdate={setisUpdate}
        />
      )}
    </div>
  );
};

export default EightPageContainer;
