import { Button, Modal } from "antd";
import React from "react";

const TwentyThreeContent = ({ setDetailPage, detailPage }: any) => {
  return (
    <div>
      <div>
        <div className='hpa' style={{ width: "210mm", height: "297mm" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "60px 20px 0px 20px",
              alignItems: "center",
            }}
          >
            <Button
              disabled={detailPage === 0}
              onClick={() => {
                setDetailPage((pre: number) => pre - 1);
              }}
            >
              이전
            </Button>

            <span
              style={{
                display: "inline-block",
                margin: "0px 20px",
              }}
            >
              {detailPage + 1} / 12
            </span>

            <Button
              style={{}}
              disabled={detailPage === 11}
              onClick={() => {
                setDetailPage((pre: number) => pre + 1);
              }}
            >
              다음
            </Button>
          </div>
          <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
            <div className='hcI' />
          </div>
          <div
            className='htb'
            style={{
              left: "30mm",
              width: "149.82mm",
              top: "35mm",
              height: "231.65mm",
            }}
          >
            <svg
              className='hs'
              viewBox='-2.50 -2.50 154.82 236.65'
              style={{
                left: "-2.50mm",
                top: "-2.50mm",
                width: "154.82mm",
                height: "236.65mm",
              }}
            >
              <defs>
                <pattern
                  id='w_220'
                  width={10}
                  height={10}
                  patternUnits='userSpaceOnUse'
                >
                  <rect width={10} height={10} fill='rgb(216,216,216)' />
                </pattern>
              </defs>
              <path
                fill='url(#w_220)'
                d='M0,0L21.59,0L21.59,7.48L0,7.48L0,0Z '
              />
              <path
                fill='url(#w_220)'
                d='M21.59,0L44.36,0L44.36,7.48L21.59,7.48L21.59,0Z '
              />
              <path
                fill='url(#w_220)'
                d='M44.36,0L79.95,0L79.95,7.48L44.36,7.48L44.36,0Z '
              />
              <path
                fill='url(#w_220)'
                d='M79.95,0L149.82,0L149.82,7.48L79.95,7.48L79.95,0Z '
              />
              <path
                d='M0,0 L0,231.65'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M21.59,0 L21.59,231.65'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M44.36,0 L44.36,231.65'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.95,0 L79.95,231.65'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M149.82,0 L149.82,231.65'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M-0.07,0 L149.90,0'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M-0.07,7.48 L149.90,7.48'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,14.60 L149.90,14.60'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M44.28,21.73 L80.03,21.73'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.10",
                }}
              />
              <path
                d='M79.88,21.73 L149.90,21.73'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,28.86 L149.90,28.86'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M44.28,35.98 L80.03,35.98'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.10",
                }}
              />
              <path
                d='M79.88,35.98 L149.90,35.98'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,43.11 L149.90,43.11'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,50.24 L149.90,50.24'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M21.51,57.36 L80.03,57.36'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.10",
                }}
              />
              <path
                d='M79.88,57.36 L149.90,57.36'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,64.49 L149.90,64.49'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,71.61 L149.90,71.61'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M44.28,78.74 L80.03,78.74'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.10",
                }}
              />
              <path
                d='M79.88,78.74 L149.90,78.74'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,85.87 L149.90,85.87'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M44.28,92.99 L80.03,92.99'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.10",
                }}
              />
              <path
                d='M79.88,92.99 L149.90,92.99'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,100.12 L149.90,100.12'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,107.24 L149.90,107.24'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M21.51,114.37 L80.03,114.37'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.10",
                }}
              />
              <path
                d='M79.88,114.37 L149.90,114.37'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,121.50 L149.90,121.50'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,128.62 L149.90,128.62'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M44.28,135.75 L80.03,135.75'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.10",
                }}
              />
              <path
                d='M79.88,135.75 L149.90,135.75'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,142.87 L149.90,142.87'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,150 L149.90,150'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M44.28,157.13 L80.03,157.13'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.10",
                }}
              />
              <path
                d='M79.88,157.13 L149.90,157.13'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,164.25 L149.90,164.25'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,171.38 L149.90,171.38'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,178.51 L149.90,178.51'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M44.28,185.63 L80.03,185.63'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.10",
                }}
              />
              <path
                d='M79.88,185.63 L149.90,185.63'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,192.76 L149.90,192.76'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M21.51,199.88 L80.03,199.88'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.10",
                }}
              />
              <path
                d='M79.88,199.88 L149.90,199.88'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,207.01 L149.90,207.01'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,214.14 L149.90,214.14'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M79.88,221.26 L149.90,221.26'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M-0.07,231.65 L80.03,231.65'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.10",
                }}
              />
              <path
                d='M79.88,231.65 L149.90,231.65'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M149.82,0 L149.82,231.65'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M0,0 L0,231.65'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M-0.07,231.65 L80.03,231.65'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.10",
                }}
              />
              <path
                d='M79.88,231.65 L149.90,231.65'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
              <path
                d='M-0.07,0 L149.90,0'
                style={{
                  stroke: "#000000",
                  strokeLinecap: "butt",
                  strokeWidth: "0.15",
                }}
              />
            </svg>
            <div
              className='hce'
              style={{
                left: "0mm",
                top: "0mm",
                width: "21.59mm",
                height: "7.48mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps95'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "19.59mm",
                    }}
                  >
                    <span className='hrt cs190'>대분류</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "21.59mm",
                top: "0mm",
                width: "22.77mm",
                height: "7.48mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps95'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "20.77mm",
                    }}
                  >
                    <span className='hrt cs190'>중분류</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "44.36mm",
                top: "0mm",
                width: "35.59mm",
                height: "7.48mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps95'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "33.58mm",
                    }}
                  >
                    <span className='hrt cs190'>소분류</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "0mm",
                width: "69.87mm",
                height: "7.48mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps95'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs190'>세분류</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "0mm",
                top: "7.48mm",
                width: "21.59mm",
                height: "224.17mm",
              }}
            />
            <div
              className='hce'
              style={{
                left: "21.59mm",
                top: "7.48mm",
                width: "22.77mm",
                height: "49.88mm",
              }}
            />
            <div
              className='hce'
              style={{
                left: "44.36mm",
                top: "7.48mm",
                width: "35.59mm",
                height: "14.25mm",
              }}
            />
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "7.48mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>가상화 보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "14.60mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>시스템 접근통제</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "44.36mm",
                top: "21.73mm",
                width: "35.59mm",
                height: "14.25mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI' style={{ top: "3.39mm" }}>
                  <div
                    className='hls ps19'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "33.58mm",
                    }}
                  >
                    <span className='hrt cs191'>악성코드</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "21.73mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>악성코드 대응</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "28.86mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>랜섬웨어 대응</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "44.36mm",
                top: "35.98mm",
                width: "35.59mm",
                height: "21.38mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI' style={{ top: "6.95mm" }}>
                  <div
                    className='hls ps19'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "33.58mm",
                    }}
                  >
                    <span className='hrt cs191'>위협 분석 및 관제</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "35.98mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>지능형 사이버위협 분석</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "43.11mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>
                      보안정보 분석 및 로그 관리
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "50.24mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>보안 관제</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "21.59mm",
                top: "57.36mm",
                width: "22.77mm",
                height: "57.01mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI' style={{ top: "22.24mm" }}>
                  <div
                    className='hls ps19'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "20.77mm",
                    }}
                  >
                    <span className='hrt cs191'>네트워크&nbsp;</span>
                  </div>
                  <div
                    className='hls ps19'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "4.86mm",
                      height: "3.88mm",
                      width: "20.77mm",
                    }}
                  >
                    <span className='hrt cs191'>보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "44.36mm",
                top: "57.36mm",
                width: "35.59mm",
                height: "21.38mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI' style={{ top: "6.95mm" }}>
                  <div
                    className='hls ps19'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "33.58mm",
                    }}
                  >
                    <span className='hrt cs191'>유선네트워크 보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "57.36mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps24'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs195'>경계보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "64.49mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>보안 연결</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "71.61mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>DDoS대응</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "44.36mm",
                top: "78.74mm",
                width: "35.59mm",
                height: "14.25mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI' style={{ top: "3.39mm" }}>
                  <div
                    className='hls ps19'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "33.58mm",
                    }}
                  >
                    <span className='hrt cs191'>무선네트워크 보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "78.74mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>이동 통신망 보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "85.87mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>무선근거리통신망보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "44.36mm",
                top: "92.99mm",
                width: "35.59mm",
                height: "21.38mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI' style={{ top: "6.95mm" }}>
                  <div
                    className='hls ps19'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "33.58mm",
                    }}
                  >
                    <span className='hrt cs191'>클라우드 보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "92.99mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps24'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs195'>가상화 플랫폼 보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "100.12mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps24'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs195'>클라우드 보안 서비스</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "107.24mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>소프트웨어 정의 보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "21.59mm",
                top: "114.37mm",
                width: "22.77mm",
                height: "85.51mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI' style={{ top: "33.97mm" }}>
                  <div
                    className='hls ps19'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "20.77mm",
                    }}
                  >
                    <span className='hrt cs191'>데이터 및&nbsp;</span>
                  </div>
                  <div
                    className='hls ps19'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "4.86mm",
                      height: "3.88mm",
                      width: "20.77mm",
                    }}
                  >
                    <span className='hrt cs191'>응용</span>
                  </div>
                  <div
                    className='hls ps19'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "9.91mm",
                      height: "3.88mm",
                      width: "20.77mm",
                    }}
                  >
                    <span className='hrt cs191'>서비스보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "44.36mm",
                top: "114.37mm",
                width: "35.59mm",
                height: "21.38mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI' style={{ top: "6.95mm" }}>
                  <div
                    className='hls ps19'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "33.58mm",
                    }}
                  >
                    <span className='hrt cs191'>응용 보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "114.37mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>웹 보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "121.50mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>이메일 보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "128.62mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>데이터베이스 보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "44.36mm",
                top: "135.75mm",
                width: "35.59mm",
                height: "21.38mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI' style={{ top: "6.95mm" }}>
                  <div
                    className='hls ps19'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "33.58mm",
                    }}
                  >
                    <span className='hrt cs191'>데이터 보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "135.75mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>프라이버시 보호</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "142.87mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>데이터 유출 방지</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "150mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>
                      디지털저작물 침해/권리 보호
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "44.36mm",
                top: "157.13mm",
                width: "35.59mm",
                height: "28.50mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI' style={{ top: "7.99mm" }}>
                  <div
                    className='hls ps95'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "33.58mm",
                    }}
                  >
                    <span className='hrt cs191'>전자화폐∙핀테크&nbsp;</span>
                  </div>
                  <div
                    className='hls ps95'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "4.86mm",
                      height: "3.88mm",
                      width: "33.58mm",
                    }}
                  >
                    <span className='hrt cs191'>보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "157.13mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>전자화폐 보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "164.25mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>블록체인 보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "171.38mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>전자거래 이상행위 탐지</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "178.51mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>거래·사기 방지</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "44.36mm",
                top: "185.63mm",
                width: "35.59mm",
                height: "14.25mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI' style={{ top: "3.39mm" }}>
                  <div
                    className='hls ps19'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "33.58mm",
                    }}
                  >
                    <span className='hrt cs191'>디지털 포렌식</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "185.63mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>디지털 증거 수집 및 분석</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "192.76mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>안티 포렌식 대응</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "21.59mm",
                top: "199.88mm",
                width: "22.77mm",
                height: "31.77mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI' style={{ top: "12.14mm" }}>
                  <div
                    className='hls ps19'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "20.77mm",
                    }}
                  >
                    <span className='hrt cs191'>물리보안</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "44.36mm",
                top: "199.88mm",
                width: "35.59mm",
                height: "31.77mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI' style={{ top: "12.14mm" }}>
                  <div
                    className='hls ps19'
                    style={{
                      lineHeight: "3.10mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.19mm",
                      height: "3.88mm",
                      width: "33.58mm",
                    }}
                  >
                    <span className='hrt cs191'>휴먼/바이오인식</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "199.88mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>바이오인식 센서</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "207.01mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>바이오인식 엔진</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "214.14mm",
                width: "69.87mm",
                height: "7.13mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI'>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>휴먼인식 및 검색</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hce'
              style={{
                left: "79.95mm",
                top: "221.26mm",
                width: "69.87mm",
                height: "10.39mm",
              }}
            >
              <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
                <div className='hcI' style={{ top: "1.63mm" }}>
                  <div
                    className='hls ps94'
                    style={{
                      lineHeight: "2.79mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      top: "-0.18mm",
                      height: "3.53mm",
                      width: "67.87mm",
                    }}
                  >
                    <span className='hrt cs194'>휴먼/바이오인식 응용</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwentyThreeContent;
