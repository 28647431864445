const Main = () => {
  return (
    <div style={{ margin: "auto", width: "210mm" }}>
      <div className='hpa' style={{ width: "210mm", height: "297mm" }}>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps32'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.19mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs38'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span className='hrt cs98'>&nbsp;&nbsp;</span>
            </div>
            <div
              className='hls ps32'
              style={{
                lineHeight: "146.63mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "6.60mm",
                height: "146.63mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.50mm",
                  width: "149.33mm",
                  top: "0.50mm",
                  height: "146.63mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 154.33 151.63'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "154.33mm",
                    height: "151.63mm",
                  }}
                >
                  <path
                    d='M0,12.11 L0,145.64'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M148.34,12.11 L148.34,145.64'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.05,12.11 L148.54,12.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,145.63 L148.54,145.63'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M148.34,12.11 L148.34,145.64'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M0,12.11 L0,145.64'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,145.63 L148.54,145.63'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.05,12.11 L148.54,12.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.34mm",
                    height: "9.76mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.49mm", top: "1mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "7.06mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.39mm",
                          height: "7.76mm",
                          width: "147.35mm",
                        }}
                      >
                        <span className='hrt cs80'>
                          정보통신응용기술개발지원(융자)사업 성과조사
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "9.76mm",
                    width: "111.80mm",
                    height: "2.35mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.49mm", top: "1mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "0.25mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.02mm",
                          height: "0.35mm",
                          width: "110.81mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "111.80mm",
                    top: "9.76mm",
                    width: "4.98mm",
                    height: "2.35mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.49mm", top: "1mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps20'
                        style={{
                          lineHeight: "0.25mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.02mm",
                          height: "0.35mm",
                          width: "5.08mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.78mm",
                    top: "9.76mm",
                    width: "31.55mm",
                    height: "2.35mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.49mm", top: "1mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "0.25mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.02mm",
                          height: "0.35mm",
                          width: "30.56mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "12.11mm",
                    width: "148.34mm",
                    height: "133.53mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.49mm", top: "1mm" }}>
                    <div className='hcI' style={{ top: "7.10mm" }}>
                      <div
                        className='hls ps63'
                        style={{
                          paddingLeft: "3.53mm",
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs81'>
                          안녕하십니까?
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                      <div
                        className='hls ps63'
                        style={{
                          paddingLeft: "3.53mm",
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "6.01mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs81'>
                          ICT산업 발전을 위한 귀사의 노고에 감사드립니다.
                        </span>
                      </div>
                      <div
                        className='hls ps63'
                        style={{
                          paddingLeft: "3.53mm",
                          lineHeight: "1.59mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "12.31mm",
                          height: "2.12mm",
                          width: "136.76mm",
                        }}
                      />
                      <div
                        className='hls ps63'
                        style={{
                          paddingLeft: "3.53mm",
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "15.61mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs81'>
                          과학기술정보통신부와 정보통신기획평가원(이하 IITP)은
                          ICT 중소기업의 기술경
                        </span>
                      </div>
                      <div
                        className='hls ps63'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "21.82mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs81'>
                          쟁력 강화 및 신산업 창출을 위하여 ICT 및 ICT기반
                          융복합분야 기술개발자금 융
                        </span>
                      </div>
                      <div
                        className='hls ps63'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "28.03mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs81'>
                          자 지원사업을 추진해 왔습니다.
                        </span>
                      </div>
                      <div
                        className='hls ps63'
                        style={{
                          paddingLeft: "3.53mm",
                          lineHeight: "1.59mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "34.33mm",
                          height: "2.12mm",
                          width: "136.76mm",
                        }}
                      />
                      <div
                        className='hls ps63'
                        style={{
                          paddingLeft: "3.53mm",
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "37.62mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs177'>
                          IITP에서는 ‘정보통신응용기술개발지원(융자)사업의 성과
                          및 사업개선방안을 모색하
                        </span>
                      </div>
                      <div
                        className='hls ps63'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "43.83mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs177'>
                          기 위해 충남대학교 산학협력단을 연구용역 수행기관으로
                          선정하여 사업 참여 기관을&nbsp;
                        </span>
                      </div>
                      <div
                        className='hls ps63'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "50.04mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs177'>
                          대상으로 사업성과 및 기업만족도를 파악하고자
                          설문조사를 실시하고 있습니다.
                        </span>
                      </div>
                      <div
                        className='hls ps63'
                        style={{
                          paddingLeft: "3.53mm",
                          lineHeight: "1.59mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "56.34mm",
                          height: "2.12mm",
                          width: "136.76mm",
                        }}
                      />
                      <div
                        className='hls ps63'
                        style={{
                          paddingLeft: "3.53mm",
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "59.64mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs83'>
                          귀사에서 제출하신 설문자료는 사업개선을 위한
                          근거자료로 활용되며, 이를 기
                        </span>
                      </div>
                      <div
                        className='hls ps63'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "65.85mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs83'>
                          반으로 더 나은 사업 방향 및 향후 ICT산업에 좀 더
                          발전적인 정책방향을 세우
                        </span>
                      </div>
                      <div
                        className='hls ps63'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "72.05mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs83'>는데 사용될 것입니다.</span>
                      </div>
                      <div
                        className='hls ps63'
                        style={{
                          paddingLeft: "3.53mm",
                          lineHeight: "1.59mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "78.35mm",
                          height: "2.12mm",
                          width: "136.76mm",
                        }}
                      />
                      <div
                        className='hls ps63'
                        style={{
                          paddingLeft: "3.53mm",
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "81.65mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs81'>
                          산업 일선에서 매우 바쁘시겠지만 귀사가 응답하시는
                          내용이 정부 정책 결정에&nbsp;
                        </span>
                      </div>
                      <div
                        className='hls ps63'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "87.86mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs81'>
                          큰 기여를 한다는 소명감을 가지고 적극 참여해 주시면
                          감사하겠습니다.
                        </span>
                      </div>
                      <div
                        className='hls ps63'
                        style={{
                          paddingLeft: "3.53mm",
                          lineHeight: "1.59mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "94.16mm",
                          height: "2.12mm",
                          width: "136.76mm",
                        }}
                      />
                      <div
                        className='hls ps63'
                        style={{
                          paddingLeft: "3.53mm",
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "97.45mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs81'>
                          귀사 및 귀하의 무궁한 발전을 기원합니다.&nbsp;
                        </span>
                      </div>
                      <div
                        className='hls ps63'
                        style={{
                          paddingLeft: "3.53mm",
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "103.66mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs81'>
                          감사합니다. &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                      <div
                        className='hls ps63'
                        style={{
                          paddingLeft: "3.53mm",
                          lineHeight: "1.59mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "109.96mm",
                          height: "2.12mm",
                          width: "136.76mm",
                        }}
                      />
                      <div
                        className='hls ps69'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "5.29mm",
                          top: "113.26mm",
                          height: "3.88mm",
                          width: "136.76mm",
                        }}
                      >
                        <span className='hrt cs102'>
                          정보통신기획평가원 미래정책단 기업확산팀
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "1.59mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "155.85mm",
                height: "2.12mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps90'
              style={{
                lineHeight: "39.98mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "159.55mm",
                height: "39.98mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.49mm",
                  width: "148.64mm",
                  top: "0.49mm",
                  height: "39.98mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.63 44.98'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "153.63mm",
                    height: "44.98mm",
                  }}
                >
                  <path
                    d='M0,0 L0,39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M147.65,0 L147.65,39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.85,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,38.99 L147.85,38.99'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M147.65,0 L147.65,39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M0,0 L0,39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,38.99 L147.85,38.99'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.85,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "147.65mm",
                    height: "38.99mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "9.09mm" }}>
                      <div
                        className='hls ps12'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "146.66mm",
                        }}
                      >
                        <span className='hrt cs38'>&nbsp;*</span>
                        <span className='hrt cs56'>
                          &nbsp;본 조사와 관련하여 문의사항이 있으시면 아래로
                          연락주시기 바랍니다.
                        </span>
                      </div>
                      <div
                        className='hls ps13'
                        style={{
                          paddingLeft: "5.29mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "8.36mm",
                          height: "3.53mm",
                          width: "146.66mm",
                        }}
                      >
                        <span className='hrt cs96'>
                          연구 주관 기관 : 충남대학교 산학협력단(연구책임 :
                          성을현 교수/충남대학교)
                        </span>
                      </div>
                      <div
                        className='hls ps13'
                        style={{
                          paddingLeft: "5.29mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "16.12mm",
                          height: "3.53mm",
                          width: "146.66mm",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            className='hrt cs96'
                            style={{
                              width: "15%",
                            }}
                          >
                            문 &nbsp;&nbsp;의 &nbsp;&nbsp;처 :
                          </div>
                          <div>
                            <span className='hrt cs96'>
                              010-8812-5525, 010-2355-0778
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              width: "15%",
                            }}
                          ></div>
                          <span
                            className='hrt cs96'
                            style={{
                              marginTop: "5px",
                            }}
                          >
                            (충남대학교 과학기술지식연구소)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps47'
              style={{
                lineHeight: "2.79mm",
                whiteSpace: "nowrap",
                left: "2.64mm",
                top: "202.46mm",
                height: "3.53mm",
                width: "143.83mm",
              }}
            >
              <span className='hrt cs85'>
                ※ 본 조사의 결과는 통계법 제33조에 의거하여 비밀이 보장되며,
                설문에 대한 모든&nbsp;
              </span>
            </div>
            <div
              className='hls ps47'
              style={{
                paddingLeft: "5.29mm",
                lineHeight: "2.79mm",
                whiteSpace: "nowrap",
                left: "2.64mm",
                top: "208.81mm",
                height: "3.53mm",
                width: "143.83mm",
              }}
            >
              <span className='hrt cs85'>
                응답과 개인적인 사항은 철저히 비밀과 무기명으로 처리되고
                통계분석의 목적에만&nbsp;
              </span>
            </div>
            <div
              className='hls ps47'
              style={{
                paddingLeft: "5.29mm",
                lineHeight: "2.79mm",
                whiteSpace: "nowrap",
                left: "2.64mm",
                top: "215.16mm",
                height: "3.53mm",
                width: "143.83mm",
              }}
            >
              <span className='hrt cs85'>
                사용됩니다. &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
