import React from "react";
import { Button, Modal } from "antd";

const TwentyContent = ({ setDetailPage, detailPage }: any) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "297mm" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "60px 20px 0px 20px",
            alignItems: "center",
          }}
        >
          <Button
            disabled={detailPage === 0}
            onClick={() => {
              setDetailPage((pre: number) => pre - 1);
            }}
          >
            이전
          </Button>

          <span
            style={{
              display: "inline-block",
              margin: "0px 20px",
            }}
          >
            {detailPage + 1} / 12
          </span>

          <Button
            style={{}}
            disabled={detailPage === 11}
            onClick={() => {
              setDetailPage((pre: number) => pre + 1);
            }}
          >
            다음
          </Button>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI' />
        </div>
        <div
          className='htb'
          style={{
            left: "30mm",
            width: "149.82mm",
            top: "35mm",
            height: "231.65mm",
          }}
        >
          <svg
            className='hs'
            viewBox='-2.50 -2.50 154.82 236.65'
            style={{
              left: "-2.50mm",
              top: "-2.50mm",
              width: "154.82mm",
              height: "236.65mm",
            }}
          >
            <defs>
              <pattern
                id='w_190'
                width={10}
                height={10}
                patternUnits='userSpaceOnUse'
              >
                <rect width={10} height={10} fill='rgb(216,216,216)' />
              </pattern>
            </defs>
            <path fill='url(#w_190)' d='M0,0L21.59,0L21.59,7.48L0,7.48L0,0Z ' />
            <path
              fill='url(#w_190)'
              d='M21.59,0L44.36,0L44.36,7.48L21.59,7.48L21.59,0Z '
            />
            <path
              fill='url(#w_190)'
              d='M44.36,0L79.95,0L79.95,7.48L44.36,7.48L44.36,0Z '
            />
            <path
              fill='url(#w_190)'
              d='M79.95,0L149.82,0L149.82,7.48L79.95,7.48L79.95,0Z '
            />
            <path
              d='M0,0 L0,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M0,7.48 L0,28.86'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M0,28.86 L0,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M21.59,0 L21.59,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M21.59,7.48 L21.59,28.86'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M21.59,28.86 L21.59,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.36,0 L44.36,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.36,7.48 L44.36,28.86'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M44.36,28.86 L44.36,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.95,0 L79.95,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.95,7.48 L79.95,28.86'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.95,28.86 L79.95,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M149.82,0 L149.82,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M149.82,7.48 L149.82,28.86'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M149.82,28.86 L149.82,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,0 L149.90,0'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,7.48 L149.90,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.90,14.60 L149.87,14.60'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.90,21.73 L149.87,21.73'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M-0.05,28.86 L44.44,28.86'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.12",
              }}
            />
            <path
              d='M44.31,28.86 L80,28.86'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M44.28,28.86 L80.03,28.86'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.12",
              }}
            />
            <path
              d='M79.90,28.86 L149.87,28.86'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.88,28.86 L149.90,28.86'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.12",
              }}
            />
            <path
              d='M79.88,35.98 L149.90,35.98'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,43.11 L149.90,43.11'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.12",
              }}
            />
            <path
              d='M79.88,50.24 L149.90,50.24'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,57.36 L149.90,57.36'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,64.49 L149.90,64.49'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.12",
              }}
            />
            <path
              d='M79.88,71.61 L149.90,71.61'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,78.74 L149.90,78.74'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,85.87 L149.90,85.87'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M21.51,92.99 L44.44,92.99'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.12",
              }}
            />
            <path
              d='M44.28,92.99 L149.90,92.99'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,100.12 L149.90,100.12'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,107.24 L149.90,107.24'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.12",
              }}
            />
            <path
              d='M79.88,114.37 L149.90,114.37'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M21.51,121.50 L149.90,121.50'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,128.62 L149.90,128.62'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,135.75 L149.90,135.75'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,142.87 L149.90,142.87'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,150 L149.90,150'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,157.13 L149.90,157.13'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,164.25 L149.90,164.25'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,171.38 L149.90,171.38'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M21.51,178.51 L149.90,178.51'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,185.63 L149.90,185.63'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,192.76 L149.90,192.76'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,199.88 L149.90,199.88'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,207.01 L149.90,207.01'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,214.14 L149.90,214.14'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,221.26 L149.90,221.26'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,231.65 L149.90,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M149.82,0 L149.82,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M149.82,7.48 L149.82,28.86'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M149.82,28.86 L149.82,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M0,28.86 L0,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M0,7.48 L0,28.86'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M0,0 L0,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,231.65 L149.90,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,0 L149.90,0'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
          </svg>
          <div
            className='hce'
            style={{
              left: "0mm",
              top: "0mm",
              width: "21.59mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "19.59mm",
                  }}
                >
                  <span className='hrt cs190'>대분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "0mm",
              width: "22.77mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs190'>중분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "0mm",
              width: "35.59mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs190'>소분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "0mm",
              width: "69.87mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs190'>세분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "0mm",
              top: "7.48mm",
              width: "21.59mm",
              height: "21.38mm",
            }}
          />
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "7.48mm",
              width: "22.77mm",
              height: "21.38mm",
            }}
          />
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "7.48mm",
              width: "35.59mm",
              height: "21.38mm",
            }}
          />
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "7.48mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>지상국/관제</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "14.60mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>위성항법/측위</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "21.73mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>통신·방송 및 ICT 응용</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "0mm",
              top: "28.86mm",
              width: "21.59mm",
              height: "202.79mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1mm" }}>
              <div className='hcI' style={{ top: "98.46mm" }}>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "19.59mm",
                  }}
                >
                  <span className='hrt cs191'>SW·AI</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "28.86mm",
              width: "22.77mm",
              height: "64.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1mm" }}>
              <div className='hcI' style={{ top: "29.48mm" }}>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "2.48mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.16mm",
                    height: "3.17mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs192'>인공지능</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "28.86mm",
              width: "35.59mm",
              height: "14.25mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1mm" }}>
              <div className='hcI' style={{ top: "4.54mm" }}>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "2.48mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.16mm",
                    height: "3.17mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs192'>학습지능</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "28.86mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>머신러닝</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "35.98mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>추론/지식표현</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "43.11mm",
              width: "35.59mm",
              height: "21.38mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1mm" }}>
              <div className='hcI' style={{ top: "8.10mm" }}>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "2.48mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.16mm",
                    height: "3.17mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs192'>단일지능</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "43.11mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>언어지능</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "50.24mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>시각지능</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "57.36mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>청각지능</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "64.49mm",
              width: "35.59mm",
              height: "28.50mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1mm" }}>
              <div className='hcI' style={{ top: "11.67mm" }}>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "2.48mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.16mm",
                    height: "3.17mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs192'>복합지능</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "64.49mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>행동/소셜지능</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "71.61mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>상황/감정이해</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "78.74mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>지능형 에이전트</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "85.87mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>범용 인공지능(AGI)</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "92.99mm",
              width: "22.77mm",
              height: "28.50mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1mm" }}>
              <div className='hcI' style={{ top: "11.67mm" }}>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "2.48mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.16mm",
                    height: "3.17mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs192'>빅데이터</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "92.99mm",
              width: "35.59mm",
              height: "14.25mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1mm" }}>
              <div className='hcI' style={{ top: "4.54mm" }}>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "2.48mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.16mm",
                    height: "3.17mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs192'>빅데이터 처리·유통</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "92.99mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>빅데이터 수집·유통기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "100.12mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    빅데이터 저장/처리/관리 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "107.24mm",
              width: "35.59mm",
              height: "14.25mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1mm" }}>
              <div className='hcI' style={{ top: "4.54mm" }}>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "2.48mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.16mm",
                    height: "3.17mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs192'>빅데이터 분석·활용</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "107.24mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>빅데이터 분석/예측 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "114.37mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>빅데이터 활용‧시각화</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "121.50mm",
              width: "22.77mm",
              height: "57.01mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1mm" }}>
              <div className='hcI' style={{ top: "25.92mm" }}>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "2.48mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.16mm",
                    height: "3.17mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs192'>응용SW</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "121.50mm",
              width: "35.59mm",
              height: "35.63mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1mm" }}>
              <div className='hcI' style={{ top: "13.16mm" }}>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "2.48mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.16mm",
                    height: "3.17mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs192'>응용기반</span>
                </div>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "2.48mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "3.98mm",
                    height: "3.17mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs192'>SW</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "121.50mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>가상 시뮬레이션</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "128.62mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>사이버 물리 시스템(CPS)</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "135.75mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>디지털 신호처리 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "142.87mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>UI/UX 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "150mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>인터넷 서비스 SW 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "157.13mm",
              width: "35.59mm",
              height: "21.38mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1mm" }}>
              <div className='hcI' style={{ top: "5.22mm" }}>
                <div
                  className='hls ps97'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs193'>응용특화</span>
                </div>
                <div
                  className='hls ps97'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "4.86mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs193'>SW</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "157.13mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>공공용SW</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "164.25mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>기업용SW</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "171.38mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>범용SW</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "178.51mm",
              width: "22.77mm",
              height: "53.15mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1mm" }}>
              <div className='hcI' style={{ top: "23.63mm" }}>
                <div
                  className='hls ps97'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs193'>시스템SW</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "178.51mm",
              width: "35.59mm",
              height: "28.50mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1mm" }}>
              <div className='hcI' style={{ top: "11.67mm" }}>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "2.48mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.16mm",
                    height: "3.17mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs192'>운영체제</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "178.51mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>초경량‧저전력 운영체제</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "185.63mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>실시간 운영체제</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "192.76mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>모바일 운영체제</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "199.88mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>PC/서버 운영체제</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "207.01mm",
              width: "35.59mm",
              height: "24.64mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1mm" }}>
              <div className='hcI' style={{ top: "9.73mm" }}>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "2.48mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.16mm",
                    height: "3.17mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs192'>미들웨어</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "207.01mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>분산 시스템 SW</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "214.14mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>서비스 플랫폼</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "221.26mm",
              width: "69.87mm",
              height: "10.39mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "1.63mm" }}>
                <div
                  className='hls ps24'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>프로그래밍 언어 처리 SW</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwentyContent;
