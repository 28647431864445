import React, { useEffect, useState } from "react";
import VoteAPI from "src/helpers/apis/vote/vote";
import { PDFDocument, rgb } from "pdf-lib";
import { saveAs } from "file-saver";
type PropsType = {};

export default function PDFContainer({}: PropsType) {
  const [serverData, setServerData] = useState<any>();

  useEffect(() => {
    const token = localStorage.getItem("vote_token");
    const adminToken = localStorage.getItem("vote_admin_token");

    if (token || adminToken) {
      VoteAPI.getVoteList({
        headers: {
          Authorization: token ? token : adminToken,
        },
        params: {
          companyNameList: [60].join(","),
        },
      }).then((res: any) => {
        console.log(res.voteList[0]);
        setServerData(res.voteList);
      });
    }
  }, []);
  const generatePdf = async () => {
    // PDF 템플릿 로드
    const existingPdfBytes = await fetch("/survey.pdf").then((res) =>
      res.arrayBuffer()
    );

    // PDF 문서 생성
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // 첫 번째 페이지 가져오기
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    if (serverData) {
      // 예시: 데이터를 특정 위치에 삽입
      console.log(serverData);
      console.log(serverData[0].twoPage.info.company.toString());
      const companyText = serverData[0].twoPage.info.company.toString();
      firstPage.drawText(companyText, {
        x: 214,
        y: 187,
        size: 10,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(companyText, {
        x: 213,
        y: 212,
        size: 10,
        color: rgb(0, 0, 0),
      });
      // 추가 질문도 같은 방식으로 삽입
    }

    // PDF 파일 저장
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    saveAs(blob, "filled-survey.pdf");
  };

  return (
    <div>
      <h1>qwer</h1>
      <button onClick={generatePdf}>qwereqwr</button>
    </div>
  );
}
