import React from "react";
import { Col, Row, Table, Button, Input, Modal, Form, Select } from "antd";
import Draggable from "react-draggable";
import styles from "./admin.module.css";
import { Option } from "antd/es/mentions";
import AddCompany from "./crud/AddCompany";

const AdminComponent = ({
  dataSource,
  columns,
  setPage,
  setSearch,
  search,
  isModalOpen,
  showModal,
  handleOk,
  handleCancel,
  rowSelection,
  hasSelected,
  downLoadList,
  isFile,
  setIsFile,
  targetFile,
  setTargetFile,
  setIsStatus,
  isStatus,
  rowDownLoadList,
}: any) => {
  return (
    <div>
      <Row>
        <Col span={24}>
          <div className={styles.table_nav}>
            <div>
              <Button onClick={() => downLoadList()} disabled={!hasSelected}>
                선택 다운받기
              </Button>

              <Button
                style={{
                  marginLeft: "20px",
                }}
                disabled={!hasSelected}
                onClick={() => rowDownLoadList()}
              >
                통계
              </Button>
            </div>
            <div>
              <Select
                style={{
                  width: "100px",
                  marginRight: "10px",
                }}
                value={isStatus}
                onChange={(e) => {
                  setIsStatus(e);
                }}
                options={[
                  { value: "-1", label: "전체" },
                  { value: "0", label: "미제출" },
                  { value: "1", label: "제출완료" },
                ]}
              ></Select>
              <Input
                style={{ width: "200px", marginRight: "30px" }}
                placeholder="회사 이름"
                onChange={(e: any) => setSearch(e.target.value)}
                value={search}
                className={"form-input"}
              />
              <Button onClick={showModal} type="primary">
                추가
              </Button>
            </div>
          </div>
          <Table
            dataSource={dataSource}
            columns={columns}
            rowSelection={rowSelection}
            rowKey="id"
            pagination={{
              onChange(current) {
                setPage(current);
              },

              showSizeChanger: true,
              pageSizeOptions: ["10", "30", "50", "100", "200", "400", "600"],
              defaultPageSize: 600,
            }}
          />
        </Col>
      </Row>
      <AddCompany
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        isFile={isFile}
        setIsFile={setIsFile}
        setTargetFile={setTargetFile}
        targetFile={targetFile}
      />
    </div>
  );
};

export default AdminComponent;
