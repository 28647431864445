import React, { useEffect, useState } from "react";
import { Col, Row, Table, Button, Input, Modal, Form, Select } from "antd";
import styles from "../admin.module.css";

type PropsType = {
  isModalOpen: boolean;
  handleOk: (formData: any) => void;
  handleCancel: () => void;
  isFile: boolean;
  setIsFile: any;
  setTargetFile: any;
  targetFile: any;
};

export default function AddCompany({
  isModalOpen,
  handleOk,
  handleCancel,
  isFile,
  setIsFile,
  setTargetFile,
  targetFile,
}: PropsType) {
  const [form, setForm] = useState({
    companyName: "",
    code: "",
  });

  useEffect(() => {
    setForm({
      companyName: "",
      code: "",
    });
  }, [isModalOpen]);

  return (
    <Modal
      title={
        <div
          style={{
            width: "100%",
            // cursor: "move",
          }}
        >
          회사 추가하기
        </div>
      }
      open={isModalOpen}
      onOk={() => handleOk(form)}
      onCancel={handleCancel}
      footer={[
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={() => handleOk(form)}
        >
          추가하기
        </Button>,
        <Button key="cancel" danger onClick={handleCancel}>
          취소
        </Button>,
      ]}
      // modalRender={(modal) => (
      //   <Draggable
      //     bounds={bounds}
      //     onStart={(event, uiData) => onStart(event, uiData)}
      //   >
      //     <div ref={draggleRef}>{modal}</div>
      //   </Draggable>
      // )}
    >
      <Form
        layout={"vertical"}
        style={{
          padding: "40px",
        }}
        className={styles.form_container}
      >
        <div style={{ marginBottom: "20px" }}>
          <Button disabled={!isFile} onClick={() => setIsFile(false)}>
            TEXT
          </Button>
          <Button
            disabled={isFile}
            style={{ marginLeft: "20px" }}
            onClick={() => setIsFile(true)}
          >
            Excel File
          </Button>
        </div>

        {isFile ? (
          <Form.Item label="업로드 할 엑셀 파일">
            <Input
              type="file"
              style={{ height: "40px" }}
              accept=".xlsx"
              onChange={(e: any) => setTargetFile(e.target)}
              value={targetFile?.value}
              className={"form-input"}
            />
          </Form.Item>
        ) : (
          <>
            <Form.Item label="회사 이름">
              <Input
                style={{ height: "40px" }}
                placeholder="추가할 회사 이름을 입력해 주세요."
                onChange={(e: any) =>
                  setForm({ ...form, companyName: e.target.value.trim() })
                }
                value={form.companyName}
                className={"form-input"}
              />
            </Form.Item>
            <Form.Item label="인증 코드">
              <Input
                style={{ height: "40px" }}
                placeholder="추가할 회사의 인증 코드를 입력해 주세요."
                onChange={(e: any) =>
                  setForm({ ...form, code: e.target.value.trim() })
                }
                value={form.code}
                className={"form-input"}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
}
