import React from "react";
import { Col, Row, Table, Button, Input, Modal, Form, Select } from "antd";
import Draggable from "react-draggable";
import styles from "../admin.module.css";
import { Option } from "antd/es/mentions";
import CompanyAPI from "src/helpers/apis/company/company";

const UpdateCompany = ({
  isModalOpen,
  handleOk,
  handleCancel,
  form,
  setForm,
  bounds,
  onStart,
  draggleRef,
  onSubmit,
}: any) => {
  return (
    <div>
      <Modal
        title={
          <div
            style={{
              width: "100%",
              // cursor: "move",
            }}
          >
            {form.name} 수정하기
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key='submit' type='primary' onClick={onSubmit}>
            수정하기
          </Button>,
          <Button key='cancel' danger onClick={handleCancel}>
            취소
          </Button>,
        ]}
        // modalRender={(modal) => (
        //   <Draggable
        //     bounds={bounds}
        //     onStart={(event, uiData) => onStart(event, uiData)}
        //   >
        //     <div ref={draggleRef}>{modal}</div>
        //   </Draggable>
        // )}
      >
        <Form
          layout={"vertical"}
          style={{
            padding: "40px",
          }}
          className={styles.form_container}
        >
          <>
            <Form.Item label='회사 이름'>
              <Input
                style={{ height: "40px" }}
                placeholder='추가할 회사 이름을 입력해 주세요.'
                onChange={(e: any) =>
                  setForm({ ...form, rename: e.target.value.trim() })
                }
                value={form.rename}
                className={"form-input"}
              />
            </Form.Item>
            <Form.Item label='인증 코드'>
              <Input
                style={{ height: "40px" }}
                placeholder='추가할 회사의 인증 코드를 입력해 주세요.'
                onChange={(e: any) =>
                  setForm({ ...form, code: e.target.value.trim() })
                }
                value={form.code}
                className={"form-input"}
              />
            </Form.Item>
          </>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdateCompany;
