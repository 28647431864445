import React from "react";
import { Button, Modal } from "antd";

const TwentyEightContent = ({ setDetailPage, detailPage }: any) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "297mm" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "60px 20px 0px 20px",
            alignItems: "center",
          }}
        >
          <Button
            disabled={detailPage === 0}
            onClick={() => {
              setDetailPage((pre: number) => pre - 1);
            }}
          >
            이전
          </Button>

          <span
            style={{
              display: "inline-block",
              margin: "0px 20px",
            }}
          >
            {detailPage + 1} / 12
          </span>

          <Button
            style={{}}
            disabled={detailPage === 11}
            onClick={() => {
              setDetailPage((pre: number) => pre + 1);
            }}
          >
            다음
          </Button>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI' />
        </div>
        <div
          className='htb'
          style={{
            left: "30mm",
            width: "149.82mm",
            top: "35mm",
            height: "231.65mm",
          }}
        >
          <svg
            className='hs'
            viewBox='-2.50 -2.50 154.82 236.65'
            style={{
              left: "-2.50mm",
              top: "-2.50mm",
              width: "154.82mm",
              height: "236.65mm",
            }}
          >
            <defs>
              <pattern
                id='w_270'
                width={10}
                height={10}
                patternUnits='userSpaceOnUse'
              >
                <rect width={10} height={10} fill='rgb(216,216,216)' />
              </pattern>
            </defs>
            <path fill='url(#w_270)' d='M0,0L21.59,0L21.59,7.48L0,7.48L0,0Z ' />
            <path
              fill='url(#w_270)'
              d='M21.59,0L44.36,0L44.36,7.48L21.59,7.48L21.59,0Z '
            />
            <path
              fill='url(#w_270)'
              d='M44.36,0L79.95,0L79.95,7.48L44.36,7.48L44.36,0Z '
            />
            <path
              fill='url(#w_270)'
              d='M79.95,0L149.82,0L149.82,7.48L79.95,7.48L79.95,0Z '
            />
            <path
              d='M0,0 L0,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M21.59,0 L21.59,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.36,0 L44.36,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.95,0 L79.95,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M149.82,0 L149.82,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,0 L149.90,0'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,7.48 L149.90,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,14.60 L149.90,14.60'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,21.73 L149.90,21.73'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,28.86 L149.90,28.86'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,35.98 L149.90,35.98'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,43.11 L149.90,43.11'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,50.24 L149.90,50.24'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,57.36 L149.90,57.36'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,64.49 L149.90,64.49'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,71.61 L149.90,71.61'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,78.74 L149.90,78.74'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,85.87 L149.90,85.87'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,92.99 L149.90,92.99'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M21.51,100.12 L149.90,100.12'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,109.83 L149.90,109.83'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,116.96 L149.90,116.96'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,124.09 L149.90,124.09'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,131.80 L149.90,131.80'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,138.93 L149.90,138.93'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,146.06 L149.90,146.06'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,153.18 L149.90,153.18'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,160.31 L149.90,160.31'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,168.03 L149.90,168.03'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,175.15 L149.90,175.15'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,182.28 L149.90,182.28'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,189.41 L149.90,189.41'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,196.53 L149.90,196.53'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,203.66 L149.90,203.66'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,210.78 L149.90,210.78'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,217.91 L149.90,217.91'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,231.65 L149.90,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M149.82,0 L149.82,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M0,0 L0,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,231.65 L149.90,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,0 L149.90,0'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
          </svg>
          <div
            className='hce'
            style={{
              left: "0mm",
              top: "0mm",
              width: "21.59mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "19.59mm",
                  }}
                >
                  <span className='hrt cs190'>대분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "0mm",
              width: "22.77mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs190'>중분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "0mm",
              width: "35.59mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs190'>소분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "0mm",
              width: "69.87mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs190'>세분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "0mm",
              top: "7.48mm",
              width: "21.59mm",
              height: "224.17mm",
            }}
          />
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "7.48mm",
              width: "22.77mm",
              height: "92.64mm",
            }}
          />
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "7.48mm",
              width: "35.59mm",
              height: "14.25mm",
            }}
          />
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "7.48mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps48'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>블록체인 보안성</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "14.60mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps48'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>블록체인 암호 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "21.73mm",
              width: "35.59mm",
              height: "28.50mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "7.99mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>블록체인 확장&nbsp;</span>
                </div>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "4.86mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "21.73mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps48'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>블록체인 구조&nbsp;</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "28.86mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>차세대 블록체인 플랫폼</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "35.98mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>블록체인 플랫폼 연동 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "43.11mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>블록체인 플랫폼 융합 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "50.24mm",
              width: "35.59mm",
              height: "28.50mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "7.99mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>블록체인 응용&nbsp;</span>
                </div>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "4.86mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>지원</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "50.24mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>블록체인 서비스 지원 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "57.36mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps48'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>DApp 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "64.49mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps48'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>정책지원기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "71.61mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps48'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>도메인별 특화 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "78.74mm",
              width: "35.59mm",
              height: "21.38mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "6.95mm" }}>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>블록체인 관리</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "78.74mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>블록체인 회원관리</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "85.87mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>블록체인 신뢰성 평가</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "92.99mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>블록체인 개발환경</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "100.12mm",
              width: "22.77mm",
              height: "131.53mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "62.20mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs194'>ICT 융합</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "100.12mm",
              width: "35.59mm",
              height: "31.69mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "12.10mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>농축수산ICT</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "100.12mm",
              width: "69.87mm",
              height: "9.72mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "1.29mm" }}>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    농축수산 지능형 정밀 센서 및 제어 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "109.83mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>
                    농축수산 지능정보 서비스 플랫폼 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "116.96mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>농축수산 유통 및 거래 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "124.09mm",
              width: "69.87mm",
              height: "7.72mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "0.30mm" }}>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    농축수산 복합통신 및 ICT 융합표준 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "131.80mm",
              width: "35.59mm",
              height: "21.38mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "6.95mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>헬스케어ICT</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "131.80mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>바이오 센싱 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "138.93mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>헬스케어 서비스 플랫폼 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "146.06mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    헬스케어 진단/치료 시스템 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "153.18mm",
              width: "35.59mm",
              height: "29.10mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "8.28mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>환경ICT</span>
                </div>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "4.86mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "153.18mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    지능형 온실가스 저감 융합 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "160.31mm",
              width: "69.87mm",
              height: "7.72mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "0.30mm" }}>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs199'>
                    지능형 거주지역 생활환경 서비스 융합 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "168.03mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>지능형 자원순환 융합 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "175.15mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    환경재난 대응 융합 플랫폼 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "182.28mm",
              width: "35.59mm",
              height: "21.38mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "7.13mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs194'>스마트시티</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "182.28mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>스마트홈 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "189.41mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>스마트시티 인프라 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "196.53mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    스마트시티 서비스 플랫폼 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "203.66mm",
              width: "35.59mm",
              height: "27.99mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "10.26mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>교통ICT</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "203.66mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>교통 플랫폼 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "210.78mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>교통 커넥션 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "217.91mm",
              width: "69.87mm",
              height: "13.74mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "3.31mm" }}>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>자율 교통 기술</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwentyEightContent;
