import React from "react";

const ThirteenBlock = () => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "16%",
          zIndex: 1000,
        }}
      >
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 20px",
          }}
        >
          2페이지 [선정년도] 선택 후 작성해 주세요. <br />
        </h1>
        {/* <h3
        style={{
          position: "absolute",
          top: "100%",
          left: "10%",
        }}
      >
        11페이지를 작성하셨다면 13페이지로 이동해주세요.
      </h3> */}
      </div>
      <div
        className='hpa'
        style={{ width: "210mm", height: "297mm", opacity: 0.3 }}
      >
        <div
          className='hpN'
          style={{
            left: "98.24mm",
            top: "282mm",
            width: "13.52mm",
            height: "3.53mm",
          }}
        >
          <span className='hrt cs0'>- 13 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "1.25mm",
                height: "4.59mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs15'>Ⅵ. Impact (기여도) &nbsp;</span>
            </div>
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "10.11mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>1. 본&nbsp;</span>
              <span className='hrt cs152'>
                융자사업의 각 항목에 대한 기여도
              </span>
              <span className='hrt cs1'>에 대해 의견을 표시하여 주십시오.</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "16.32mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>■&nbsp;</span>
              <span className='hrt cs183'>기술적 기여도</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "26.11mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "23.11mm",
                height: "26.11mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.50mm",
                  width: "148.59mm",
                  top: "0.50mm",
                  height: "26.11mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.59 31.11'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "153.59mm",
                    height: "31.11mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_120'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_120)'
                    d='M0,0L81.53,0L81.53,9.33L0,9.33L0,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M81.53,0L92.35,0L92.35,9.33L81.53,9.33L81.53,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M92.35,0L106.16,0L106.16,9.33L92.35,9.33L92.35,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M106.16,0L119.97,0L119.97,9.33L106.16,9.33L106.16,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M119.97,0L133.78,0L133.78,9.33L119.97,9.33L119.97,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M133.78,0L147.59,0L147.59,9.33L133.78,9.33L133.78,0Z '
                  />
                  <path
                    d='M0,0 L0,25.12'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M81.53,0 L81.53,25.12'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,25.12'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,9.33 L147.80,9.33'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,13.85 L147.80,13.85'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,19.48 L147.80,19.48'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,25.12 L147.80,25.12'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,25.12'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M0,0 L0,25.12'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,25.12 L147.80,25.12'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "81.53mm",
                    height: "9.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.41mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs129'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "0mm",
                    width: "10.82mm",
                    height: "9.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "9.82mm",
                        }}
                      >
                        <span className='hrt cs60'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "9.82mm",
                        }}
                      >
                        <span className='hrt cs60'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.35mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "9.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.59mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "106.16mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "9.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.59mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>보통</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.97mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "9.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.59mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "133.78mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "9.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "9.33mm",
                    width: "81.53mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          1) 융자사업이 기업의 기술개발에 기여
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "9.33mm",
                    width: "66.06mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.18mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>①…………②…………③………④…………⑤</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "13.85mm",
                    width: "81.53mm",
                    height: "5.63mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.56mm" }}>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>2</span>
                        <span className='hrt cs158'>
                          ) 융자사업이 기업의 기술진 능력 개선
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "13.85mm",
                    width: "66.06mm",
                    height: "5.63mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.73mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>①…………②…………③………④…………⑤</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "19.48mm",
                    width: "81.53mm",
                    height: "5.63mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.56mm" }}>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>3</span>
                        <span className='hrt cs158'>
                          ) 융자사업이 국가 과학기술 발전에 기여
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "19.48mm",
                    width: "66.06mm",
                    height: "5.63mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.73mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>①…………②…………③………④…………⑤</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "51.76mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>■&nbsp;</span>
              <span className='hrt cs183'>경제적 기여도</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "33.38mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "58.55mm",
                height: "33.38mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.50mm",
                  width: "148.59mm",
                  top: "0.50mm",
                  height: "33.38mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.59 38.38'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "153.59mm",
                    height: "38.38mm",
                  }}
                >
                  <path
                    fill='url(#w_120)'
                    d='M0,0L81.53,0L81.53,10.33L0,10.33L0,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M81.53,0L92.35,0L92.35,10.33L81.53,10.33L81.53,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M92.35,0L106.16,0L106.16,10.33L92.35,10.33L92.35,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M106.16,0L119.97,0L119.97,10.33L106.16,10.33L106.16,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M119.97,0L133.78,0L133.78,10.33L119.97,10.33L119.97,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M133.78,0L147.59,0L147.59,10.33L133.78,10.33L133.78,0Z '
                  />
                  <path
                    d='M0,0 L0,32.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M81.53,0 L81.53,32.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,32.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,10.33 L147.80,10.33'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,15.85 L147.80,15.85'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,21.36 L147.80,21.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,26.87 L147.80,26.87'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,32.39 L147.80,32.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,32.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M0,0 L0,32.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,32.39 L147.80,32.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "81.53mm",
                    height: "10.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.91mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs129'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "0mm",
                    width: "10.82mm",
                    height: "10.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.50mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "9.82mm",
                        }}
                      >
                        <span className='hrt cs60'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "9.82mm",
                        }}
                      >
                        <span className='hrt cs60'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.35mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "10.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.08mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "106.16mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "10.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.08mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>보통</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.97mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "10.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.08mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "133.78mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "10.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.50mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "10.33mm",
                    width: "81.53mm",
                    height: "5.51mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.50mm" }}>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>1</span>
                        <span className='hrt cs158'>)&nbsp;</span>
                        <span className='hrt cs30'>
                          융자사업이 기업의 제품 및 서비스 경쟁력 강화에 기여
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "10.33mm",
                    width: "66.06mm",
                    height: "5.51mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.67mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>①…………②…………③………④…………⑤</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "15.85mm",
                    width: "81.53mm",
                    height: "5.51mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.50mm" }}>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>2</span>
                        <span className='hrt cs158'>
                          ) 융자사업이 산업발전에 기여
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "15.85mm",
                    width: "66.06mm",
                    height: "5.51mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.67mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>①…………②…………③………④…………⑤</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "21.36mm",
                    width: "81.53mm",
                    height: "5.51mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.50mm" }}>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>3</span>
                        <span className='hrt cs158'>
                          ) 융자사업이 새로운 수요를 창출하는데 기여
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "21.36mm",
                    width: "66.06mm",
                    height: "5.51mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.67mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>①…………②…………③………④…………⑤</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "26.87mm",
                    width: "81.53mm",
                    height: "5.51mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.50mm" }}>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>4</span>
                        <span className='hrt cs158'>
                          ) 융자사업이 국가 경쟁력을 강화하는데 기여
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "26.87mm",
                    width: "66.06mm",
                    height: "5.51mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.67mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>①…………②…………③………④…………⑤</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "94.47mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>■&nbsp;</span>
              <span className='hrt cs183'>사회적 기여</span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "22.89mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "101.26mm",
                height: "22.89mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.50mm",
                  width: "148.59mm",
                  top: "0.50mm",
                  height: "22.89mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.59 27.89'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "153.59mm",
                    height: "27.89mm",
                  }}
                >
                  <path
                    fill='url(#w_120)'
                    d='M0,0L81.53,0L81.53,9.33L0,9.33L0,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M81.53,0L92.35,0L92.35,9.33L81.53,9.33L81.53,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M92.35,0L106.16,0L106.16,9.33L92.35,9.33L92.35,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M106.16,0L119.97,0L119.97,9.33L106.16,9.33L106.16,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M119.97,0L133.78,0L133.78,9.33L119.97,9.33L119.97,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M133.78,0L147.59,0L147.59,9.33L133.78,9.33L133.78,0Z '
                  />
                  <path
                    d='M0,0 L0,21.90'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M81.53,0 L81.53,21.90'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,21.90'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,9.33 L147.80,9.33'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,13.85 L147.80,13.85'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,21.89 L147.80,21.89'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,21.90'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M0,0 L0,21.90'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,21.89 L147.80,21.89'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "81.53mm",
                    height: "9.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.41mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs129'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "0mm",
                    width: "10.82mm",
                    height: "9.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "9.82mm",
                        }}
                      >
                        <span className='hrt cs60'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "9.82mm",
                        }}
                      >
                        <span className='hrt cs60'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.35mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "9.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.59mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "106.16mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "9.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.59mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>보통</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.97mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "9.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.59mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "133.78mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "9.33mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "9.33mm",
                    width: "81.53mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>1</span>
                        <span className='hrt cs158'>
                          ) 융자사업이 기업의 고용창출에 기여
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "9.33mm",
                    width: "66.06mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.18mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>①…………②…………③………④…………⑤</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "13.85mm",
                    width: "81.53mm",
                    height: "8.04mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>2</span>
                        <span className='hrt cs158'>
                          ) 융자사업이 공공의 이익에 부합
                        </span>
                      </div>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.35mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          &nbsp;&nbsp;&nbsp;(사회발전에 기여)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "13.85mm",
                    width: "66.06mm",
                    height: "8.04mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.94mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>①…………②…………③………④…………⑤</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps98'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "126.68mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps115'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "130.56mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                2. 가장 최근 신청한 융자사업을 통해 개발되는&nbsp;
              </span>
              <span className='hrt cs9'>
                제품 및 서비스의 사업화 성숙도 단계
              </span>
            </div>
            <div
              className='hls ps115'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "135.61mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                를 판단하여 기재해 주십시오. (※사업화 성숙도 단계는 아래 참고표
                참조)&nbsp;
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "12.33mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "140.86mm",
                height: "12.33mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.50mm",
                  width: "148.09mm",
                  top: "0.50mm",
                  height: "12.33mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.08 17.33'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "153.08mm",
                    height: "17.33mm",
                  }}
                >
                  <path
                    d='M0,0 L0,11.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M34.03,0 L34.03,11.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M72.38,0 L72.38,11.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M110.73,0 L110.73,11.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M147.09,0 L147.09,11.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.30,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,5.17 L147.30,5.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,11.33 L147.30,11.33'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M147.09,0 L147.09,11.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M0,0 L0,11.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,11.33 L147.30,11.33'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.30,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "34.03mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "1.80mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.42mm",
                        }}
                      >
                        <span className='hrt cs158'>연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "34.03mm",
                    top: "0mm",
                    width: "38.35mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "1.80mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "34.76mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          과제 신청연도( &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;년)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "72.38mm",
                    top: "0mm",
                    width: "38.35mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "1.80mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "34.76mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          과제 종료연도(
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;년)&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.73mm",
                    top: "0mm",
                    width: "36.36mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "1.80mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "32.75mm",
                        }}
                      >
                        <span className='hrt cs158'>현재(2022년)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "5.17mm",
                    width: "34.03mm",
                    height: "6.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "1.80mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.65mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "30.42mm",
                        }}
                      >
                        <span className='hrt cs43'>사업화성숙도 단계</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "34.03mm",
                    top: "5.17mm",
                    width: "38.35mm",
                    height: "6.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "1.80mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.65mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "34.76mm",
                        }}
                      >
                        <span className='hrt cs43'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
                          단계
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "72.38mm",
                    top: "5.17mm",
                    width: "38.35mm",
                    height: "6.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "1.80mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.65mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "34.76mm",
                        }}
                      >
                        <span className='hrt cs43'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
                          단계
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.73mm",
                    top: "5.17mm",
                    width: "36.36mm",
                    height: "6.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "1.80mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.65mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "32.75mm",
                        }}
                      >
                        <span className='hrt cs43'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
                          단계
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "155.72mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs176'>
                ※ 참고 : 사업화 성숙도(CRL : Commercial Readiness Level)
              </span>
            </div>
            <div
              className='hls ps110'
              style={{
                lineHeight: "69.15mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "162.52mm",
                height: "69.15mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.50mm",
                  width: "147.04mm",
                  top: "0.50mm",
                  height: "69.15mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 152.04 74.15'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "152.04mm",
                    height: "74.15mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_121'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_121)'
                    d='M0,0L17.11,0L17.11,4.52L0,4.52L0,0Z '
                  />
                  <path
                    fill='url(#w_121)'
                    d='M17.11,0L146.04,0L146.04,4.52L17.11,4.52L17.11,0Z '
                  />
                  <path
                    d='M0,0 L0,68.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M17.11,0 L17.11,68.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M146.04,0 L146.04,68.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L146.25,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,4.52 L146.25,4.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,9.05 L146.25,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,13.57 L146.25,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,22.68 L146.25,22.68'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,31.79 L146.25,31.79'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,40.89 L146.25,40.89'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,50 L146.25,50'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,59.11 L146.25,59.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,63.63 L146.25,63.63'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,68.16 L146.25,68.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M146.04,0 L146.04,68.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M0,0 L0,68.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,68.16 L146.25,68.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L146.25,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "17.11mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "15.11mm",
                        }}
                      >
                        <span className='hrt cs163'>CRL 단계</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "17.11mm",
                    top: "0mm",
                    width: "128.93mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "126.93mm",
                        }}
                      >
                        <span className='hrt cs163'>판단 기준</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "4.52mm",
                    width: "17.11mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "15.11mm",
                        }}
                      >
                        <span className='hrt cs144'>1</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "17.11mm",
                    top: "4.52mm",
                    width: "128.93mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "126.93mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          대상기술을 활용한 제품정보나 사례, 시장분석 자료가
                          알려진 바 없거나 일부만 존재
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "9.05mm",
                    width: "17.11mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "15.11mm",
                        }}
                      >
                        <span className='hrt cs144'>2</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "17.11mm",
                    top: "9.05mm",
                    width: "128.93mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "126.93mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          새로운 기술을 기반으로 제품 아이디어는 존재하지만
                          검증되지 않은 상태
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "13.57mm",
                    width: "17.11mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "15.11mm",
                        }}
                      >
                        <span className='hrt cs144'>3</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "17.11mm",
                    top: "13.57mm",
                    width: "128.93mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "126.93mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          경쟁기술·제품 분석으로 신제품의 시장진입을 위한
                          고려사항이 문헌 및 인터뷰 조사를&nbsp;
                        </span>
                      </div>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "126.93mm",
                        }}
                      >
                        <span className='hrt cs144'>통해 도출</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "22.68mm",
                    width: "17.11mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "15.11mm",
                        }}
                      >
                        <span className='hrt cs144'>4</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "17.11mm",
                    top: "22.68mm",
                    width: "128.93mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "126.93mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          제품에 대한 가설검증으로 Value-position, Value-chain,
                          잠재적파트너, 고객 및 관련인
                        </span>
                      </div>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "126.93mm",
                        }}
                      >
                        <span className='hrt cs144'>증·규제 확인</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "31.79mm",
                    width: "17.11mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "15.11mm",
                        }}
                      >
                        <span className='hrt cs144'>5</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "17.11mm",
                    top: "31.79mm",
                    width: "128.93mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "126.93mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          목표시장 및 제품에 대한 정의 완료, 단기에서 중기까지의
                          판매 및 이윤창출 방안을 포
                        </span>
                      </div>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "126.93mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          함한 자금 운영 계획 수립
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "40.89mm",
                    width: "17.11mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "15.11mm",
                        }}
                      >
                        <span className='hrt cs144'>6</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "17.11mm",
                    top: "40.89mm",
                    width: "128.93mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "126.93mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          제품화를 위한 필요사항이 문서화 되고, 설계 최적화 및
                          파트너십 체결, 관련 인증 및&nbsp;
                        </span>
                      </div>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "126.93mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          규제 준수를 위한 절차 진행
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "50mm",
                    width: "17.11mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "15.11mm",
                        }}
                      >
                        <span className='hrt cs144'>7</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "17.11mm",
                    top: "50mm",
                    width: "128.93mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "126.93mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          상품설계, 생산을 위한 인증 규제 대응방안 및 종합적
                          자금운영계획이 완료되어 제품&nbsp;
                        </span>
                      </div>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "126.93mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          생산을 위한 집행이 승인
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "59.11mm",
                    width: "17.11mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "15.11mm",
                        }}
                      >
                        <span className='hrt cs144'>8</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "17.11mm",
                    top: "59.11mm",
                    width: "128.93mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "126.93mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          초기 제품들이 제조되고 판매되는 상태, 대규모 생산 및
                          판매를 위한 상용화 준비 진행
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "63.63mm",
                    width: "17.11mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "15.11mm",
                        }}
                      >
                        <span className='hrt cs144'>9</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "17.11mm",
                    top: "63.63mm",
                    width: "128.93mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "126.93mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          제품의 대량생산 및 보급이 완료
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirteenBlock;
