import React from "react";
import { Button, Modal } from "antd";

const TwentyNineContent = ({ setDetailPage, detailPage }: any) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "297mm" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "60px 20px 0px 20px",
            alignItems: "center",
          }}
        >
          <Button
            disabled={detailPage === 0}
            onClick={() => {
              setDetailPage((pre: number) => pre - 1);
            }}
          >
            이전
          </Button>

          <span
            style={{
              display: "inline-block",
              margin: "0px 20px",
            }}
          >
            {detailPage + 1} / 12
          </span>

          <Button
            style={{}}
            disabled={detailPage === 11}
            onClick={() => {
              setDetailPage((pre: number) => pre + 1);
            }}
          >
            다음
          </Button>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI' />
        </div>
        <div
          className='htb'
          style={{
            left: "30mm",
            width: "149.82mm",
            top: "35mm",
            height: "231.65mm",
          }}
        >
          <svg
            className='hs'
            viewBox='-2.50 -2.50 154.82 236.65'
            style={{
              left: "-2.50mm",
              top: "-2.50mm",
              width: "154.82mm",
              height: "236.65mm",
            }}
          >
            <defs>
              <pattern
                id='w_280'
                width={10}
                height={10}
                patternUnits='userSpaceOnUse'
              >
                <rect width={10} height={10} fill='rgb(216,216,216)' />
              </pattern>
            </defs>
            <path fill='url(#w_280)' d='M0,0L21.59,0L21.59,7.48L0,7.48L0,0Z ' />
            <path
              fill='url(#w_280)'
              d='M21.59,0L44.36,0L44.36,7.48L21.59,7.48L21.59,0Z '
            />
            <path
              fill='url(#w_280)'
              d='M44.36,0L79.95,0L79.95,7.48L44.36,7.48L44.36,0Z '
            />
            <path
              fill='url(#w_280)'
              d='M79.95,0L149.82,0L149.82,7.48L79.95,7.48L79.95,0Z '
            />
            <path
              d='M0,0 L0,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M21.59,0 L21.59,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.36,0 L44.36,187.96'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.36,187.95 L44.36,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.95,0 L79.95,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M149.82,0 L149.82,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,0 L149.90,0'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,7.48 L149.90,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,14.60 L149.90,14.60'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,21.73 L149.90,21.73'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,28.86 L149.90,28.86'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,36.58 L149.90,36.58'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,43.70 L149.90,43.70'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,50.83 L149.90,50.83'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,57.95 L149.90,57.95'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,65.08 L149.90,65.08'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,72.21 L149.90,72.21'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,79.33 L149.90,79.33'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,86.46 L149.90,86.46'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,93.58 L149.90,93.58'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,100.71 L149.90,100.71'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,107.84 L149.90,107.84'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,116.55 L149.90,116.55'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,123.68 L149.90,123.68'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,130.81 L149.90,130.81'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,137.93 L149.90,137.93'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,145.06 L149.90,145.06'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,152.18 L149.90,152.18'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,159.31 L149.90,159.31'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,166.44 L149.90,166.44'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,173.56 L149.90,173.56'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,180.69 L149.90,180.69'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M21.51,187.95 L149.90,187.95'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,195.08 L149.90,195.08'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,202.20 L149.90,202.20'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,209.33 L149.90,209.33'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,216.46 L149.90,216.46'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,223.58 L149.90,223.58'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,231.65 L149.90,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M149.82,0 L149.82,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M0,0 L0,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,231.65 L149.90,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,0 L149.90,0'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
          </svg>
          <div
            className='hce'
            style={{
              left: "0mm",
              top: "0mm",
              width: "21.59mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "19.59mm",
                  }}
                >
                  <span className='hrt cs190'>대분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "0mm",
              width: "22.77mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs190'>중분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "0mm",
              width: "35.59mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs190'>소분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "0mm",
              width: "69.87mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs190'>세분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "0mm",
              top: "7.48mm",
              width: "21.59mm",
              height: "224.17mm",
            }}
          />
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "7.48mm",
              width: "22.77mm",
              height: "180.47mm",
            }}
          />
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "7.48mm",
              width: "35.59mm",
              height: "21.38mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "6.95mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>교육ICT</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "7.48mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>교육 응용 플랫폼 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "14.60mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>교육 응용 인터페이스 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "21.73mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>교육용 툴킷 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "28.86mm",
              width: "35.59mm",
              height: "21.97mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "4.72mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>공공안전/</span>
                </div>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "4.86mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>재난예방ICT</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "28.86mm",
              width: "69.87mm",
              height: "7.72mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "0.30mm" }}>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs200'>
                    공공안전/재난상황 센서 데이터 수집 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "36.58mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    지능형 데이터 처리/예측/예방 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "43.70mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs198'>
                    개인 맞춤형 치안 및 재난 대응 플랫폼
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "50.83mm",
              width: "35.59mm",
              height: "28.50mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "10.51mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>국방ICT</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "50.83mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>지능형 지휘결심 지원 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "57.95mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>지능형 군사작전 지원 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "65.08mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>밀리터리 네트워크 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "72.21mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>실시간 훈련 모니터링 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "79.33mm",
              width: "35.59mm",
              height: "14.25mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "3.39mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>복지ICT</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "79.33mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    소외∙취약계층 지원 플랫폼 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "86.46mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    복지기반 개인 맞춤형 응용 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "93.58mm",
              width: "35.59mm",
              height: "30.10mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "11.48mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs194'>에너지ICT</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "93.58mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>에너지 데이터 수집 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "100.71mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>에너지 빅데이터 플랫폼 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "107.84mm",
              width: "69.87mm",
              height: "8.72mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "0.79mm" }}>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    지능형 에너지 자원 분석/관리/거래 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "116.55mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs201'>
                    지능형 에너지 산업 응용 서비스 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "123.68mm",
              width: "35.59mm",
              height: "28.50mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "10.69mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs194'>제조ICT</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "123.68mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>산업용 ICT 디바이스 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "130.81mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>산업용 네트워크 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "137.93mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>산업용 ICT 플랫폼 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "145.06mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>지능형 제조 서비스</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "152.18mm",
              width: "35.59mm",
              height: "28.50mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "10.69mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs194'>산업응용ICT</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "152.18mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>레저/스포츠 ICT 응용 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "159.31mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>섬유/패션 ICT 응용 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "166.44mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>물류/유통 ICT 응용 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "173.56mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>문화/관광 ICT 응용 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "180.69mm",
              width: "35.59mm",
              height: "7.26mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "0.07mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs194'>기타 ICT융합</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "180.69mm",
              width: "69.87mm",
              height: "7.26mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "0.07mm" }}>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>기타 ICT 융합</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "187.95mm",
              width: "22.77mm",
              height: "43.70mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "18.28mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs194'>사물인터넷</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "187.95mm",
              width: "35.59mm",
              height: "21.38mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "7.13mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs194'>IoT 응용 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "187.95mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>IoT 서비스 매쉬업 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "195.08mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>도메인 데이터 모델링</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "202.20mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>데이터 거래 서비스</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "209.33mm",
              width: "35.59mm",
              height: "22.32mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "7.60mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs194'>IoT플랫폼</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "209.33mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>IoT 데이터 플랫폼</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "216.46mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>지능형 IoT 플랫폼</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "223.58mm",
              width: "69.87mm",
              height: "8.07mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "0.47mm" }}>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>IoT 엣지/포그 컴퓨팅</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwentyNineContent;
