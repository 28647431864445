import Reactm, { useEffect, useState } from "react";
import LoginComponent from "./LoginComponent";
import styles from "./login.module.css";

import AdminAPI from "src/helpers/apis/admin/admin";
import { useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import { login } from "src/store/actions/user";
import { useDispatch } from "react-redux";

const LoginContainer = () => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  // 토큰 없으면 로그인 이동
  useEffect(() => {
    const token = localStorage.getItem("vote_admin_token");
    if (token) {
      navigate("/admin");
    }
  }, []);

  // 로그인 요청
  const handleLogin = () => {
    AdminAPI.requestLogin({
      ...form,
    })
      .then((res: any) => {
        const { token } = res;
        dispatch(login(true));
        localStorage.setItem("vote_admin_token", token);
        navigate("/admin");
      })
      .catch((err: any) => {
        if (err.response.status < 500) {
          messageApi.warning("아이디 또는 비밀번호가 틀렸습니다.");
          setForm((pre) => ({
            ...pre,
            password: "",
          }));
        } else {
          messageApi.error("관리자에게 문의해주세요.");
        }
        console.log(err);
      });
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      <LoginComponent handleLogin={handleLogin} setForm={setForm} form={form} />
    </div>
  );
};

export default LoginContainer;
