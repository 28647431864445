import { get, post, put, deleteAxios } from "src/helpers/apis/api_helper";

interface LoginDataTypes {
  email: string;
  password: string;
}

class AdminAPI {
  BASE_URL: string;

  constructor() {
    this.BASE_URL = "/api/admin";
  }

  // 로그인
  async requestLogin(data: LoginDataTypes, config = {}) {
    const url = this.BASE_URL + "/login";
    return post(url, data, config);
  }
}

export default new AdminAPI();
