import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VoteComponent from "./VoteComponent";
import CryptoJS from "crypto-js";
import useRefresh from "src/hooks/useRefresh";
import GateContainer from "src/layouts/components/Gate/GateContainer";
import VoteAPI from "src/helpers/apis/vote/vote";
import { Button, message } from "antd";
import _, { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { login, setIsValidCheck } from "src/store/actions/user";
import TwentyModal from "src/layouts/components/TwentyModal/TwentyModal";
import SubmitErrorModal from "./SubmitErrorModal/SubmitErrorModal";

import CompanyAPI from "src/helpers/apis/company/company";
import axios from "axios";
import { RootState } from "src/store";
import { setLoading } from "src/store/actions/user";
import { PAGE_DATA } from "./VotePages/utils";

const VoteContainer = () => {
  const [isVoteAxios, setisVoteAxios] = useState(false);

  const [isGetCompany, setIsGetCompany] = useState(false);

  const dispattch = useDispatch();

  // 맨위 상단 저장 버튼 누르기
  const [unitSave, setUnitSave] = useState(false);

  /// 세부내용
  const [detailPage, setDetailPage] = useState(0);
  const [openDetail, setOpenDetail] = useState(false);

  const showDetailModal = () => {
    setOpenDetail(true);
  };

  const handleDetailOk = (e: React.MouseEvent<HTMLElement>) => {
    setOpenDetail(false);
  };

  const handleDetailCancel = (e: React.MouseEvent<HTMLElement>) => {
    setOpenDetail(false);
  };

  ///

  const [messageApi, contextHolder] = message.useMessage();

  const location = useLocation();
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("");
  const [votePage, setVotePage] = useState(1);

  const dispatch = useDispatch();
  const { isLogin } = useSelector((state: any) => {
    return {
      isLogin: state.user.isLogin,
    };
  });

  const [voteData, setVoteData] = useState<any>();

  // content가 업데이트 됐는지 안됐는지
  const [isUpdate, setisUpdate] = useState(false);
  // 페이지 이동 index
  const [nextPage, setNextPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 모달을 통해 사용자가 서버 업데이트 할건지 말건지
  const [serverUpdate, setServerUpdate] = useState(true);

  // 제출 버튼 (폼 확인)

  useEffect(() => {
    if (nextPage !== votePage) {
      if (isUpdate) {
        showModal();
      } else {
        navigate(`/vote/${nextPage}/${companyName}`);
      }
    }
  }, [nextPage]);

  // 토큰 확인
  const handleGetCompany = async () => {
    const token = localStorage.getItem("vote_token");
    const adminToken = localStorage.getItem("vote_admin_token");
    const pageCompany = location.pathname.split("vote")[1].slice(1);
    const cryptoCompany = pageCompany.slice(pageCompany.indexOf("/") + 1);
    if (adminToken) {
      setIsGetCompany(true);
      return;
    }

    try {
      const { company } = await CompanyAPI.getCompany({
        headers: {
          Authorization: token ? token : adminToken,
        },
        params: {
          companyName: cryptoCompany,
        },
      });
      if (company.status) {
        navigate("/block");
      } else {
        setIsGetCompany(true);
      }
    } catch (e: any) {
      const err: any = e;
      if (err.response.status < 500) {
        if (err.response.status === 403) {
          // alert("접근 권한이 없습니다.1");
          dispatch(login(false));
        } else if (err.response.status === 404 || err.response.status === 401) {
          // alert("접근 권한이 없습니다.2");
          dispatch(login(false));
        }
        console.log(err);
      } else {
        dispatch(login(false));
        messageApi.warning("관리자에게 문의해 주세요.");
      }
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("vote_token");
    const adminToken = localStorage.getItem("vote_admin_token");
    if (token || adminToken) {
      dispatch(login(true));
      handleGetCompany();
    }
  }, [location.pathname, isLogin]);

  useLayoutEffect(() => {
    setisVoteAxios(true);
  }, [location.pathname]);

  // vote 정보 가져오기
  useEffect(() => {
    if (!isLogin) return;
    const token = localStorage.getItem("vote_token");
    const adminToken = localStorage.getItem("vote_admin_token");
    if (!(token || adminToken) || !isVoteAxios || !isGetCompany) {
      return;
    }

    let cryptoCompany: any;
    try {
      const pageCompany = location.pathname.split("vote")[1].slice(1);
      cryptoCompany = pageCompany.slice(pageCompany.indexOf("/") + 1);
      const newPage = pageCompany.slice(0, pageCompany.indexOf("/"));
      dispatch(setIsValidCheck(false));
      setCompanyName(cryptoCompany);
      setVotePage(Number(newPage));
      setNextPage(Number(newPage));
      setisUpdate(false);
      setServerUpdate(false);
      VoteAPI.getVote({
        headers: {
          Authorization: token ? token : adminToken,
        },
        params: {
          companyName: cryptoCompany,
        },
      })
        .then((res) => {
          for (let key of Object.keys(PAGE_DATA) as any) {
            if (isEmpty(res.vote[key])) {
              res.vote[key] = PAGE_DATA[key];
            }
          }

          setVoteData(res);
          window.scrollTo(0, 0);
          setisVoteAxios(false);
        })
        .catch((err) => {
          if (err.response.status >= 500) {
            messageApi.error("관리자에게 문의해 주세요.");
          } else if (err.response.status === 403) {
            // alert("접근 권한이 없습니다.3");
            // navigate("/");
            dispatch(login(false));
          } else if (err.response.status === 404) {
            dispatch(login(false));
            alert("잘못된 주소로 접근했습니다.");
            navigate("/");
          } else {
            // alert("로그인 정보가 만료됐습니다. 다시 로그인 해주세요.");
            dispatch(login(false));
          }
        });
    } catch (err) {
      console.log(err);
      navigate("/");
    }
  }, [isLogin, location.pathname, isVoteAxios, isGetCompany]);

  useRefresh({ isLogin });
  if (!isLogin) {
    return (
      <>
        {contextHolder}
        <GateContainer />
      </>
    );
  }

  const goPage = (index: number) => {
    // navigate(`/vote/${index}/${companyName}`);
    setNextPage(index);
  };

  // 저장 모달 열기
  const showModal = () => {
    setIsModalOpen(true);
  };

  // 저장
  const handleOk = () => {
    setIsModalOpen(false);
    setServerUpdate(true);
  };

  // 모달 닫기
  const handleCancel = () => {
    setNextPage(votePage);
    setIsModalOpen(false);
  };

  // 서버 저장 안하고 이동
  const modalCancelGoPage = () => {
    setIsModalOpen(false);
    navigate(`/vote/${nextPage}/${companyName}`);
  };

  const fileDownload = async (isPdf: boolean) => {
    const data = {
      url: isPdf ? "/pdf.pdf" : "/origin-2024.hwp",
      name: isPdf
        ? "/정보통신응용기술개발지원(융자)사업 성과조사 협조 요청.pdf"
        : "/2024년 융자사업조사표.hwp",
    };

    dispatch(setLoading(true));
    const res = await axios({
      url: data.url,
      responseType: "arraybuffer",
    });

    let url = URL.createObjectURL(new Blob([res.data]));
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.download = data.name;
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);
    dispatch(setLoading(false));
  };

  const pageCompany = location.pathname.split("vote")[1].slice(1);
  const page = pageCompany.split("/")[0];

  return (
    <div>
      {contextHolder}
      <div style={{ width: "210mm", margin: "auto" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="">
            {/* <Button style={{}} onClick={() => fileDownload(true)}>
              <i className='fas fa-download'></i> &nbsp;&nbsp; 공문
            </Button> */}
            <Button
              style={{
                margin: "0px 2rem 0px 1rem",
              }}
              onClick={() => fileDownload(false)}
            >
              <i className="fas fa-download"></i>&nbsp;&nbsp; 설문지
            </Button>

            <Button style={{}} onClick={showDetailModal}>
              기술분류체계
            </Button>
          </div>

          <div>
            {page !== "1" && (
              <>
                <Button
                  style={{
                    marginLeft: "20px",
                  }}
                  onClick={() => {
                    setUnitSave(true);
                  }}
                >
                  저장
                </Button>
                <Button
                  style={{
                    marginLeft: "20px",
                  }}
                  onClick={() => {
                    dispatch(setIsValidCheck(true));
                  }}
                >
                  제출
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      {voteData && (
        <VoteComponent
          setVotePage={setVotePage}
          votePage={votePage}
          goPage={goPage}
          voteData={voteData}
          isUpdate={isUpdate}
          setisUpdate={setisUpdate}
          nextPage={nextPage}
          companyName={companyName}
          handleOk={handleOk}
          handleCancel={handleCancel}
          isModalOpen={isModalOpen}
          modalCancelGoPage={modalCancelGoPage}
          serverUpdate={serverUpdate}
          setUnitSave={setUnitSave}
          unitSave={unitSave}
          setServerUpdate={setServerUpdate}
        />
      )}
      <TwentyModal
        showModal={showDetailModal}
        open={openDetail}
        handleOk={handleDetailOk}
        handleCancel={handleDetailCancel}
        setDetailPage={setDetailPage}
        detailPage={detailPage}
        setisUpdate={setisUpdate}
      />
      <SubmitErrorModal
        isUpdate={isUpdate}
        showModal={showModal}
        setUnitSave={setUnitSave}
        setisUpdate={setisUpdate}
      />
    </div>
  );
};

export default VoteContainer;
