import React from "react";
import SixteenPageContainer from "src/Pages/vote/VotePages/teen/SixteenPage/SixteenPageContainer";
import OnePageContainer from "src/Pages/vote/VotePages/zero/OnePage/OnePageContainer";

import { Button, Space, Modal } from "antd";
import SeventeenPageContainer from "./VotePages/teen/SeventeenPage/SeventeenPageContainer";
import EighteenPageContainer from "./VotePages/teen/EighteenPage/EighteenPageContainer";
import ThreePageContainer from "./VotePages/zero/ThreePage/ThreePageContainer";
import TwoPageContainer from "./VotePages/zero/TwoPage/TwoPageContainer";
import FourPageContainer from "./VotePages/zero/FourPage/FourPageContainer";
import FivePageContainer from "./VotePages/zero/FivePage/FivePageContainer";
import SixPageContainer from "./VotePages/zero/SixPage/SixPageContainer";
import SevenPageContainer from "./VotePages/zero/Seven/SevenPageContainer";
import EightPageContainer from "./VotePages/zero/EightPage/EightPageContainer";
import NinePageContainer from "./VotePages/zero/NinePage/NinePageContainer";
import TeenPageContainer from "./VotePages/teen/TeenPage/TeenPageContainer";
import ElevenPageContainer from "./VotePages/teen/ElevenPage/ElevenPageContainer";
import TwelvePageContainer from "./VotePages/teen/TwelvePage/TwelvePageContainer";
import ThirteenPageContainer from "./VotePages/teen/ThirteenPage/ThirteenPageContainer";
import FourTeenPageContainer from "./VotePages/teen/FourTeenPage/FourTeenPageContainer";
import FifteenPageContainer from "./VotePages/teen/FifteenPage/FifteenPageContainer";

const VotePages = ({
  votePage,
  nextPage,
  companyName,
  isUpdate,
  currentPage,
  voteData,
  setisUpdate,
  serverUpdate,
  setUnitSave,
  unitSave,
  setServerUpdate,
}: any) => {
  const props = {
    voteData,
    setisUpdate,
    nextPage,
    companyName,
    currentPage: votePage,
    serverUpdate,
    setUnitSave,
    unitSave,
    setServerUpdate,
  };

  const votePageList = [
    0,
    <OnePageContainer {...props} />,
    <TwoPageContainer {...props} />,
    <ThreePageContainer {...props} />,
    <FourPageContainer {...props} />,
    <FivePageContainer {...props} />,
    <SixPageContainer {...props} />,
    <SevenPageContainer {...props} />,
    <EightPageContainer {...props} />,
    <NinePageContainer {...props} />,
    <TeenPageContainer {...props} />,
    <ElevenPageContainer {...props} />,
    <TwelvePageContainer {...props} />,
    <ThirteenPageContainer {...props} />,
    <FourTeenPageContainer {...props} />,
    <FifteenPageContainer {...props} />,
    <SixteenPageContainer {...props} />,
    <SeventeenPageContainer {...props} />,
    <EighteenPageContainer {...props} />,
    19,
  ];

  return <div style={{ marginBottom: "20px" }}>{votePageList[votePage]}</div>;
};

const VoteComponent = ({
  setVotePage,
  votePage,
  goPage,
  voteData,
  isUpdate,
  setisUpdate,
  nextPage,
  companyName,
  isModalOpen,
  handleOk,
  handleCancel,
  modalCancelGoPage,
  serverUpdate,
  setUnitSave,
  unitSave,
  setServerUpdate,
}: any) => {
  return (
    <div>
      <div
        style={{
          margin: "20px auto",
          width: "210mm",
          // display: "flex",
          // justifyContent: "center",
          // margin: "20px 0px",
        }}
      >
        <div>
          <div className="all-page-container">
            <VotePages
              votePage={votePage}
              nextPage={nextPage}
              companyName={companyName}
              isUpdate={isUpdate}
              currentPage={votePage}
              voteData={voteData}
              setisUpdate={setisUpdate}
              serverUpdate={serverUpdate}
              setUnitSave={setUnitSave}
              unitSave={unitSave}
              setServerUpdate={setServerUpdate}
            />
          </div>
        </div>
      </div>
      <Space wrap style={{ display: "flex", justifyContent: "center" }}>
        {new Array(18).fill(undefined).map((_, index) => {
          return (
            <Button
              key={index}
              onClick={() => goPage(index + 1)}
              type={votePage === index + 1 ? "primary" : undefined}
              disabled={votePage === index + 1}
            >
              {index + 1}
            </Button>
          );
        })}
      </Space>
      <Modal
        title="저장"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[
          <Button key="serversave" onClick={handleOk}>
            저장하기
          </Button>,

          <Button key="servercancel" danger onClick={modalCancelGoPage}>
            저장하지 않고 이동
          </Button>,
        ]}
      >
        <p>변경사항이 있습니다. 저장하시겠습니까?</p>
      </Modal>
    </div>
  );
};

export default VoteComponent;
