import { Button, Form, Input, Radio } from "antd";
import { useState } from "react";
import styles from "./login.module.css";

const LoginComponent = ({ form, setForm, handleLogin }: any) => {
  const [formLayout, setFormLayout] = useState("vertical");
  const onFormLayoutChange = ({ layout }: any) => {
    setFormLayout(layout);
  };
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;

  return (
    <Form
      {...formItemLayout}
      layout={"vertical"}
      initialValues={{
        layout: formLayout,
      }}
      onValuesChange={onFormLayoutChange}
      style={{
        maxWidth: 600,
      }}
      className={styles.form_container}
      onKeyDown={(e: any) => {
        if (e.code === "Enter") {
          if (form.password && form.email) {
            handleLogin();
          }
        }
      }}
    >
      <div style={{ marginBottom: "40px", textAlign: "center" }}>
        <h2>관리자 로그인을 진행해 주세요.</h2>
      </div>
      <Form.Item label='아이디'>
        <Input
          value={form.email}
          onChange={(e) => setForm({ ...form, email: e.target.value.trim() })}
          style={{ height: "40px" }}
          placeholder='아이디를 입력해 주세요.'
          className='form-input'
        />
      </Form.Item>
      <Form.Item label='비밀번호'>
        <Input
          value={form.password}
          type='password'
          autoComplete='false'
          onChange={(e) =>
            setForm({ ...form, password: e.target.value.trim() })
          }
          style={{ height: "40px" }}
          placeholder='비밀번호를 입력해 주세요.'
          className='form-input'
        />
      </Form.Item>
      <Button
        disabled={!form.password || !form.email}
        onClick={handleLogin}
        style={{ width: "100%" }}
        type='primary'
      >
        Submit
      </Button>
    </Form>
  );
};
export default LoginComponent;
