import React from "react";
import { Button, Modal } from "antd";

const TwentySevenContent = ({ setDetailPage, detailPage }: any) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "297mm" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "60px 20px 0px 20px",
            alignItems: "center",
          }}
        >
          <Button
            disabled={detailPage === 0}
            onClick={() => {
              setDetailPage((pre: number) => pre - 1);
            }}
          >
            이전
          </Button>

          <span
            style={{
              display: "inline-block",
              margin: "0px 20px",
            }}
          >
            {detailPage + 1} / 12
          </span>

          <Button
            style={{}}
            disabled={detailPage === 11}
            onClick={() => {
              setDetailPage((pre: number) => pre + 1);
            }}
          >
            다음
          </Button>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI' />
        </div>
        <div
          className='htb'
          style={{
            left: "30mm",
            width: "149.82mm",
            top: "35mm",
            height: "231.65mm",
          }}
        >
          <svg
            className='hs'
            viewBox='-2.50 -2.50 154.82 236.65'
            style={{
              left: "-2.50mm",
              top: "-2.50mm",
              width: "154.82mm",
              height: "236.65mm",
            }}
          >
            <defs>
              <pattern
                id='w_260'
                width={10}
                height={10}
                patternUnits='userSpaceOnUse'
              >
                <rect width={10} height={10} fill='rgb(216,216,216)' />
              </pattern>
            </defs>
            <path fill='url(#w_260)' d='M0,0L21.59,0L21.59,7.48L0,7.48L0,0Z ' />
            <path
              fill='url(#w_260)'
              d='M21.59,0L44.36,0L44.36,7.48L21.59,7.48L21.59,0Z '
            />
            <path
              fill='url(#w_260)'
              d='M44.36,0L79.95,0L79.95,7.48L44.36,7.48L44.36,0Z '
            />
            <path
              fill='url(#w_260)'
              d='M79.95,0L149.82,0L149.82,7.48L79.95,7.48L79.95,0Z '
            />
            <path
              d='M0,0 L0,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M21.59,0 L21.59,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.36,0 L44.36,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.95,0 L79.95,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M149.82,0 L149.82,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,0 L149.90,0'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,7.48 L149.90,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,19.19 L149.90,19.19'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,26.32 L149.90,26.32'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,35.03 L149.90,35.03'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,43.53 L149.90,43.53'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,50.66 L149.90,50.66'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,57.78 L149.90,57.78'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,64.91 L149.90,64.91'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,72.04 L149.90,72.04'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,79.16 L149.90,79.16'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,86.29 L149.90,86.29'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,94.01 L149.90,94.01'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,101.13 L149.90,101.13'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,108.26 L149.90,108.26'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,115.39 L149.90,115.39'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,122.51 L149.90,122.51'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M21.51,131.40 L149.90,131.40'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,138.53 L149.90,138.53'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,145.65 L149.90,145.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,152.78 L149.90,152.78'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,159.90 L149.90,159.90'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,167.03 L149.90,167.03'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,174.16 L149.90,174.16'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,181.28 L149.90,181.28'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,188.41 L149.90,188.41'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,195.53 L149.90,195.53'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,202.66 L149.90,202.66'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,209.79 L149.90,209.79'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,216.91 L149.90,216.91'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,224.04 L149.90,224.04'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,231.65 L149.90,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M149.82,0 L149.82,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M0,0 L0,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,231.65 L149.90,231.65'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,0 L149.90,0'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
          </svg>
          <div
            className='hce'
            style={{
              left: "0mm",
              top: "0mm",
              width: "21.59mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "19.59mm",
                  }}
                >
                  <span className='hrt cs190'>대분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "0mm",
              width: "22.77mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs190'>중분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "0mm",
              width: "35.59mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs190'>소분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "0mm",
              width: "69.87mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs190'>세분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "0mm",
              top: "7.48mm",
              width: "21.59mm",
              height: "188.05mm",
            }}
          />
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "7.48mm",
              width: "22.77mm",
              height: "123.92mm",
            }}
          />
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "7.48mm",
              width: "35.59mm",
              height: "36.05mm",
            }}
          />
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "7.48mm",
              width: "69.87mm",
              height: "11.71mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "2.29mm" }}>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs197'>
                    3D프린팅 가소성 고분자 소재 및 가공 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "19.19mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    3D프린팅 세라믹 소재 및 가공 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "26.32mm",
              width: "69.87mm",
              height: "8.72mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "0.79mm" }}>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs198'>
                    3D프린팅 바이오/의료용 소재 및 가공 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "35.03mm",
              width: "69.87mm",
              height: "8.50mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "0.68mm" }}>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    3D프린팅 융복합 소재 및 가공 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "43.53mm",
              width: "35.59mm",
              height: "57.60mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "22.54mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>3D프린팅&nbsp;</span>
                </div>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "4.86mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>소프트웨어</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "43.53mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>3D프린팅 역설계 SW</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "50.66mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>3D프린팅 CAD 모델링 SW</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "57.78mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>3D프린팅 적층 해석 SW</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "64.91mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>3D프린팅 메시 모델링 SW</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "72.04mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>3D프린팅 서포트 생성 SW</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "79.16mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>3D프린팅 슬라이서 SW</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "86.29mm",
              width: "69.87mm",
              height: "7.72mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "0.30mm" }}>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    3D프린팅 적층제조 호스트/컨트롤러 SW
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "94.01mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>3D프린팅 적층 모니터링 SW</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "101.13mm",
              width: "35.59mm",
              height: "30.26mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "8.87mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>3D프린팅&nbsp;</span>
                </div>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "4.86mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>응용/서비스</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "101.13mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    3D프린팅 의료/바이오 산업 응용
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "108.26mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>3D프린팅 제조 산업 응용</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "115.39mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    3D프린팅 생활밀착형 상품 응용
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "122.51mm",
              width: "69.87mm",
              height: "8.89mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "0.88mm" }}>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    3D프린팅 제조 기반 서비스 플랫폼
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "131.40mm",
              width: "22.77mm",
              height: "64.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "25.80mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs191'>지능형</span>
                </div>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "4.86mm",
                    height: "3.88mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs191'>디바이스</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "131.40mm",
              width: "35.59mm",
              height: "28.50mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "7.99mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>지능형디바이스&nbsp;</span>
                </div>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "4.86mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>하드웨어</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "131.40mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    지능형디바이스 센싱/입력 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "138.53mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>지능형디바이스 출력 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "145.65mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    지능형디바이스 전원/저장 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "152.78mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    지능형디바이스 네트워크 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "159.90mm",
              width: "35.59mm",
              height: "35.63mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "11.55mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>지능형디바이스&nbsp;</span>
                </div>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "4.86mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>소프트웨어</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "159.90mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    지능형디바이스 입력/전처리 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "167.03mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    지능형디바이스 인지분석판단 기술
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "174.16mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>지능형디바이스 출력 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "181.28mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    지능형디바이스 소프트웨어 플랫폼
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "188.41mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>
                    지능형디바이스 서비스 플랫폼
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "0mm",
              top: "195.53mm",
              width: "21.59mm",
              height: "36.12mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "11.79mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "19.59mm",
                  }}
                >
                  <span className='hrt cs191'>블록체인</span>
                </div>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "4.86mm",
                    height: "3.88mm",
                    width: "19.59mm",
                  }}
                >
                  <span className='hrt cs191'>·융합</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "195.53mm",
              width: "22.77mm",
              height: "36.12mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "14.50mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs194'>블록체인</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "195.53mm",
              width: "35.59mm",
              height: "36.12mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "11.79mm" }}>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>블록체인 기반&nbsp;</span>
                </div>
                <div
                  className='hls ps22'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "4.86mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "195.53mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps48'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>분산합의 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "202.66mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps48'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>분산원장 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "209.79mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps48'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>고성능 트랜젝션 처리 기술</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "216.91mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps48'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>스마트 계약</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "224.04mm",
              width: "69.87mm",
              height: "7.61mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "0.24mm" }}>
                <div
                  className='hls ps48'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>블록체인 네트워킹 기술</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwentySevenContent;
