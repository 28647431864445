import AdminContainer from "./Pages/admin/AdminContainer";
import LoginContainer from "./Pages/login/LoginContainer";
import Main from "src/Pages/Main";
import VoteContainer from "./Pages/vote/VoteContainer";

import SixteenPageContainer from "./Pages/vote/VotePages/teen/SixteenPage/SixteenPageContainer";
import BlockPage from "./Pages/block/BlockPage";
import PDFContainer from "./Pages/pdf/PDFContainer";

export const routes = [
  {
    path: "/admin",
    name: "Admin",
    component: AdminContainer,
  },
  {
    path: "/admin/pdf",
    name: "PDF",
    component: PDFContainer,
  },
  {
    path: "/block",
    name: "Block",
    component: BlockPage,
  },
  {
    path: "/admin/login",
    name: "Login",
    component: LoginContainer,
  },
  {
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    path: "/sixteen",
    name: "SixteenPageContainer",
    component: SixteenPageContainer,
  },
  // {
  //   path: "/vote/*",
  //   name: "VoteContainer",
  //   component: VoteContainer,
  // },
  {
    path: "/vote/:page/*",
    name: "VoteContainer",
    component: VoteContainer,
  },
];
