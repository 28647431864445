import React, { useState, useEffect } from "react";
import _ from "lodash";
import VoteAPI from "src/helpers/apis/vote/vote";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { login, setIsError, setTargetPage } from "src/store/actions/user";
import { checkSubmitForm } from "src/ts/formSubmit";
import CompanyAPI from "src/helpers/apis/company/company";
import { RootState } from "src/store";

const useSubmit = ({
  companyName,
  vote,
  setUnitSave,
  setServerUpdate,
  setisUpdate,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isValidCheck, targetPage } = useSelector((state: RootState) => {
    return {
      isValidCheck: state.user.isValidCheck,
      targetPage: state.user.targetPage,
    };
  });

  const { messageApi } = useSelector((state: RootState) => {
    return {
      messageApi: state.user.messageApi,
    };
  });

  useEffect(() => {
    if (isValidCheck) {
      VoteAPI.updateVote({
        companyName,
        vote,
      })
        .then(async (res) => {
          const pageCompany = location.pathname.split("vote")[1].slice(1);
          const cryptoCompany = pageCompany.slice(pageCompany.indexOf("/") + 1);

          const [checkResult, isError] = checkSubmitForm({
            ...vote,
          });

          console.log(isError);

          if (isError) {
            messageApi.info("문항을 다시 확인해 주세요.");
            setUnitSave(false);
            setServerUpdate(false);
            setisUpdate(false);
            dispatch(
              setIsError({
                checked: true,
                checkResult,
              })
            );

            if (targetPage !== -1) {
              navigate(`/vote/${targetPage}/${cryptoCompany}`);
              dispatch(setTargetPage(-1));
            }
          } else {
            if (targetPage !== -1) {
              navigate(`/vote/${targetPage}/${cryptoCompany}`);
              dispatch(setTargetPage(-1));
              return;
            }

            const token = localStorage.getItem("vote_token");
            const adminToken = localStorage.getItem("vote_admin_token");

            try {
              await CompanyAPI.putCompanyStatus(
                {},
                {
                  headers: {
                    Authorization: token ? token : adminToken,
                  },
                  params: {
                    companyName: cryptoCompany,
                  },
                }
              );
              alert("제출을 완료했습니다. 참여해주셔서 감사합니다.");
              navigate(`/`);
            } catch (e: any) {
              const err: any = e;
              if (
                err.response.status === 404 ||
                err.response.status === 400 ||
                err.response.status === 401
              ) {
                alert("로그인 정보가 만료됐습니다. 다시 로그인 해주세요.");
                dispatch(login(false));
              } else {
                messageApi.warning("관리자에게 문의해 주세요.");
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status < 500) {
            dispatch(login(false));
            messageApi.info(
              "로그인 정보가 만료 됐습니다. 다시 로그인 해 주세요."
            );
            const token = localStorage.getItem("vote_token");
          } else {
            messageApi.error("관리자에게 문의해 주세요.");
          }
        });
    }
  }, [isValidCheck]);

  return null;
};

export default useSubmit;
