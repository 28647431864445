import React from "react";
import { Col, Row, Table, Button, Input, Modal, Form, Select } from "antd";
import Draggable from "react-draggable";
import styles from "../admin.module.css";
import { Option } from "antd/es/mentions";
import CompanyAPI from "src/helpers/apis/company/company";

const DeleteCompany = ({
  isModalOpen,
  handleOk,
  handleCancel,
  form,
  setForm,
  bounds,
  onStart,
  draggleRef,
  onSubmit,
  companyName,
}: any) => {
  return (
    <div>
      <Modal
        title={
          <div
            style={{
              width: "100%",
              // cursor: "move",
            }}
          >
            {companyName?.name} 삭제하기
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key='submit' type='primary' onClick={onSubmit}>
            삭제하기
          </Button>,
          <Button key='cancel' danger onClick={handleCancel}>
            취소
          </Button>,
        ]}
        // modalRender={(modal) => (
        //   <Draggable
        //     bounds={bounds}
        //     onStart={(event, uiData) => onStart(event, uiData)}
        //   >
        //     <div ref={draggleRef}>{modal}</div>
        //   </Draggable>
        // )}
      >
        <Form
          layout={"vertical"}
          style={{
            padding: "40px",
          }}
          className={styles.form_container}
        >
          <>
            <p>정말 삭제 하시겠습니까?</p>
            <p>삭제한 데이터는 복구 할 수 없습니다.</p>
          </>
        </Form>
      </Modal>
    </div>
  );
};

export default DeleteCompany;
