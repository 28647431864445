import React, { useEffect, useState } from "react";
import { Button, Modal, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import { setIsError, setIsValidCheck } from "src/store/actions/user";
import { useLocation, useNavigate } from "react-router-dom";
import { setTargetPage } from "src/store/actions/user";

const pages = [
  "twoPage",
  "threePage",
  "fourPage",
  "fivePage",
  "sixPage",
  "sevenPage",
  "eightPage",
  "ninePage",
  "teenPage",
  "elevenPage",
  "twelvePage",
  "thirteenPage",
  "fourteenPage",
  "fifteenPage",
  "sixteenPage",
  "seventeenPage",
  "eighteenPage",
];

const pageNames: any = {
  twoPage: "2페이지",
  threePage: "3페이지",
  fourPage: "4페이지",
  fivePage: "5페이지",
  sixPage: "6페이지",
  sevenPage: "7페이지",
  eightPage: "8페이지",
  ninePage: "9페이지",
  teenPage: "10페이지",
  elevenPage: "11페이지",
  twelvePage: "12페이지",
  thirteenPage: "13페이지",
  fourteenPage: "14페이지",
  fifteenPage: "15페이지",
  sixteenPage: "16페이지",
  seventeenPage: "17페이지",
  eighteenPage: "18페이지",
};

const pageIndex: any = {
  twoPage: 2,
  threePage: 3,
  fourPage: 4,
  fivePage: 5,
  sixPage: 6,
  sevenPage: 7,
  eightPage: 8,
  ninePage: 9,
  teenPage: 10,
  elevenPage: 11,
  twelvePage: 12,
  thirteenPage: 13,
  fourteenPage: 14,
  fifteenPage: 15,
  sixteenPage: 16,
  seventeenPage: 17,
  eighteenPage: 18,
};

const noti_key = "check_valid";

const SubmitErrorModal = ({ isUpdate, setIsModalOpen }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isModal, setIsModal] = useState(false);
  const [page, setPage] = useState<any>(1);

  const pageCompany = location.pathname.split("vote")[1].slice(1);
  const cryptoCompany = pageCompany.slice(pageCompany.indexOf("/") + 1);

  const { isError, checkResult, targetPage }: any = useSelector(
    (state: RootState) => {
      return {
        isError: state.user.isError,
        checkResult: state.user.checkResult,
        targetPage: state.user.targetPage,
      };
    }
  );
  useEffect(() => {
    // console.log(checkResult);
  }, [checkResult]);

  useEffect(() => {
    if (isError) {
      // api.destroy(noti_key);
      openNotification();
    }
  }, [isError, isUpdate]);

  const handleOk = () => {
    dispatch(
      setIsError({
        checked: false,
        checkResult: {},
      })
    );
    dispatch(setIsValidCheck(false));
  };

  const handleCancel = () => {
    dispatch(
      setIsError({
        checked: false,
        checkResult: {},
      })
    );
    dispatch(setIsValidCheck(false));
  };

  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    api.open({
      message: <h4>아래 문항을 다시 확인해 주세요.</h4>,
      key: noti_key,
      description: (
        <div>
          <div style={{ marginTop: "30px" }}></div>
          {pages.map((page) => {
            const checkPage = Object.keys(checkResult);
            if (checkPage.includes(page)) {
              return (
                <React.Fragment key={page}>
                  <div style={{}}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBlock: "10px",
                      }}
                    >
                      <h2 className='submit-form-h2' style={{}}>
                        {pageNames[page]}
                      </h2>
                      <div
                        className='submit-form-div'
                        onClick={() => {
                          if (!isUpdate) {
                            navigate(
                              `/vote/${pageIndex[page]}/${cryptoCompany}`
                            );
                          } else {
                            // setPage(`${pageIndex[page]}`);
                            dispatch(setTargetPage(`${pageIndex[page]}`));
                            setIsModal(true);
                          }
                        }}
                      >
                        <span className='submit-form-span'>이동</span>
                        <i className='fas fa-angle-double-right'></i>
                      </div>
                    </div>
                    {Object.keys(checkResult[page]).map((item, index) => {
                      return (
                        <div key={index}>
                          <span style={{ whiteSpace: "pre-line" }}>
                            {checkResult[page][item]}
                          </span>
                        </div>
                      );
                    })}
                    <hr style={{ margin: "20px 0px 30px 0px" }} />
                  </div>
                </React.Fragment>
              );
            }
            return null;
          })}
        </div>
      ),

      duration: 0,
      style: { overflowY: "auto", maxHeight: "calc(100vh - 100px)" },
      onClose: handleCancel,
    });
  };

  return (
    // <Modal
    //   title='작성하지 않은 목록'
    //   open={isError}
    //   onOk={handleOk}
    //   onCancel={handleCancel}
    //   footer={<></>}
    // >

    // </Modal>
    <>
      {contextHolder}

      <Modal
        title='저장'
        open={isModal}
        onOk={() => setIsModal(true)}
        onCancel={() => setIsModal(false)}
        centered
        footer={[
          <Button
            key='serversave'
            onClick={() => {
              dispatch(setIsValidCheck(true));
              setIsModal(false);
            }}
          >
            저장하기
          </Button>,

          <Button
            key='servercancel'
            danger
            onClick={() => {
              setIsModal(false);
              navigate(`/vote/${targetPage}/${cryptoCompany}`);
            }}
          >
            저장하지 않고 이동
          </Button>,
        ]}
      >
        <p>수정 사항이 있습니다. 저장 후 이동해 주세요.</p>
      </Modal>
    </>
  );
};

export default SubmitErrorModal;
