export const PAGE_DATA: any = {
  twoPage: {
    info: {
      company: "",
      projectName1: "",
      date1: false,
      projectName2: "",
      date2: false,
      responseName: "",
      department: "",
      phone: "",
      email: "",
    },
    one: {
      one: "",
      two: {
        number: false,
        year: false,
      },
      three: {
        number: false,
        year: false,
      },
      four: {
        number: false,
        year: false,
      },
      five: {
        number: false,
        year: false,
      },
    },
    two: {
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
    },
    three: {
      one: false,
      two: false,
      three: false,
      four: false,
    },
  },
  threePage: {
    four: false,
    five: {
      one: false,
      two: false,
    },
  },
  fourPage: {
    six: false,
    seven: {
      one: false,
      two: false,
      three: false,
    },
    eight: {
      one: false,
      two: false,
      three: false,
    },
  },
  fivePage: {
    one: {
      one: false,
      two: false,
      three: {
        number: false,
        text: "",
      },
      four: false,
    },
    two: {
      one: false,
      two: {
        one: false,
        two: false,
        three: false,
      },
    },
    three: {
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      six: false,
    },
  },
  sixPage: {
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: {
      number: false,
      text: false,
    },
    eight: false,
  },
  sevenPage: {
    one: [
      {
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
      },
      {
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
      },
      {
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
      },
      {
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
      },
      {
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
      },
    ],
  },
  eightPage: {
    two: false,
    twoA: {
      one: false,
      two: false,
    },
    twoB: {
      one: false,
      two: false,
    },
  },
  ninePage: {
    one: false,
    two: {
      one: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      two: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      three: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      four: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      five: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
    },
    three: {
      one: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      two: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      three: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      four: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      five: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
    },
  },
  teenPage: {
    two: false,
    twoOne: {
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
    },
    three: {
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
    },
    four: {
      one: {
        one: false,
        two: false,
      },
      two: {
        one: false,
        two: false,
      },
      three: {
        one: false,
        two: false,
      },
      four: {
        one: false,
        two: false,
      },
      five: {
        one: false,
        two: false,
      },
      six: {
        one: false,
        two: false,
      },
      seven: {
        one: false,
        two: false,
      },
    },
    five: false,
  },
  elevenPage: {
    one: false,
    two: {
      one: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      two: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      three: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      four: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      five: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
    },
    three: {
      one: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      two: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      three: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      four: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      five: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
    },
  },
  twelvePage: {
    two: false,
    twoOne: {
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
    },
    three: {
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
    },
    four: {
      one: {
        one: false,
        two: false,
      },
      two: {
        one: false,
        two: false,
      },
      three: {
        one: false,
        two: false,
      },
      four: {
        one: false,
        two: false,
      },
      five: {
        one: false,
        two: false,
      },
      six: {
        one: false,
        two: false,
      },
      seven: {
        one: false,
        two: false,
      },
    },
    five: false,
  },
  thirteenPage: {
    one: {
      one: {
        one: false,
        two: false,
        three: false,
      },
      two: {
        one: false,
        two: false,
        three: false,
        four: false,
      },
      three: {
        one: false,
        two: false,
      },
    },
    two: {
      one: {
        year: false,
        step: false,
      },
      two: {
        year: false,
        step: false,
      },
      three: {
        year: false,
        step: false,
      },
    },
  },
  fourteenPage: {
    one: {
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      six: false,
      seven: false,
    },
    oneOne: false,
    oneTwo: false,
    oneThree: false,
  },
  fifteenPage: {
    two: {
      one: false,
      two: false,
    },
    three: {
      one: {
        one: false,
        two: false,
        three: false,
      },
      two: {
        one: false,
        two: false,
        three: false,
      },
    },
    four: {
      one: false,
      two: false,
      three: false,
    },
    five: {
      one: false,
      two: false,
    },
  },
  sixteenPage: {
    one: false,
    two: {
      number: false,
      text: "",
    },
    three: {
      number: false,
      text: "",
    },
    four: [
      {
        number: false,
        text: "",
      },
      {
        number: false,
        text: "",
      },
      {
        number: false,
        text: "",
      },
      {
        number: false,
        text: "",
      },
      {
        number: false,
        text: "",
      },
      {
        number: false,
        text: "",
      },
      {
        number: false,
        text: "",
      },
      {
        number: false,
        text: "",
      },
      {
        number: false,
        text: "",
      },
    ],
    five: false,
    six: false,
  },
  seventeenPage: {
    sixOne: false,
    seven: [
      {
        number: false,
        text: "",
      },
      {
        number: false,
        text: "",
      },
      {
        number: false,
        text: "",
      },
      {
        number: false,
        text: "",
      },
      {
        number: false,
        text: "",
      },
    ],
    eight: false,
    nine: {
      one: {
        number: false,
        text: "",
      },
      two: {
        number: false,
        text: "",
      },
    },
    ten: {
      one: {
        number: false,
        text: "",
      },
      two: {
        number: false,
        text: "",
      },
      three: {
        number: false,
        text: "",
      },
    },
  },
  eighteenPage: {
    eleven: {
      elevenOne: false,
      elevenTwo: false,
      elevenThree: false,
      elevenFour: false,
      elevenFive: false,
      elevenSix: false,
      elevenSeven: false,
      elevenEight: false,
      elevenNine: {
        number: false,
        text: "",
      },
    },
    twelve: "",
  },
};
