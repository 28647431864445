import React from "react";

import { ThreePageTypes } from "gita";

interface ThreePageComponentProps {
  threePageData: ThreePageTypes;
  setThreePageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

const ThreePageComponent = ({
  threePageData,
  setThreePageData,
  setisUpdate,
}: ThreePageComponentProps) => {
  return (
    <div>
      <div className="hpa" style={{ width: "210mm", height: "297mm" }}>
        <div
          className="hpN"
          style={{
            left: "99.27mm",
            top: "282mm",
            width: "11.46mm",
            height: "3.53mm",
          }}
        >
          <span className="hrt cs0">- 3 -</span>
        </div>
        <div className="hcD" style={{ left: "30mm", top: "35mm" }}>
          <div className="hcI">
            <div
              className="hls ps0"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.19mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">
                4. 귀사가 융자사업을 신청한 과제는 다음의&nbsp;
              </span>
              <span className="hrt cs9">기술분야 6개 대분류</span>
              <span className="hrt cs1">&nbsp;중 어느 유형에 해</span>
            </div>
            <div
              className="hls ps0"
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "6.41mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">당됩니까?</span>
            </div>
            <div
              className="hls ps0"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "13.01mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">&nbsp;&nbsp;&nbsp;(</span>
              <span className="hrt cs62">
                ※ 기술분류체계가 상기 6대 분류로 조정되었으며, 세부내용은 상단
                "기술분류체계" 버튼 참고.)
              </span>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "19.62mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">
                &nbsp;
                <label>
                  <input
                    type="radio"
                    checked={threePageData.four === "1"}
                    onChange={(e) => {
                      setisUpdate(true);
                      setThreePageData({
                        ...threePageData,
                        four: "1",
                      });
                    }}
                    name="question_4"
                  />
                  &nbsp; 미래통신‧전파
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type="radio"
                    checked={threePageData.four === "2"}
                    onChange={(e) => {
                      setisUpdate(true);
                      setThreePageData({
                        ...threePageData,
                        four: "2",
                      });
                    }}
                    name="question_4"
                  />
                  &nbsp; SW‧AI
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type="radio"
                    checked={threePageData.four === "3"}
                    onChange={(e) => {
                      setisUpdate(true);
                      setThreePageData({
                        ...threePageData,
                        four: "3",
                      });
                    }}
                    name="question_4"
                  />
                  &nbsp; 방송․콘텐츠
                </label>
              </span>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "26.22mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">
                &nbsp;
                <label>
                  <input
                    type="radio"
                    checked={threePageData.four === "4"}
                    onChange={(e) => {
                      setisUpdate(true);
                      setThreePageData({
                        ...threePageData,
                        four: "4",
                      });
                    }}
                    name="question_4"
                  />{" "}
                  &nbsp;차세대보안
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type="radio"
                    checked={threePageData.four === "5"}
                    onChange={(e) => {
                      setisUpdate(true);
                      setThreePageData({
                        ...threePageData,
                        four: "5",
                      });
                    }}
                    name="question_4"
                  />
                  &nbsp; 디바이스
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type="radio"
                    checked={threePageData.four === "6"}
                    onChange={(e) => {
                      setisUpdate(true);
                      setThreePageData({
                        ...threePageData,
                        four: "6",
                      });
                    }}
                    name="question_4"
                  />
                  &nbsp; 블록체인‧융합
                </label>
              </span>
            </div>
            <div
              className="hls ps0"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "32.83mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className="hls ps0"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "39.43mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs10">5</span>
              <span className="hrt cs10">. 귀사가 융자사업을 통해 개발</span>
              <span className="hrt cs10">했거나 개발 중인</span>
              <span className="hrt cs10">&nbsp;</span>
              <span className="hrt cs10">기술의&nbsp;</span>
              <span className="hrt cs9">신청시점 및 현</span>
              <span className="hrt cs9">재</span>
              <span className="hrt cs9">시점의</span>
              <span className="hrt cs9">&nbsp;기</span>
            </div>
            <div
              className="hls ps0"
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "46.03mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs9">술성숙도</span>
              <span className="hrt cs10">
                (TRL; Technology Readiness Level)는 어느 단계라고 생각하십니까?
              </span>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "50.75mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "52.83mm",
                height: "50.75mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs10">&nbsp;</span>
              <div
                className="htb"
                style={{
                  left: "1mm",
                  width: "148.01mm",
                  top: "1mm",
                  height: "50.75mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 153.01 55.75"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.01mm",
                    height: "55.75mm",
                  }}
                >
                  <defs>
                    <pattern
                      id="w_20"
                      width={10}
                      height={10}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect width={10} height={10} fill="rgb(217,217,217)" />
                    </pattern>
                  </defs>
                  <path
                    fill="url(#w_20)"
                    d="M0,0L94.60,0L94.60,4.88L0,4.88L0,0Z "
                  />
                  <path
                    fill="url(#w_20)"
                    d="M94.60,0L120.30,0L120.30,4.88L94.60,4.88L94.60,0Z "
                  />
                  <path
                    fill="url(#w_20)"
                    d="M120.30,0L146.01,0L146.01,4.88L120.30,4.88L120.30,0Z "
                  />
                  <path
                    d="M0,0 L0,48.76"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M94.60,0 L94.60,48.76"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M120.30,0 L120.30,48.76"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M146.01,0 L146.01,48.76"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,0 L146.07,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,4.88 L146.07,4.88"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,9.75 L146.07,9.75"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,14.63 L146.07,14.63"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,19.50 L146.07,19.50"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,24.38 L146.07,24.38"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,29.25 L146.07,29.25"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,34.13 L146.07,34.13"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,39 L146.07,39"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,43.88 L146.07,43.88"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,48.75 L146.07,48.75"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M146.01,0 L146.01,48.76"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M0,0 L0,48.76"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,48.75 L146.07,48.75"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,0 L146.07,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "94.6mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "90.99mm",
                        }}
                      >
                        <span className="hrt cs10">기술성숙도 단계</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "94.6mm",
                    top: "0mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                        }}
                      >
                        <span className="hrt cs10">신청시점</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "120.3mm",
                    top: "0mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                        }}
                      >
                        <span className="hrt cs10">현재시점</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "4.88mm",
                    width: "94.6mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "90.99mm",
                        }}
                      >
                        <span className="hrt cs10">
                          ① TRL 1 단계 (기초실험단계)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "94.6mm",
                    top: "4.88mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            checked={threePageData.five.one === "1"}
                            onChange={(e) => {
                              setisUpdate(true);
                              let two = threePageData.five.two;
                              if (
                                threePageData.five.two &&
                                Number(threePageData.five.two) < 1
                              ) {
                                two = false;
                              }

                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  one: "1",
                                  two,
                                },
                              });
                            }}
                            name="question_5_past"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "120.3mm",
                    top: "4.88mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            disabled={
                              Number(threePageData.five.one) > 1 ||
                              threePageData.five.one === false
                            }
                            checked={threePageData.five.two === "1"}
                            onChange={(e) => {
                              setisUpdate(true);
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  two: "1",
                                },
                              });
                            }}
                            name="question_5_present"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "9.75mm",
                    width: "94.6mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "90.99mm",
                        }}
                      >
                        <span className="hrt cs10">
                          ② TRL 2 단계 (개념정립 단계)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "94.6mm",
                    top: "9.75mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            checked={threePageData.five.one === "2"}
                            onChange={(e) => {
                              setisUpdate(true);
                              let two = threePageData.five.two;
                              if (
                                threePageData.five.two &&
                                Number(threePageData.five.two) < 2
                              ) {
                                two = false;
                              }
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  one: "2",
                                  two,
                                },
                              });
                            }}
                            name="question_5_past"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "120.3mm",
                    top: "9.75mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            disabled={
                              Number(threePageData.five.one) > 2 ||
                              threePageData.five.one === false
                            }
                            checked={threePageData.five.two === "2"}
                            onChange={(e) => {
                              setisUpdate(true);
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  two: "2",
                                },
                              });
                            }}
                            name="question_5_present"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "14.63mm",
                    width: "94.6mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "90.99mm",
                        }}
                      >
                        <span className="hrt cs10">
                          ③ TRL 3 단계 (기본성능검증 단계)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "94.6mm",
                    top: "14.63mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            checked={threePageData.five.one === "3"}
                            onChange={(e) => {
                              setisUpdate(true);
                              let two = threePageData.five.two;
                              if (
                                threePageData.five.two &&
                                Number(threePageData.five.two) < 3
                              ) {
                                two = false;
                              }
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  one: "3",
                                  two,
                                },
                              });
                            }}
                            name="question_5_past"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "120.3mm",
                    top: "14.63mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            disabled={
                              Number(threePageData.five.one) > 3 ||
                              threePageData.five.one === false
                            }
                            checked={threePageData.five.two === "3"}
                            onChange={(e) => {
                              setisUpdate(true);
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  two: "3",
                                },
                              });
                            }}
                            name="question_5_present"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "19.5mm",
                    width: "94.6mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "90.99mm",
                        }}
                      >
                        <span className="hrt cs10">
                          ④ TRL 4 단계 (부품/시스템 성능검증 단계
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "94.6mm",
                    top: "19.5mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            checked={threePageData.five.one === "4"}
                            onChange={(e) => {
                              setisUpdate(true);
                              let two = threePageData.five.two;
                              if (
                                threePageData.five.two &&
                                Number(threePageData.five.two) < 4
                              ) {
                                two = false;
                              }
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  one: "4",
                                  two,
                                },
                              });
                            }}
                            name="question_5_past"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "120.3mm",
                    top: "19.5mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            disabled={
                              Number(threePageData.five.one) > 4 ||
                              threePageData.five.one === false
                            }
                            checked={threePageData.five.two === "4"}
                            onChange={(e) => {
                              setisUpdate(true);
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  two: "4",
                                },
                              });
                            }}
                            name="question_5_present"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "24.38mm",
                    width: "94.6mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "90.99mm",
                        }}
                      >
                        <span className="hrt cs10">
                          ⑤ TRL 5 단계 (부품/시스템 시제품 성능검증 단계)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "94.6mm",
                    top: "24.38mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            checked={threePageData.five.one === "5"}
                            onChange={(e) => {
                              setisUpdate(true);
                              let two = threePageData.five.two;
                              if (
                                threePageData.five.two &&
                                Number(threePageData.five.two) < 5
                              ) {
                                two = false;
                              }
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  one: "5",
                                  two,
                                },
                              });
                            }}
                            name="question_5_past"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "120.3mm",
                    top: "24.38mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            disabled={
                              Number(threePageData.five.one) > 5 ||
                              threePageData.five.one === false
                            }
                            checked={threePageData.five.two === "5"}
                            onChange={(e) => {
                              setisUpdate(true);
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  two: "5",
                                },
                              });
                            }}
                            name="question_5_present"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "29.25mm",
                    width: "94.6mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "90.99mm",
                        }}
                      >
                        <span className="hrt cs10">
                          ⑥ TRL 6 단계 (시제품 성능평가 단계)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "94.6mm",
                    top: "29.25mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            checked={threePageData.five.one === "6"}
                            onChange={(e) => {
                              setisUpdate(true);
                              let two = threePageData.five.two;
                              if (
                                threePageData.five.two &&
                                Number(threePageData.five.two) < 6
                              ) {
                                two = false;
                              }
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  one: "6",
                                  two,
                                },
                              });
                            }}
                            name="question_5_past"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "120.3mm",
                    top: "29.25mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            disabled={
                              Number(threePageData.five.one) > 6 ||
                              threePageData.five.one === false
                            }
                            checked={threePageData.five.two === "6"}
                            onChange={(e) => {
                              setisUpdate(true);
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  two: "6",
                                },
                              });
                            }}
                            name="question_5_present"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "34.13mm",
                    width: "94.6mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "90.99mm",
                        }}
                      >
                        <span className="hrt cs10">
                          ⑦ TRL 7 단계 (시제품 신뢰성평가 단계)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "94.6mm",
                    top: "34.13mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            checked={threePageData.five.one === "7"}
                            onChange={(e) => {
                              setisUpdate(true);
                              let two = threePageData.five.two;
                              if (
                                threePageData.five.two &&
                                Number(threePageData.five.two) < 7
                              ) {
                                two = false;
                              }
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  one: "7",
                                  two,
                                },
                              });
                            }}
                            name="question_5_past"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "120.3mm",
                    top: "34.13mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            disabled={
                              Number(threePageData.five.one) > 7 ||
                              threePageData.five.one === false
                            }
                            checked={threePageData.five.two === "7"}
                            onChange={(e) => {
                              setisUpdate(true);
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  two: "7",
                                },
                              });
                            }}
                            name="question_5_present"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "39mm",
                    width: "94.6mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "90.99mm",
                        }}
                      >
                        <span className="hrt cs10">
                          ⑧ TRL 8 단계 (시제품 인증 단계)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "94.6mm",
                    top: "39mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            checked={threePageData.five.one === "8"}
                            onChange={(e) => {
                              setisUpdate(true);
                              let two = threePageData.five.two;
                              if (
                                threePageData.five.two &&
                                Number(threePageData.five.two) < 8
                              ) {
                                two = false;
                              }
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  one: "8",
                                  two,
                                },
                              });
                            }}
                            name="question_5_past"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "120.3mm",
                    top: "39mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            disabled={
                              Number(threePageData.five.one) > 8 ||
                              threePageData.five.one === false
                            }
                            checked={threePageData.five.two === "8"}
                            onChange={(e) => {
                              setisUpdate(true);
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  two: "8",
                                },
                              });
                            }}
                            name="question_5_present"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "43.88mm",
                    width: "94.6mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "90.99mm",
                        }}
                      >
                        <span className="hrt cs10">
                          ⑨ TRL 9 단계 (사업화 단계)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "94.6mm",
                    top: "43.88mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            checked={threePageData.five.one === "9"}
                            onChange={(e) => {
                              setisUpdate(true);
                              let two = threePageData.five.two;
                              if (
                                threePageData.five.two &&
                                Number(threePageData.five.two) < 9
                              ) {
                                two = false;
                              }
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  one: "9",
                                  two,
                                },
                              });
                            }}
                            name="question_5_past"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "120.3mm",
                    top: "43.88mm",
                    width: "25.71mm",
                    height: "4.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps20"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "22.1mm",
                          textAlign: "center",
                        }}
                      >
                        <span className="hrt cs10">
                          <input
                            type="radio"
                            disabled={
                              Number(threePageData.five.one) > 9 ||
                              threePageData.five.one === false
                            }
                            checked={threePageData.five.two === "9"}
                            onChange={(e) => {
                              setisUpdate(true);
                              setThreePageData({
                                ...threePageData,
                                five: {
                                  ...threePageData.five,
                                  two: "9",
                                },
                              });
                            }}
                            name="question_5_present"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hls ps0"
              style={{
                lineHeight: "116.21mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "106.31mm",
                height: "116.21mm",
                width: "150mm",
              }}
            >
              <div
                className="htb"
                style={{
                  left: "0.5mm",
                  width: "151.32mm",
                  top: "0.5mm",
                  height: "116.21mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 156.32 121.21"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "156.32mm",
                    height: "121.21mm",
                  }}
                >
                  <defs>
                    <pattern
                      id="w_21"
                      width={10}
                      height={10}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect width={10} height={10} fill="rgb(224,229,250)" />
                    </pattern>
                  </defs>
                  <path
                    fill="url(#w_21)"
                    d="M0,0L150.33,0L150.33,115.22L0,115.22L0,0Z "
                  />
                  <defs>
                    <pattern
                      id="w_22"
                      width={10}
                      height={10}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect width={10} height={10} fill="rgb(221,221,221)" />
                    </pattern>
                  </defs>
                  <path
                    fill="url(#w_22)"
                    d="M0,0L57.43,0L57.43,7.49L0,7.49L0,0Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M57.43,0L150.33,0L150.33,7.49L57.43,7.49L57.43,0Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M0,7.49L12.22,7.49L12.22,22.46L0,22.46L0,7.49Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M12.22,7.49L33.44,7.49L33.44,14.97L12.22,14.97L12.22,7.49Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M33.44,7.49L57.43,7.49L57.43,14.97L33.44,14.97L33.44,7.49Z "
                  />
                  <defs>
                    <pattern
                      id="w_23"
                      width={10}
                      height={10}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect width={10} height={10} fill="rgb(255,255,255)" />
                    </pattern>
                  </defs>
                  <path
                    fill="url(#w_23)"
                    d="M57.43,7.49L150.33,7.49L150.33,14.97L57.43,14.97L57.43,7.49Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M12.22,14.97L33.44,14.97L33.44,22.46L12.22,22.46L12.22,14.97Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M33.44,14.97L57.43,14.97L57.43,22.46L33.44,22.46L33.44,14.97Z "
                  />
                  <path
                    fill="url(#w_23)"
                    d="M57.43,14.97L150.33,14.97L150.33,22.46L57.43,22.46L57.43,14.97Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M0,22.46L12.22,22.46L12.22,44.77L0,44.77L0,22.46Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M12.22,22.46L33.44,22.46L33.44,33.61L12.22,33.61L12.22,22.46Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M33.44,22.46L57.43,22.46L57.43,33.61L33.44,33.61L33.44,22.46Z "
                  />
                  <path
                    fill="url(#w_23)"
                    d="M57.43,22.46L150.33,22.46L150.33,33.61L57.43,33.61L57.43,22.46Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M12.22,33.61L33.44,33.61L33.44,44.77L12.22,44.77L12.22,33.61Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M33.44,33.61L57.43,33.61L57.43,44.77L33.44,44.77L33.44,33.61Z "
                  />
                  <path
                    fill="url(#w_23)"
                    d="M57.43,33.61L150.33,33.61L150.33,44.77L57.43,44.77L57.43,33.61Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M0,44.77L12.22,44.77L12.22,85.42L0,85.42L0,44.77Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M12.22,44.77L33.44,44.77L33.44,66.93L12.22,66.93L12.22,44.77Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M33.44,44.77L57.43,44.77L57.43,66.93L33.44,66.93L33.44,44.77Z "
                  />
                  <path
                    fill="url(#w_23)"
                    d="M57.43,44.77L150.33,44.77L150.33,66.93L57.43,66.93L57.43,44.77Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M12.22,66.93L33.44,66.93L33.44,85.42L12.22,85.42L12.22,66.93Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M33.44,66.93L57.43,66.93L57.43,85.42L33.44,85.42L33.44,66.93Z "
                  />
                  <path
                    fill="url(#w_23)"
                    d="M57.43,66.93L150.33,66.93L150.33,85.42L57.43,85.42L57.43,66.93Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M0,85.42L12.22,85.42L12.22,107.73L0,107.73L0,85.42Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M12.22,85.42L33.44,85.42L33.44,100.25L12.22,100.25L12.22,85.42Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M33.44,85.42L57.43,85.42L57.43,100.25L33.44,100.25L33.44,85.42Z "
                  />
                  <path
                    fill="url(#w_23)"
                    d="M57.43,85.42L150.33,85.42L150.33,100.25L57.43,100.25L57.43,85.42Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M12.22,100.25L33.44,100.25L33.44,107.73L12.22,107.73L12.22,100.25Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M33.44,100.25L57.43,100.25L57.43,107.73L33.44,107.73L33.44,100.25Z "
                  />
                  <path
                    fill="url(#w_23)"
                    d="M57.43,100.25L150.33,100.25L150.33,107.73L57.43,107.73L57.43,100.25Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M0,107.73L12.22,107.73L12.22,115.22L0,115.22L0,107.73Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M12.22,107.73L33.44,107.73L33.44,115.22L12.22,115.22L12.22,107.73Z "
                  />
                  <path
                    fill="url(#w_22)"
                    d="M33.44,107.73L57.43,107.73L57.43,115.22L33.44,115.22L33.44,107.73Z "
                  />
                  <path
                    fill="url(#w_23)"
                    d="M57.43,107.73L150.33,107.73L150.33,115.22L57.43,115.22L57.43,107.73Z "
                  />
                  <path
                    d="M0,0 L0,115.22"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M12.22,7.49 L12.22,115.22"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M33.44,7.49 L33.44,115.22"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M57.43,0 L57.43,115.22"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M150.33,0 L150.33,115.22"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,0 L150.39,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,7.49 L150.39,7.49"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M12.17,14.97 L150.39,14.97"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,22.46 L150.39,22.46"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M12.17,33.61 L150.39,33.61"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,44.77 L150.39,44.77"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M12.17,66.93 L150.39,66.93"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,85.42 L150.39,85.42"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M12.17,100.25 L150.39,100.25"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,107.73 L150.39,107.73"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,115.22 L150.39,115.22"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M150.33,0 L150.33,115.22"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M0,0 L0,115.22"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,115.22 L150.39,115.22"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,0 L150.39,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "57.43mm",
                    height: "7.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.83mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "56.43mm",
                        }}
                      >
                        <span className="hrt cs17">TRL 단계</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "57.43mm",
                    top: "0mm",
                    width: "92.9mm",
                    height: "7.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.83mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">단계별 상세정의</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "7.49mm",
                    width: "12.22mm",
                    height: "14.97mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "3.74mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "11.22mm",
                        }}
                      >
                        <span className="hrt cs17">기초&nbsp;</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "11.22mm",
                        }}
                      >
                        <span className="hrt cs17">연구</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "12.22mm",
                    top: "7.49mm",
                    width: "21.21mm",
                    height: "7.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">TRL1 단계</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">(기초실험)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "33.44mm",
                    top: "7.49mm",
                    width: "23.99mm",
                    height: "7.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.83mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">기본원리발견</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "57.43mm",
                    top: "7.49mm",
                    width: "92.9mm",
                    height: "7.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.83mm" }}>
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">∙ 기초이론 정립 단계</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "12.22mm",
                    top: "14.97mm",
                    width: "21.21mm",
                    height: "7.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">TRL2 단계</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">(개념정립)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "33.44mm",
                    top: "14.97mm",
                    width: "23.99mm",
                    height: "7.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">기술개념과&nbsp;</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">적용분야의 확립</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "57.43mm",
                    top: "14.97mm",
                    width: "92.9mm",
                    height: "7.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.83mm" }}>
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 기술개발 개념 정립 및 아이디어에 대한 특허 출원
                          단계&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "22.46mm",
                    width: "12.22mm",
                    height: "22.31mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "9.25mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "11.22mm",
                        }}
                      >
                        <span className="hrt cs17">실험</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "12.22mm",
                    top: "22.46mm",
                    width: "21.21mm",
                    height: "11.15mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.83mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">TRL3 단계</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">(기본성능검증)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "33.44mm",
                    top: "22.46mm",
                    width: "23.99mm",
                    height: "11.15mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">분석과 실험을&nbsp;</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">통한 기술개념&nbsp;</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "7.2mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">검증</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "57.43mm",
                    top: "22.46mm",
                    width: "92.9mm",
                    height: "11.15mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 실험실 환경에서 실험 또는 전산 시뮬레이션을 통해
                          기본 성능이 검증될&nbsp;
                        </span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          paddingLeft: "3.2mm",
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">수 있는 단계</span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "7.2mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 개발하려는 부품 또는 시스템의 기본 설계도면을
                          확보하는 단계 등
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "12.22mm",
                    top: "33.61mm",
                    width: "21.21mm",
                    height: "11.15mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">TRL4 단계</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">(부품/시스템&nbsp;</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "7.2mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">성능검증)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "33.44mm",
                    top: "33.61mm",
                    width: "23.99mm",
                    height: "11.15mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">
                          연구실 환경에서의&nbsp;
                        </span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">Working Model&nbsp;</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "7.2mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">개발</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "57.43mm",
                    top: "33.61mm",
                    width: "92.9mm",
                    height: "11.15mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 시험샘플을 제작하여 핵심성능에 대한 평가가 완료된
                          단계
                        </span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 3단계에서 도출된 다양한 결과 중에서 최적의 결과를
                          선택하는 단계
                        </span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "7.2mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 컴퓨터 모사가 가능한 경우 최적화를 완료하는 단계
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "44.77mm",
                    width: "12.22mm",
                    height: "40.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "18.42mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "11.22mm",
                        }}
                      >
                        <span className="hrt cs17">시작품</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "12.22mm",
                    top: "44.77mm",
                    width: "21.21mm",
                    height: "22.16mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "3.67mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">TRL5 단계</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">(부품/시스템&nbsp;</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "7.2mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">시제품&nbsp;</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "10.87mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">성능검증)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "33.44mm",
                    top: "44.77mm",
                    width: "23.99mm",
                    height: "22.16mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "5.5mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">유사 환경에서의&nbsp;</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">Working Model&nbsp;</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "7.2mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">검증</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "57.43mm",
                    top: "44.77mm",
                    width: "92.9mm",
                    height: "22.16mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 확정된 소재/부품/시스템의 실험실 시제품 제작 및 성능
                          평가가 완료된&nbsp;
                        </span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          paddingLeft: "3.2mm",
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">단계</span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "7.2mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 개발 대상의 생산을 고려하여 설계하나 실제 제작한
                          시제품 샘플은 1~수
                        </span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          paddingLeft: "3.2mm",
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "10.87mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">개 미만인 단계</span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "14.53mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 경제성을 고려하지 않고 기술의 핵심성능으로만 볼 때,
                          실제로 판매가&nbsp;
                        </span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          paddingLeft: "3.2mm",
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "18.2mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          될 수 있는 정도로 목표 성능을 달성한 단계
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "12.22mm",
                    top: "66.93mm",
                    width: "21.21mm",
                    height: "18.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "3.67mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">TRL6 단계</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">(시제품&nbsp;</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "7.2mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">성능평가)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "33.44mm",
                    top: "66.93mm",
                    width: "23.99mm",
                    height: "18.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "5.5mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">유사 환경에서의&nbsp;</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">프로토타입 개발</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "57.43mm",
                    top: "66.93mm",
                    width: "92.9mm",
                    height: "18.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 파일롯 규모(복수 개~양산규모의 1/10 정도)의 시제품
                          제작 및 평가가&nbsp;
                        </span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          paddingLeft: "3.2mm",
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">완료된 단계</span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "7.2mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 생산기업이 수요기업 적용환경에 유사하게 자체
                          현장테스트를 실시하여&nbsp;
                        </span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          paddingLeft: "3.2mm",
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "10.87mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          목표성능을 만족시킨 단계
                        </span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "14.53mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 성능평가 결과에 대해 가능하면 공인인증 기관의 성적서
                          확보
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "85.42mm",
                    width: "12.22mm",
                    height: "22.31mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "9.25mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "11.22mm",
                        }}
                      >
                        <span className="hrt cs17">실용화</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "12.22mm",
                    top: "85.42mm",
                    width: "21.21mm",
                    height: "14.82mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.83mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">TRL7 단계</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">(시제품 신뢰성&nbsp;</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "7.2mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">평가)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "33.44mm",
                    top: "85.42mm",
                    width: "23.99mm",
                    height: "14.82mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "3.67mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">
                          &nbsp;실제 환경에서&nbsp;
                        </span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">&nbsp;시제품 데모</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "57.43mm",
                    top: "85.42mm",
                    width: "92.9mm",
                    height: "14.82mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 실제 환경에서 성능 검증이 이루어지는 단계
                        </span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 부품 및 소재개발의 경우 수요업체에서 직접 파일롯
                          시제품을 현장 평가&nbsp;
                        </span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          paddingLeft: "3.2mm",
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "7.2mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          (성능뿐만 아니라 신뢰성에 대해서도 평가)
                        </span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "10.87mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 가능하면 KOLAS 인증기관 등의 신뢰성 평가 결과 제출
                          등
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "12.22mm",
                    top: "100.25mm",
                    width: "21.21mm",
                    height: "7.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">TRL8 단계</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">(시제품 인증)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "33.44mm",
                    top: "100.25mm",
                    width: "23.99mm",
                    height: "7.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">
                          상용제품 시험평가&nbsp;
                        </span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">및 신뢰성 검증</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "57.43mm",
                    top: "100.25mm",
                    width: "92.9mm",
                    height: "7.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.83mm" }}>
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 표준화 및 인허가 취득 단계
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "107.73mm",
                    width: "12.22mm",
                    height: "7.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "11.22mm",
                        }}
                      >
                        <span className="hrt cs17">사업화</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "11.22mm",
                        }}
                      >
                        <span className="hrt cs17">(양산)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "12.22mm",
                    top: "107.73mm",
                    width: "21.21mm",
                    height: "7.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">TRL9 단계</span>
                      </div>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "20.21mm",
                        }}
                      >
                        <span className="hrt cs17">(사업화)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "33.44mm",
                    top: "107.73mm",
                    width: "23.99mm",
                    height: "7.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.83mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "22.99mm",
                        }}
                      >
                        <span className="hrt cs17">상용제품생산</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "57.43mm",
                    top: "107.73mm",
                    width: "92.9mm",
                    height: "7.49mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 본격적인 양산 및 사업화 단계
                        </span>
                      </div>
                      <div
                        className="hls ps10"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.53mm",
                          height: "2.82mm",
                          width: "91.89mm",
                        }}
                      >
                        <span className="hrt cs17">
                          ∙ 6-시그마 등 품질관리가 중요한 단계 등
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hls ps52"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "225.05mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreePageComponent;
