import { Input } from "antd";
import React from "react";
import { changeNumber } from "src/ts/operator";

import { TeenPageTypes } from "gita";
import _ from "lodash";

interface TeenPageComponentProps {
  teenPageData: TeenPageTypes;
  setTeenPageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  targetYear: number;
}

const TeenPageComponent = ({
  teenPageData,
  setTeenPageData,
  setisUpdate,
  targetYear,
}: TeenPageComponentProps) => {
  return (
    <div>
      <div className="hpa" style={{ width: "210mm", height: "347mm" }}>
        <div
          className="hpN"
          style={{
            left: "98.24mm",
            top: "332mm",
            width: "13.52mm",
            height: "35mm",
          }}
        >
          <span className="hrt cs0">- 10 -</span>
        </div>
        <div className="hcD" style={{ left: "30mm", top: "35mm" }}>
          <div className="hcI">
            <div
              className="hls ps0"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.19mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">
                2. 본 융자사업과제를 통해 개발된 기술을 사업화하여 발생한 매출액
                이외에&nbsp;
              </span>
            </div>
            <div
              className="hls ps0"
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "6.41mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs9">
                귀사가 개발한 기술로 인해 비용절감효과
              </span>
              <span className="hrt cs1">가 있었습니까?</span>
            </div>
            <div
              className="hls ps68"
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "13.02mm",
                height: "3.7mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs86">
                <label htmlFor="page10_yes">
                  <input
                    type="radio"
                    onChange={() => {
                      setisUpdate(true);
                      setTeenPageData({
                        ...teenPageData,
                        two: "1",
                      });
                    }}
                    checked={teenPageData.two === "1"}
                    name="page10_yesorno"
                    id="page10_yes"
                  />
                  &nbsp; 예
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span
                className="htC"
                style={{ left: "0.92mm", width: "11.81mm", height: "100%" }}
              />
              <span className="hrt cs86">
                <label htmlFor="page10_no">
                  <input
                    type="radio"
                    onChange={() => {
                      setisUpdate(true);
                      setTeenPageData({
                        ...teenPageData,
                        two: "2",
                      });
                    }}
                    checked={teenPageData.two === "2"}
                    name="page10_yesorno"
                    id="page10_no"
                  />
                  &nbsp; 아니오
                </label>
              </span>
            </div>
            <div
              className="hls ps68"
              style={{
                lineHeight: "1.59mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "17.92mm",
                height: "2.12mm",
                width: "150mm",
              }}
            />
            <div
              className="hls ps5"
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "20.59mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className="hrt cs8">
                2-1. 위 항목에서 ‘예’라고 응답한 경우,&nbsp;
              </span>
              <span className="hrt cs12">비용절감액을 기입</span>
              <span className="hrt cs8">해 주시기 바랍니다.</span>
            </div>
            <div
              className="hls ps0"
              style={{
                lineHeight: "18.51mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "27.07mm",
                height: "18.51mm",
                width: "150mm",
              }}
            >
              <div
                className="htb"
                style={{
                  left: "1mm",
                  width: "150.01mm",
                  top: "1mm",
                  height: "18.51mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 155.01 23.51"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "155.01mm",
                    height: "23.51mm",
                  }}
                >
                  <path
                    d="M0,0 L0,16.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M148.01,0 L148.01,16.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,0 L148.07,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,16.52 L148.07,16.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M148.01,0 L148.01,16.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M0,0 L0,16.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,16.52 L148.07,16.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,0 L148.07,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.01mm",
                    height: "16.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps0"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.18mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className="hrt cs209">
                          ※예: 2018년 선정기업은 2019년부터 기입, 2020년
                          선정기업은 2021년부터 기입
                        </span>
                      </div>
                      <div
                        className="hls ps0"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "8.82mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className="hrt cs209">
                          ※비용절감액이 없는 경우에는 0을 기입
                        </span>
                      </div>
                      {/* <div
                        className="hls ps0"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "11.82mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className="hrt cs209">
                          ※2023년 선정기업은 2023년만 기입
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hls ps36"
              style={{
                lineHeight: "47.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "48.3mm",
                height: "47.1mm",
                width: "150mm",
              }}
            >
              <div
                className="htb"
                style={{
                  left: "1mm",
                  width: "150.52mm",
                  top: "1mm",
                  height: "47.1mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 155.52 52.10"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "155.52mm",
                    height: "52.1mm",
                  }}
                >
                  <defs>
                    <pattern
                      id="w_90"
                      width={10}
                      height={10}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect width={10} height={10} fill="rgb(229,229,229)" />
                    </pattern>
                  </defs>
                  <path
                    fill="url(#w_90)"
                    d="M0,0L55.92,0L55.92,7.52L0,7.52L0,0Z "
                  />
                  <defs>
                    <pattern
                      id="w_91"
                      width={10}
                      height={10}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect width={10} height={10} fill="rgb(229,229,229)" />
                    </pattern>
                  </defs>
                  <path
                    fill="url(#w_91)"
                    d="M55.92,0L148.53,0L148.53,7.52L55.92,7.52L55.92,0Z "
                  />
                  <path
                    d="M0,0 L0,45.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M55.92,0 L55.92,15.05"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.92,7.52 L55.92,15.05"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M55.92,15.05 L55.92,22.58"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.92,15.05 L55.92,22.58"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M55.92,22.57 L55.92,30.10"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.92,22.57 L55.92,30.10"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M55.92,30.10 L55.92,37.63"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.92,30.10 L55.92,37.63"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M55.92,37.62 L55.92,45.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.92,37.62 L55.92,45.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M148.53,0 L148.53,45.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L148.78,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,7.52 L148.78,7.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.84,7.52 L148.78,7.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,15.05 L55.98,15.05"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.84,15.05 L148.78,15.05"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,22.57 L55.98,22.57"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.84,22.57 L148.78,22.57"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,30.10 L55.98,30.10"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.84,30.10 L148.78,30.10"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,37.62 L55.98,37.62"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.84,37.62 L148.78,37.62"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,45.11 L148.78,45.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M148.53,0 L148.53,45.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M0,0 L0,45.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,45.11 L148.78,45.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L148.78,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "55.92mm",
                    height: "7.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.5mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className="hrt cs129">연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.92mm",
                    top: "0mm",
                    width: "92.61mm",
                    height: "7.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.5mm" }}>
                      <div
                        className="hls ps22"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      >
                        <span className="hrt cs129">
                          &nbsp;비용절감액(백만원)
                        </span>
                        <span className="hrt cs130">1)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "7.52mm",
                    width: "55.92mm",
                    height: "7.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.5mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className="hrt cs131">2019년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.92mm",
                    top: "7.52mm",
                    width: "92.61mm",
                    height: "7.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      >
                        <Input
                          disabled={
                            teenPageData.two !== "1" || targetYear >= 2019
                          }
                          type="number"
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setTeenPageData({
                              ...teenPageData,
                              twoOne: {
                                ...teenPageData.twoOne,
                                one:
                                  e.target.value === ""
                                    ? false
                                    : e.target.value,
                              },
                            });
                          }}
                          value={
                            typeof teenPageData.twoOne.one === "string"
                              ? teenPageData.twoOne.one
                              : ""
                          }
                          className="teen-number-input"
                          style={{ width: "40%", height: "80%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "15.05mm",
                    width: "55.92mm",
                    height: "7.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.5mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className="hrt cs131">2020년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.92mm",
                    top: "15.05mm",
                    width: "92.61mm",
                    height: "7.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      >
                        <Input
                          disabled={
                            teenPageData.two !== "1" || targetYear >= 2020
                          }
                          type="number"
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setTeenPageData({
                              ...teenPageData,
                              twoOne: {
                                ...teenPageData.twoOne,
                                two:
                                  e.target.value === ""
                                    ? false
                                    : e.target.value,
                              },
                            });
                          }}
                          value={
                            typeof teenPageData.twoOne.two === "string"
                              ? teenPageData.twoOne.two
                              : ""
                          }
                          className="teen-number-input"
                          style={{ width: "40%", height: "80%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "22.57mm",
                    width: "55.92mm",
                    height: "7.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.5mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className="hrt cs131">2021년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.92mm",
                    top: "22.57mm",
                    width: "92.61mm",
                    height: "7.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      >
                        <Input
                          disabled={
                            teenPageData.two !== "1" || targetYear >= 2021
                          }
                          type="number"
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setTeenPageData({
                              ...teenPageData,
                              twoOne: {
                                ...teenPageData.twoOne,
                                three:
                                  e.target.value === ""
                                    ? false
                                    : e.target.value,
                              },
                            });
                          }}
                          value={
                            typeof teenPageData.twoOne.three === "string"
                              ? teenPageData.twoOne.three
                              : ""
                          }
                          className="teen-number-input"
                          style={{ width: "40%", height: "80%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "30.1mm",
                    width: "55.92mm",
                    height: "7.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.5mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className="hrt cs131">2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.92mm",
                    top: "30.1mm",
                    width: "92.61mm",
                    height: "7.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      >
                        <Input
                          disabled={
                            teenPageData.two !== "1" || targetYear >= 2022
                          }
                          type="number"
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setTeenPageData({
                              ...teenPageData,
                              twoOne: {
                                ...teenPageData.twoOne,
                                four:
                                  e.target.value === ""
                                    ? false
                                    : e.target.value,
                              },
                            });
                          }}
                          value={
                            typeof teenPageData.twoOne.four === "string"
                              ? teenPageData.twoOne.four
                              : ""
                          }
                          className="teen-number-input"
                          style={{ width: "40%", height: "80%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "37.62mm",
                    width: "55.92mm",
                    height: "7.48mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.48mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className="hrt cs131">2023년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.92mm",
                    top: "37.62mm",
                    width: "92.61mm",
                    height: "7.48mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      >
                        <Input
                          disabled={teenPageData.two !== "1"}
                          type="number"
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setTeenPageData({
                              ...teenPageData,
                              twoOne: {
                                ...teenPageData.twoOne,
                                five:
                                  e.target.value === ""
                                    ? false
                                    : e.target.value,
                              },
                            });
                          }}
                          value={
                            typeof teenPageData.twoOne.five === "string"
                              ? teenPageData.twoOne.five
                              : ""
                          }
                          className="teen-number-input"
                          style={{ width: "40%", height: "80%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hls ps28"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "5.58mm",
                top: "97.98mm",
                height: "3mm",
                width: "144.42mm",
              }}
            >
              <span className="hrt cs13">
                ※ 비용절감액 : 개발된 기술을 이용하여 제조 공정에 필요한 부품,
                원재료의 감소 및 생산공정의 효율성 개선을 유도함으로써&nbsp;
              </span>
            </div>
            <div
              className="hls ps28"
              style={{
                paddingLeft: "3.22mm",
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "5.58mm",
                top: "101.88mm",
                height: "3mm",
                width: "144.42mm",
              }}
            >
              <span className="hrt cs13">
                직접적으로 유발된 비용절감액(추정) 또는 타사(국내 및 외국)로부터
                비싸게 구입 및 수입해 오던 기술이나 제품을 본&nbsp;
              </span>
            </div>
            <div
              className="hls ps28"
              style={{
                paddingLeft: "3.22mm",
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "5.58mm",
                top: "105.78mm",
                height: "3mm",
                width: "144.42mm",
              }}
            >
              <span className="hrt cs13">
                기술과제를 통해 개발된 기술로 대체함으로써 간접적으로 유발된
                비용절감액(추정)&nbsp;
              </span>
            </div>
            <div
              className="hls ps8"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "109.68mm",
                height: "3mm",
                width: "150mm",
              }}
            />
            <div
              className="hls ps0"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "113.54mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">
                3. 본 융자사업과제를 수행하거나 사업화하는 과정에서 2023년&nbsp;
              </span>
              <span className="hrt cs9">신규 고용한 인력</span>
              <span className="hrt cs1">을 연</span>
            </div>
            <div
              className="hls ps0"
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "120.15mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">
                구개발(R&amp;D)과 연구개발 외(비R&amp;D)로 나누어 기입해
                주십시오.
              </span>
            </div>
            <div
              className="hls ps36"
              style={{
                lineHeight: "25.85mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "126.94mm",
                height: "25.85mm",
                width: "150mm",
              }}
            >
              <div
                className="htb"
                style={{
                  left: "0.5mm",
                  width: "148.76mm",
                  top: "1mm",
                  height: "25.85mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 153.76 30.85"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.76mm",
                    height: "30.85mm",
                  }}
                >
                  <path
                    fill="url(#w_90)"
                    d="M0,0L23.10,0L23.10,13.95L0,13.95L0,0Z "
                  />
                  <path
                    fill="url(#w_90)"
                    d="M23.10,0L48.91,0L48.91,13.95L23.10,13.95L23.10,0Z "
                  />
                  <path
                    fill="url(#w_90)"
                    d="M48.91,0L147.76,0L147.76,4.52L48.91,4.52L48.91,0Z "
                  />
                  <path
                    fill="url(#w_90)"
                    d="M48.91,4.52L98.34,4.52L98.34,9.05L48.91,9.05L48.91,4.52Z "
                  />
                  <path
                    fill="url(#w_91)"
                    d="M98.34,4.52L147.76,4.52L147.76,9.05L98.34,9.05L98.34,4.52Z "
                  />
                  <path
                    fill="url(#w_90)"
                    d="M48.91,9.05L73.62,9.05L73.62,13.95L48.91,13.95L48.91,9.05Z "
                  />
                  <path
                    fill="url(#w_90)"
                    d="M73.62,9.05L98.34,9.05L98.34,13.95L73.62,13.95L73.62,9.05Z "
                  />
                  <path
                    fill="url(#w_91)"
                    d="M98.34,9.05L123.05,9.05L123.05,13.95L98.34,13.95L98.34,9.05Z "
                  />
                  <path
                    fill="url(#w_91)"
                    d="M123.05,9.05L147.76,9.05L147.76,13.95L123.05,13.95L123.05,9.05Z "
                  />
                  <path
                    d="M0,0 L0,23.85"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M23.10,0 L23.10,23.85"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M23.10,13.95 L23.10,23.85"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M48.91,4.52 L48.91,23.85"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M73.62,9.05 L73.62,13.95"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M73.62,13.95 L73.62,23.85"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M98.34,4.52 L98.34,13.95"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M98.34,13.95 L98.34,23.85"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M123.05,9.05 L123.05,23.85"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M147.76,0 L147.76,23.85"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L148.01,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M48.86,4.52 L148.01,4.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M48.84,9.05 L148.01,9.05"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.25,13.95 L48.98,13.95"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M23.03,13.95 L48.99,13.95"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M48.84,13.95 L73.69,13.95"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M48.84,13.95 L73.70,13.95"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M73.57,13.95 L98.40,13.95"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M73.55,13.95 L98.41,13.95"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M98.28,13.95 L123.13,13.95"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M98.26,13.95 L148.01,13.95"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,23.85 L148.01,23.85"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M147.76,0 L147.76,23.85"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M0,0 L0,23.85"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,23.85 L148.01,23.85"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L148.01,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "23.1mm",
                    height: "13.95mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "4.71mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.1mm",
                        }}
                      >
                        <span className="hrt cs129">구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "23.1mm",
                    top: "0mm",
                    width: "25.81mm",
                    height: "13.95mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.18mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.81mm",
                        }}
                      >
                        <span className="hrt cs129">신규고용인력&nbsp;</span>
                      </div>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.35mm",
                          height: "3.53mm",
                          width: "24.81mm",
                        }}
                      >
                        <span className="hrt cs129">합계</span>
                      </div>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "6.88mm",
                          height: "3.53mm",
                          width: "24.81mm",
                        }}
                      >
                        <span className="hrt cs129">(A=B+C)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "48.91mm",
                    top: "0mm",
                    width: "98.85mm",
                    height: "4.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "97.85mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "48.91mm",
                    top: "4.52mm",
                    width: "49.42mm",
                    height: "4.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "48.43mm",
                        }}
                      >
                        <span className="hrt cs129">
                          연구개발(R&amp;D)인력(B)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "98.34mm",
                    top: "4.52mm",
                    width: "49.42mm",
                    height: "4.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "48.43mm",
                        }}
                      >
                        <span className="hrt cs40">
                          연구개발 외(비R&amp;D)인력
                        </span>
                        <span className="hrt cs129">(C)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "48.91mm",
                    top: "9.05mm",
                    width: "24.71mm",
                    height: "4.9mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.19mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs129">정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "73.62mm",
                    top: "9.05mm",
                    width: "24.71mm",
                    height: "4.9mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.19mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs129">비정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "98.34mm",
                    top: "9.05mm",
                    width: "24.71mm",
                    height: "4.9mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.19mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs40">정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "123.05mm",
                    top: "9.05mm",
                    width: "24.71mm",
                    height: "4.9mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.19mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs40">비정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "13.95mm",
                    width: "23.1mm",
                    height: "9.91mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.69mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.1mm",
                        }}
                      >
                        <span className="hrt cs131">2023</span>
                        <span className="hrt cs131">년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "23.1mm",
                    top: "13.95mm",
                    width: "25.81mm",
                    height: "9.91mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.69mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.81mm",
                        }}
                      >
                        <span className="hrt cs133">
                          <Input
                            value={
                              typeof teenPageData.three.one === "number"
                                ? teenPageData.three.one
                                : ""
                            }
                            disabled={true}
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "48.91mm",
                    top: "13.95mm",
                    width: "24.71mm",
                    height: "9.91mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.52mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs174">
                          <Input
                            onChange={(e) => {
                              const data = Number(
                                changeNumber(e.target.value) === ""
                                  ? false
                                  : changeNumber(e.target.value)
                              );

                              const { three, four, five } = teenPageData.three;
                              const one =
                                data +
                                Number(three) +
                                Number(four) +
                                Number(five);
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                three: {
                                  ...teenPageData.three,
                                  two:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                  one,
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.three.two === "number"
                                ? teenPageData.three.two
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "73.62mm",
                    top: "13.95mm",
                    width: "24.71mm",
                    height: "9.91mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.52mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs174">
                          <Input
                            onChange={(e) => {
                              const data = Number(
                                changeNumber(e.target.value) === ""
                                  ? false
                                  : changeNumber(e.target.value)
                              );

                              const { two, four, five } = teenPageData.three;
                              const one =
                                data +
                                Number(two) +
                                Number(four) +
                                Number(five);
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                three: {
                                  ...teenPageData.three,
                                  three:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                  one,
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.three.three === "number"
                                ? teenPageData.three.three
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "98.34mm",
                    top: "13.95mm",
                    width: "24.71mm",
                    height: "9.91mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.52mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs174">
                          <Input
                            onChange={(e) => {
                              const data = Number(
                                changeNumber(e.target.value) === ""
                                  ? false
                                  : changeNumber(e.target.value)
                              );

                              const { two, three, five } = teenPageData.three;
                              const one =
                                data +
                                Number(two) +
                                Number(three) +
                                Number(five);
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                three: {
                                  ...teenPageData.three,
                                  four:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                  one,
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.three.four === "number"
                                ? teenPageData.three.four
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "123.05mm",
                    top: "13.95mm",
                    width: "24.71mm",
                    height: "9.91mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.52mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs174">
                          <Input
                            onChange={(e) => {
                              const data = Number(
                                changeNumber(e.target.value) === ""
                                  ? false
                                  : changeNumber(e.target.value)
                              );

                              const { two, three, four } = teenPageData.three;
                              const one =
                                data +
                                Number(two) +
                                Number(three) +
                                Number(four);
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                three: {
                                  ...teenPageData.three,
                                  five:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                  one,
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.three.five === "number"
                                ? teenPageData.three.five
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hls ps86"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "155.36mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs5">
                ※ 본 설문응답 인력은 ‘4대 보험 적용자’를 대상으로 함.
              </span>
              <span className="hrt cs13">&nbsp;</span>
            </div>
            <div
              className="hls ps8"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "160.15mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs13">※ 년</span>
              <span className="hrt cs13">&nbsp;</span>
              <span className="hrt cs13">
                중 비정규직 근로자가 정규직으로 전환되었을 경우, 정규직으로 기입
              </span>
            </div>
            <div
              className="hls ps8"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "164.06mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs13">
                ※ 비정규직 근로자는 파견근로자, 용역근로자, 일일근로자,
                단시간근로자, 기간제근로자 등을 의미
              </span>
            </div>
            <div
              className="hls ps8"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "167.96mm",
                height: "3mm",
                width: "150mm",
              }}
            />
            <div
              className="hls ps0"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "171.82mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs10">
                4. 본 융자사업과제 선정 직전년도부터&nbsp;
              </span>
              <span className="hrt cs9">2023</span>
              <span className="hrt cs9">년까지 종업원 수</span>
              <span className="hrt cs10">를 기입해 주십시오.</span>
            </div>
            <div
              className="hls ps0"
              style={{
                lineHeight: "12.52mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "178.61mm",
                height: "12.52mm",
                width: "150mm",
              }}
            >
              <div
                className="htb"
                style={{
                  left: "0.5mm",
                  width: "149.01mm",
                  top: "1mm",
                  height: "12.52mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 154.01 17.52"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "154.01mm",
                    height: "17.52mm",
                  }}
                >
                  <path
                    d="M0,0 L0,10.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M148.01,0 L148.01,10.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,0 L148.07,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,10.52 L148.07,10.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M148.01,0 L148.01,10.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M0,0 L0,10.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,10.52 L148.07,10.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,0 L148.07,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.01mm",
                    height: "10.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps0"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className="hrt cs209">
                          ※예: 2018년 선정기업은 2017년부터 기입, 2020년
                          선정기업은 2019년부터 기입
                        </span>
                      </div>
                      <div
                        className="hls ps0"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "5.82mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className="hrt cs209">
                          ※종업원이 없는 경우에는 0을 기입&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hls ps36"
              style={{
                lineHeight: "22.55mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "193.85mm",
                height: "22.55mm",
                width: "150mm",
              }}
            >
              <div
                className="htb"
                style={{
                  left: "0.5mm",
                  width: "148.77mm",
                  top: "1mm",
                  height: "22.55mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 153.77 27.55"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.77mm",
                    height: "27.55mm",
                  }}
                >
                  <path
                    fill="url(#w_90)"
                    d="M0,0L18.47,0L18.47,4.77L0,4.77L0,0Z "
                  />
                  <path
                    fill="url(#w_90)"
                    d="M18.47,0L36.94,0L36.94,4.77L18.47,4.77L18.47,0Z "
                  />
                  <path
                    fill="url(#w_90)"
                    d="M36.94,0L55.41,0L55.41,4.77L36.94,4.77L36.94,0Z "
                  />
                  <path
                    fill="url(#w_90)"
                    d="M55.41,0L73.89,0L73.89,4.77L55.41,4.77L55.41,0Z "
                  />
                  <path
                    fill="url(#w_90)"
                    d="M73.89,0L92.36,0L92.36,4.77L73.89,4.77L73.89,0Z "
                  />
                  <path
                    fill="url(#w_90)"
                    d="M92.36,0L110.83,0L110.83,4.77L92.36,4.77L92.36,0Z "
                  />
                  <path
                    fill="url(#w_90)"
                    d="M110.83,0L129.30,0L129.30,4.77L110.83,4.77L110.83,0Z "
                  />
                  <path
                    fill="url(#w_90)"
                    d="M129.30,0L147.77,0L147.77,4.77L129.30,4.77L129.30,0Z "
                  />
                  <path
                    d="M0,0 L0,20.56"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M18.47,0 L18.47,4.78"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M18.47,0 L18.47,4.78"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M18.47,4.77 L18.47,12.66"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M18.47,4.77 L18.47,12.66"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M18.47,12.65 L18.47,20.56"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M18.47,12.65 L18.47,20.56"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M36.94,0 L36.94,20.56"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M55.41,0 L55.41,20.56"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M73.89,0 L73.89,20.56"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M92.36,0 L92.36,20.56"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M110.83,0 L110.83,20.56"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M129.30,0 L129.30,20.56"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M147.77,0 L147.77,20.56"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L148.03,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,4.77 L18.53,4.77"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M18.40,4.77 L148.03,4.77"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,12.65 L18.53,12.65"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M18.40,12.65 L148.03,12.65"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,20.56 L148.03,20.56"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M147.77,0 L147.77,20.56"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M0,0 L0,20.56"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,20.56 L148.03,20.56"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L148.03,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.77mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.12mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "18.47mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.77mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.12mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">2017년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "36.94mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.77mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.12mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">2018년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.41mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.77mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.12mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">2019년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "73.89mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.77mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.12mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">2020년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "92.36mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.77mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.12mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">2021년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "110.83mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.77mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.12mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs24">2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "129.3mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.77mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.12mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs24">2023</span>
                        <span className="hrt cs24">년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "4.77mm",
                    width: "18.47mm",
                    height: "7.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.68mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs22">정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "18.47mm",
                    top: "4.77mm",
                    width: "18.47mm",
                    height: "7.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.68mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2018}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                four: {
                                  ...teenPageData.four,
                                  one: {
                                    ...teenPageData.four.one,
                                    one:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.four.one.one === "number"
                                ? teenPageData.four.one.one
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "36.94mm",
                    top: "4.77mm",
                    width: "18.47mm",
                    height: "7.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.68mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2019}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                four: {
                                  ...teenPageData.four,
                                  two: {
                                    ...teenPageData.four.two,
                                    one:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.four.two.one === "number"
                                ? teenPageData.four.two.one
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.41mm",
                    top: "4.77mm",
                    width: "18.47mm",
                    height: "7.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.68mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2020}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                four: {
                                  ...teenPageData.four,
                                  three: {
                                    ...teenPageData.four.three,
                                    one:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.four.three.one === "number"
                                ? teenPageData.four.three.one
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "73.89mm",
                    top: "4.77mm",
                    width: "18.47mm",
                    height: "7.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.68mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2021}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                four: {
                                  ...teenPageData.four,
                                  four: {
                                    ...teenPageData.four.four,
                                    one:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.four.four.one === "number"
                                ? teenPageData.four.four.one
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "92.36mm",
                    top: "4.77mm",
                    width: "18.47mm",
                    height: "7.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.68mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2022}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                four: {
                                  ...teenPageData.four,
                                  five: {
                                    ...teenPageData.four.five,
                                    one:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.four.five.one === "number"
                                ? teenPageData.four.five.one
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "110.83mm",
                    top: "4.77mm",
                    width: "18.47mm",
                    height: "7.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.68mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2023}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                four: {
                                  ...teenPageData.four,
                                  six: {
                                    ...teenPageData.four.six,
                                    one:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.four.six.one === "number"
                                ? teenPageData.four.six.one
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "129.3mm",
                    top: "4.77mm",
                    width: "18.47mm",
                    height: "7.88mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.68mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            onChange={(e) => {
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                four: {
                                  ...teenPageData.four,
                                  seven: {
                                    ...teenPageData.four.seven,
                                    one:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.four.seven.one === "number"
                                ? teenPageData.four.seven.one
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "12.65mm",
                    width: "18.47mm",
                    height: "7.9mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.69mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs22">비정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "18.47mm",
                    top: "12.65mm",
                    width: "18.47mm",
                    height: "7.9mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.69mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2018}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                four: {
                                  ...teenPageData.four,
                                  one: {
                                    ...teenPageData.four.one,
                                    two:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.four.one.two === "number"
                                ? teenPageData.four.one.two
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "36.94mm",
                    top: "12.65mm",
                    width: "18.47mm",
                    height: "7.9mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.69mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2019}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                four: {
                                  ...teenPageData.four,
                                  two: {
                                    ...teenPageData.four.two,
                                    two:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.four.two.two === "number"
                                ? teenPageData.four.two.two
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.41mm",
                    top: "12.65mm",
                    width: "18.47mm",
                    height: "7.9mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.69mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2020}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                four: {
                                  ...teenPageData.four,
                                  three: {
                                    ...teenPageData.four.three,
                                    two:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.four.three.two === "number"
                                ? teenPageData.four.three.two
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "73.89mm",
                    top: "12.65mm",
                    width: "18.47mm",
                    height: "7.9mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.69mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2021}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                four: {
                                  ...teenPageData.four,
                                  four: {
                                    ...teenPageData.four.four,
                                    two:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.four.four.two === "number"
                                ? teenPageData.four.four.two
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "92.36mm",
                    top: "12.65mm",
                    width: "18.47mm",
                    height: "7.9mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.69mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2022}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                four: {
                                  ...teenPageData.four,
                                  five: {
                                    ...teenPageData.four.five,
                                    two:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.four.five.two === "number"
                                ? teenPageData.four.five.two
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "110.83mm",
                    top: "12.65mm",
                    width: "18.47mm",
                    height: "7.9mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.69mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2023}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                four: {
                                  ...teenPageData.four,
                                  six: {
                                    ...teenPageData.four.six,
                                    two:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.four.six.two === "number"
                                ? teenPageData.four.six.two
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "129.3mm",
                    top: "12.65mm",
                    width: "18.47mm",
                    height: "7.9mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.69mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            onChange={(e) => {
                              setisUpdate(true);
                              setTeenPageData({
                                ...teenPageData,
                                four: {
                                  ...teenPageData.four,
                                  seven: {
                                    ...teenPageData.four.seven,
                                    two:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof teenPageData.four.seven.two === "number"
                                ? teenPageData.four.seven.two
                                : ""
                            }
                            className="teen-number-input"
                            style={{ width: "60%", height: "80%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hls ps86"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "218.98mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs20">
                ※ 본 설문응답 인력은 ‘4대 보험 적용자’를 대상으로 함.
              </span>
              <span className="hrt cs21">&nbsp;</span>
            </div>
            <div
              className="hls ps8"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "223.77mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs21">
                ※ 년 중 비정규직 근로자가 정규직으로 전환되었을 경우, 정규직으로
                기입
              </span>
            </div>
            <div
              className="hls ps8"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "227.67mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs21">
                ※ 비정규직 근로자는 파견근로자, 용역근로자, 일일근로자,
                단시간근로자, 기간제근로자 등을 의미
              </span>
            </div>
            <div
              className="hls ps8"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "231.57mm",
                height: "3mm",
                width: "150mm",
              }}
            />
            <div
              className="hls ps109"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "236.62mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">5</span>
              <span className="hrt cs1">. 본&nbsp;</span>
              <span className="hrt cs152">
                융자사업과제로 개발된 제품으로 인하여&nbsp;
              </span>
              <span className="hrt cs9">2023년 외부로부터 투자유치한 실적</span>
              <span className="hrt cs1">을&nbsp;</span>
            </div>
            <div
              className="hls ps109"
              style={{
                paddingLeft: "4.99mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "241.67mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">기재해 주시기 바랍니다.</span>
            </div>
            <div
              className="hls ps112"
              style={{
                lineHeight: "14.04mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "246.92mm",
                height: "14.04mm",
                width: "150mm",
              }}
            >
              <div
                className="htb"
                style={{
                  left: "1mm",
                  width: "148.53mm",
                  top: "1mm",
                  height: "14.04mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 153.53 19.04"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.53mm",
                    height: "19.04mm",
                  }}
                >
                  <path
                    fill="url(#w_90)"
                    d="M0,0L57.91,0L57.91,5.18L0,5.18L0,0Z "
                  />
                  <path
                    fill="url(#w_91)"
                    d="M57.91,0L146.53,0L146.53,5.18L57.91,5.18L57.91,0Z "
                  />
                  <path
                    d="M0,0 L0,12.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M57.91,0 L57.91,12.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M57.91,5.18 L57.91,12.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M146.53,0 L146.53,12.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L146.78,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,5.18 L146.78,5.18"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M57.84,5.18 L146.78,5.18"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,12.04 L146.78,12.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M146.53,0 L146.53,12.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M0,0 L0,12.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,12.04 L146.78,12.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L146.78,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "57.91mm",
                    height: "5.18mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.32mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "56.91mm",
                        }}
                      >
                        <span className="hrt cs132">연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "57.91mm",
                    top: "0mm",
                    width: "88.62mm",
                    height: "5.18mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.32mm" }}>
                      <div
                        className="hls ps22"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "87.62mm",
                        }}
                      >
                        <span className="hrt cs132">투자유치액(백만원)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "5.18mm",
                    width: "57.91mm",
                    height: "6.87mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.17mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "56.91mm",
                        }}
                      >
                        <span className="hrt cs131">2023년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "57.91mm",
                    top: "4.2mm",
                    width: "88.62mm",
                    height: "7.87mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.47mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "87.62mm",
                        }}
                      >
                        <Input
                          type="number"
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setTeenPageData({
                              ...teenPageData,
                              five:
                                e.target.value === "" ? false : e.target.value,
                            });
                          }}
                          value={
                            typeof teenPageData.five === "string"
                              ? teenPageData.five
                              : ""
                          }
                          className="teen-number-input"
                          style={{ width: "40%", height: "80%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hls ps112"
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "260.77mm",
                height: "3.7mm",
                width: "150mm",
              }}
            />
            <div
              className="hls ps7"
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "262.21mm",
                height: "4.59mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeenPageComponent;
