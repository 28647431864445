import { Input } from "antd";
import React from "react";
import { changeNumber } from "src/ts/operator";
import { FourteenPageTypes } from "gita";

import _ from "lodash";

interface FourteenPageComponentProps {
  fourteenPageData: FourteenPageTypes;
  setFourteenPageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

const FourTeenPageComponent = ({
  fourteenPageData,
  setFourteenPageData,
  setisUpdate,
}: FourteenPageComponentProps) => {
  return (
    <div>
      <div className="hpa" style={{ width: "210mm", height: "297mm" }}>
        <div
          className="hpN"
          style={{
            left: "98.24mm",
            top: "282mm",
            width: "13.52mm",
            height: "3.53mm",
          }}
        >
          <span className="hrt cs0">- 14 -</span>
        </div>
        <div className="hcD" style={{ left: "30mm", top: "35mm" }}>
          <div className="hcI">
            <div
              className="hls ps21"
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.23mm",
                height: "4.59mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs15">Ⅶ. 만족도 및 수요조사&nbsp;</span>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "7.14mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className="hls ps0"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "13.75mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">
                1. 다음은 타 금융상품 및 정책자금의 조건을 고려하였을 때,&nbsp;
              </span>
              <span className="hrt cs9">정보통신응용기술개발지</span>
            </div>
            <div
              className="hls ps0"
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "20.35mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs9">원(융자)사업의 만족도</span>
              <span className="hrt cs1">
                에 관한 질문입니다. 귀사의 생각을 표시하여 주십시오.
              </span>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "74.26mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "27.15mm",
                height: "74.26mm",
                width: "150mm",
              }}
            >
              <div
                className="htb"
                style={{
                  left: "0.5mm",
                  width: "149.6mm",
                  top: "0.5mm",
                  height: "74.26mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 154.60 79.25"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "154.6mm",
                    height: "79.25mm",
                  }}
                >
                  <defs>
                    <pattern
                      id="w_130"
                      width={10}
                      height={10}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect width={10} height={10} fill="rgb(229,229,229)" />
                    </pattern>
                  </defs>
                  <path
                    fill="url(#w_130)"
                    d="M0,0L85.53,0L85.53,7.34L0,7.34L0,0Z "
                  />
                  <path
                    fill="url(#w_130)"
                    d="M85.53,0L98.14,0L98.14,7.34L85.53,7.34L85.53,0Z "
                  />
                  <path
                    fill="url(#w_130)"
                    d="M98.14,0L110.76,0L110.76,7.34L98.14,7.34L98.14,0Z "
                  />
                  <path
                    fill="url(#w_130)"
                    d="M110.76,0L123.37,0L123.37,7.34L110.76,7.34L110.76,0Z "
                  />
                  <path
                    fill="url(#w_130)"
                    d="M123.37,0L135.99,0L135.99,7.34L123.37,7.34L123.37,0Z "
                  />
                  <path
                    fill="url(#w_130)"
                    d="M135.99,0L148.60,0L148.60,7.34L135.99,7.34L135.99,0Z "
                  />
                  <path
                    d="M0,0 L0,73.26"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d="M85.53,0 L85.53,73.26"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d="M148.60,0 L148.60,73.26"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d="M-0.20,0 L148.81,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d="M-0.20,7.34 L148.81,7.34"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.20,16.58 L148.81,16.58"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.20,26.19 L148.81,26.19"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.20,33.70 L148.81,33.70"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.20,44.16 L148.81,44.16"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.20,54.40 L148.81,54.40"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.20,64.65 L148.81,64.65"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.20,73.26 L148.81,73.26"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d="M148.60,0 L148.60,73.26"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d="M0,0 L0,73.26"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d="M-0.20,73.26 L148.81,73.26"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d="M-0.20,0 L148.81,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "85.53mm",
                    height: "7.34mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "1.59mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "84.54mm",
                        }}
                      >
                        <span className="hrt cs60">구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "85.53mm",
                    top: "0mm",
                    width: "12.62mm",
                    height: "7.34mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI">
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className="hrt cs60">매우&nbsp;</span>
                      </div>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className="hrt cs60">불만족</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "98.14mm",
                    top: "0mm",
                    width: "12.62mm",
                    height: "7.34mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "1.59mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className="hrt cs60">불만족</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "110.76mm",
                    top: "0mm",
                    width: "12.62mm",
                    height: "7.34mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "1.59mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className="hrt cs60">보통</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "123.37mm",
                    top: "0mm",
                    width: "12.62mm",
                    height: "7.34mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "1.59mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className="hrt cs60">만족</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "135.99mm",
                    top: "0mm",
                    width: "12.62mm",
                    height: "7.34mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI">
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className="hrt cs60">매우&nbsp;</span>
                      </div>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className="hrt cs60">만족</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "7.34mm",
                    width: "85.53mm",
                    height: "9.24mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "2.36mm" }}>
                      <div
                        className="hls ps34"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "84.54mm",
                        }}
                      >
                        <span className="hrt cs158">
                          1) 융자지원을 통한 연구개발 목표 달성 만족도
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "85.53mm",
                    top: "7.34mm",
                    width: "63.08mm",
                    height: "9.24mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "2.54mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "62.09mm",
                        }}
                      >
                        <span className="hrt cs136">
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  one: "1",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.one === "1"}
                            name="page14_1_1"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  one: "2",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.one === "2"}
                            name="page14_1_1"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  one: "3",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.one === "3"}
                            name="page14_1_1"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  one: "4",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.one === "4"}
                            name="page14_1_1"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  one: "5",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.one === "5"}
                            name="page14_1_1"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "16.58mm",
                    width: "85.53mm",
                    height: "9.61mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "0.43mm" }}>
                      <div
                        className="hls ps34"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "84.54mm",
                        }}
                      >
                        <span className="hrt cs158">2) 선정에 대한 만족도</span>
                      </div>
                      <div
                        className="hls ps34"
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.8mm",
                          height: "2.82mm",
                          width: "84.54mm",
                        }}
                      >
                        <span className="hrt cs44">
                          &nbsp;&nbsp;&nbsp;(선정기준, 제출서류, 선정기간 등이
                          합리적이고 효율적으로 제공)&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "85.53mm",
                    top: "16.58mm",
                    width: "63.08mm",
                    height: "9.61mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "2.72mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "62.09mm",
                        }}
                      >
                        <span className="hrt cs136">
                          <input
                            type="radio"
                            onChange={(e) => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  two: "1",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.two === "1"}
                            name="page14_1_2"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={(e) => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  two: "2",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.two === "2"}
                            name="page14_1_2"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={(e) => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  two: "3",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.two === "3"}
                            name="page14_1_2"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={(e) => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  two: "4",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.two === "4"}
                            name="page14_1_2"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={(e) => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  two: "5",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.two === "5"}
                            name="page14_1_2"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "26.19mm",
                    width: "85.53mm",
                    height: "7.51mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "1.5mm" }}>
                      <div
                        className="hls ps34"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "84.54mm",
                        }}
                      >
                        <span className="hrt cs158">
                          3) 융자금의 이자율에 대한 만족도
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "85.53mm",
                    top: "26.19mm",
                    width: "63.08mm",
                    height: "7.51mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "1.67mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "62.09mm",
                        }}
                      >
                        <span className="hrt cs136">
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  three: "1",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.three === "1"}
                            name="page14_1_3"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  three: "2",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.three === "2"}
                            name="page14_1_3"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  three: "3",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.three === "3"}
                            name="page14_1_3"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  three: "4",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.three === "4"}
                            name="page14_1_3"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  three: "5",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.three === "5"}
                            name="page14_1_3"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "33.7mm",
                    width: "85.53mm",
                    height: "10.45mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "0.5mm" }}>
                      <div
                        className="hls ps34"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "84.54mm",
                        }}
                      >
                        <span className="hrt cs158">
                          4) 융자금의 2년 거치 3년 분할 상환 조건에 대한&nbsp;
                        </span>
                      </div>
                      <div
                        className="hls ps34"
                        style={{
                          paddingLeft: "4.29mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.76mm",
                          height: "3.53mm",
                          width: "84.54mm",
                        }}
                      >
                        <span className="hrt cs158">만족도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "85.53mm",
                    top: "33.7mm",
                    width: "63.08mm",
                    height: "10.45mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "3.14mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "62.09mm",
                        }}
                      >
                        <span className="hrt cs136">
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  four: "1",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.four === "1"}
                            name="page14_1_4"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  four: "2",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.four === "2"}
                            name="page14_1_4"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  four: "3",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.four === "3"}
                            name="page14_1_4"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  four: "4",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.four === "4"}
                            name="page14_1_4"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  four: "5",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.four === "5"}
                            name="page14_1_4"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "44.16mm",
                    width: "85.53mm",
                    height: "10.24mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "0.4mm" }}>
                      <div
                        className="hls ps34"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "84.54mm",
                        }}
                      >
                        <span className="hrt cs158">
                          5) 융자금 지급에 대한 만족도
                        </span>
                      </div>
                      <div
                        className="hls ps34"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.76mm",
                          height: "3.53mm",
                          width: "84.54mm",
                        }}
                      >
                        <span className="hrt cs44">
                          &nbsp;&nbsp;&nbsp;&nbsp;(지급방법, 절차, 기간 등이
                          합리적이고 효율적으로 제공)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "85.53mm",
                    top: "44.16mm",
                    width: "63.08mm",
                    height: "10.24mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "3.04mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "62.09mm",
                        }}
                      >
                        <span className="hrt cs136">
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  five: "1",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.five === "1"}
                            name="page14_1_5"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  five: "2",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.five === "2"}
                            name="page14_1_5"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  five: "3",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.five === "3"}
                            name="page14_1_5"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  five: "4",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.five === "4"}
                            name="page14_1_5"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  five: "5",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.five === "5"}
                            name="page14_1_5"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "54.4mm",
                    width: "85.53mm",
                    height: "10.24mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "0.4mm" }}>
                      <div
                        className="hls ps34"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "84.54mm",
                        }}
                      >
                        <span className="hrt cs158">
                          6) 융자신청 등 지원시스템의 만족도
                        </span>
                      </div>
                      <div
                        className="hls ps34"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.76mm",
                          height: "3.53mm",
                          width: "84.54mm",
                        }}
                      >
                        <span className="hrt cs44">
                          &nbsp;&nbsp;&nbsp;&nbsp;(신청, 문의사항 등 문제 해결의
                          편의성)&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "85.53mm",
                    top: "54.4mm",
                    width: "63.08mm",
                    height: "10.24mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "3.04mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "62.09mm",
                        }}
                      >
                        <span className="hrt cs136">
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  six: "1",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.six === "1"}
                            name="page14_1_6"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  six: "2",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.six === "2"}
                            name="page14_1_6"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  six: "3",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.six === "3"}
                            name="page14_1_6"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  six: "4",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.six === "4"}
                            name="page14_1_6"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  six: "5",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.six === "5"}
                            name="page14_1_6"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "64.65mm",
                    width: "85.53mm",
                    height: "8.61mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "2.05mm" }}>
                      <div
                        className="hls ps34"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "84.54mm",
                        }}
                      >
                        <span className="hrt cs158">
                          7) 신청액 대비 실제 지원결정 금액 만족도
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "85.53mm",
                    top: "64.65mm",
                    width: "63.08mm",
                    height: "8.61mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "2.23mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "62.09mm",
                        }}
                      >
                        <span className="hrt cs136">
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  seven: "1",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.seven === "1"}
                            name="page14_1_7"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  seven: "2",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.seven === "2"}
                            name="page14_1_7"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  seven: "3",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.seven === "3"}
                            name="page14_1_7"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  seven: "4",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.seven === "4"}
                            name="page14_1_7"
                          />
                          ………
                          <input
                            type="radio"
                            onChange={() => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                one: {
                                  ...fourteenPageData.one,
                                  seven: "5",
                                },
                              });
                            }}
                            checked={fourteenPageData.one.seven === "5"}
                            name="page14_1_7"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "103.94mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className="hls ps0"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "110.54mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">
                1-1. 귀사의 본 정보통신응용기술개발지원(융자)사업에 대한&nbsp;
              </span>
              <span className="hrt cs9">전체적인 만족도</span>
              <span className="hrt cs1">를 기입하</span>
            </div>
            <div
              className="hls ps0"
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "117.14mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">여 주십시오.</span>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "15.79mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "123.94mm",
                height: "15.79mm",
                width: "150mm",
              }}
            >
              <div
                className="htb"
                style={{
                  left: "1mm",
                  width: "150.62mm",
                  top: "1mm",
                  height: "15.79mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 155.62 20.79"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "155.62mm",
                    height: "20.79mm",
                  }}
                >
                  <path
                    d="M0,0 L0,13.79"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d="M84.61,0 L84.61,13.79"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M148.61,0 L148.61,13.79"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d="M-0.20,0 L148.82,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d="M-0.20,13.79 L148.82,13.79"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d="M148.61,0 L148.61,13.79"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d="M0,0 L0,13.79"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d="M-0.20,13.79 L148.82,13.79"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d="M-0.20,0 L148.82,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "84.61mm",
                    height: "13.79mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI">
                      <div
                        className="hls ps118"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "83.62mm",
                        }}
                      >
                        <span className="hrt cs119">
                          본 정보통신응용기술개발지원(융자)사업의 전체
                        </span>
                      </div>
                      <div
                        className="hls ps118"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.27mm",
                          height: "3.88mm",
                          width: "83.62mm",
                        }}
                      >
                        <span className="hrt cs119">
                          적인 만족도는 0~100점 중 몇 점 수준인지 응답
                        </span>
                      </div>
                      <div
                        className="hls ps118"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "8.72mm",
                          height: "3.88mm",
                          width: "83.62mm",
                        }}
                      >
                        <span className="hrt cs119">
                          해주시기 바랍니다.&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "84.61mm",
                    top: "0mm",
                    width: "64mm",
                    height: "13.79mm",
                  }}
                >
                  <div
                    className="hcD"
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className="hcI" style={{ top: "4.46mm" }}>
                      <div
                        className="hls ps3"
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "63.01mm",
                        }}
                      >
                        <span className="hrt cs51">
                          <Input
                            style={{
                              width: "3rem",
                            }}
                            onChange={(e) => {
                              setisUpdate(true);
                              setFourteenPageData({
                                ...fourteenPageData,
                                oneOne:
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value) > 100
                                    ? 100
                                    : changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value),
                              });
                            }}
                            value={
                              typeof fourteenPageData.oneOne === "number"
                                ? fourteenPageData.oneOne
                                : ""
                            }
                          />{" "}
                          점
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "142.26mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "148.86mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs204">
                1-2. 현재 정보통신응용기술개발지원(융자)사업의 융자 상환기간은
                2년 거치 3년 분할&nbsp;
              </span>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "155.47mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs204">상환(총 5년)입니다.&nbsp;</span>
              <span className="hrt cs203">융자상환기간이 어느 정도</span>
              <span className="hrt cs204">가 되어야 된다고 생각하십니까?</span>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "162.07mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs204">
                <label htmlFor="page14_1-2_1">
                  <input
                    type="radio"
                    onChange={(e) => {
                      setisUpdate(true);
                      setFourteenPageData({
                        ...fourteenPageData,
                        oneTwo: "1",
                      });
                    }}
                    checked={fourteenPageData.oneTwo === "1"}
                    name="page14_1-2"
                    id="page14_1-2_1"
                  />{" "}
                  현행 유지 (5년)
                </label>
                &nbsp;&nbsp;&nbsp;
                <label htmlFor="page14_1-2_2">
                  <input
                    type="radio"
                    onChange={(e) => {
                      setisUpdate(true);
                      setFourteenPageData({
                        ...fourteenPageData,
                        oneTwo: "2",
                      });
                    }}
                    checked={fourteenPageData.oneTwo === "2"}
                    name="page14_1-2"
                    id="page14_1-2_2"
                  />
                  &nbsp;6년
                </label>
                &nbsp;&nbsp;&nbsp;
                <label htmlFor="page14_1-2_3">
                  <input
                    type="radio"
                    onChange={(e) => {
                      setisUpdate(true);
                      setFourteenPageData({
                        ...fourteenPageData,
                        oneTwo: "3",
                      });
                    }}
                    checked={fourteenPageData.oneTwo === "3"}
                    name="page14_1-2"
                    id="page14_1-2_3"
                  />
                  &nbsp;7년
                </label>
                &nbsp;&nbsp;&nbsp;
                <label htmlFor="page14_1-2_4">
                  <input
                    type="radio"
                    onChange={(e) => {
                      setisUpdate(true);
                      setFourteenPageData({
                        ...fourteenPageData,
                        oneTwo: "4",
                      });
                    }}
                    checked={fourteenPageData.oneTwo === "4"}
                    name="page14_1-2"
                    id="page14_1-2_4"
                  />
                  &nbsp;8년
                </label>
                &nbsp;&nbsp;&nbsp;
                <label htmlFor="page14_1-2_5">
                  <input
                    type="radio"
                    onChange={(e) => {
                      setisUpdate(true);
                      setFourteenPageData({
                        ...fourteenPageData,
                        oneTwo: "5",
                      });
                    }}
                    checked={fourteenPageData.oneTwo === "5"}
                    name="page14_1-2"
                    id="page14_1-2_5"
                  />{" "}
                  9년 이상
                </label>
              </span>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "168.68mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "175.28mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs204">
                1-3. 정보통신응용기술개발지원(융자)사업&nbsp;
              </span>
              <span className="hrt cs203">신청은 어느 정도의 주기가 적합</span>
              <span className="hrt cs204">하다고 생각</span>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "181.88mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs204">하십니까?</span>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "188.49mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs204">
                <label htmlFor="page14_1-3_1">
                  <input
                    type="radio"
                    onChange={(e) => {
                      setisUpdate(true);
                      setFourteenPageData({
                        ...fourteenPageData,
                        oneThree: "1",
                      });
                    }}
                    checked={fourteenPageData.oneThree === "1"}
                    name="page14_1-3"
                    id="page14_1-3_1"
                  />{" "}
                  현행 유지(예산 소진 시까지 상시 접수)
                </label>
              </span>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "195.09mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs204">
                <label htmlFor="page14_1-3_2">
                  <input
                    type="radio"
                    onChange={(e) => {
                      setisUpdate(true);
                      setFourteenPageData({
                        ...fourteenPageData,
                        oneThree: "2",
                      });
                    }}
                    checked={fourteenPageData.oneThree === "2"}
                    name="page14_1-3"
                    id="page14_1-3_2"
                  />
                  &nbsp;상·하반기 (연 2회)
                </label>
              </span>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "201.7mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs204">
                <label htmlFor="page14_1-3_3">
                  <input
                    type="radio"
                    onChange={(e) => {
                      setisUpdate(true);
                      setFourteenPageData({
                        ...fourteenPageData,
                        oneThree: "3",
                      });
                    }}
                    checked={fourteenPageData.oneThree === "3"}
                    name="page14_1-3"
                    id="page14_1-3_3"
                  />{" "}
                  매 분기(연 4회)
                </label>
              </span>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "208.3mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs204">
                <label htmlFor="page14_1-3_4">
                  <input
                    type="radio"
                    onChange={(e) => {
                      setisUpdate(true);
                      setFourteenPageData({
                        ...fourteenPageData,
                        oneThree: "4",
                      });
                    }}
                    checked={fourteenPageData.oneThree === "4"}
                    name="page14_1-3"
                    id="page14_1-3_4"
                  />
                  &nbsp;2개월에 한 번(연 6회)
                </label>
              </span>
            </div>
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "214.9mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className="hls ps20"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "221.51mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourTeenPageComponent;
