import React from "react";
import { EighteenPageDataTypes } from "gita";
import Input from "antd/es/input/Input";
import TextArea from "antd/es/input/TextArea";

interface EighteenPageComponentProps {
  eightPageData: EighteenPageDataTypes;
  setEightPageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<any>>;
}

const EighteenPageComponent = ({
  eightPageData,
  setEightPageData,
  setisUpdate,
}: EighteenPageComponentProps) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "297mm" }}>
        <div
          className='hpN'
          style={{
            left: "98.24mm",
            top: "282mm",
            width: "13.52mm",
            height: "3.53mm",
          }}
        >
          <span className='hrt cs0'>- 18 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.19mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                11. 귀사는 정보통신응용기술개발지원(융자)사업과 연계하여&nbsp;
              </span>
              <span className='hrt cs152'>다음과 같은 사업이 얼마</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "6.41mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs152'>나 필요</span>
              <span className='hrt cs1'>하다고 생각하십니까?</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "59.14mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "13.21mm",
                height: "59.14mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.50mm",
                  width: "145.61mm",
                  top: "0.50mm",
                  height: "59.14mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 150.60 64.13'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "150.60mm",
                    height: "64.13mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_170'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_170)'
                    d='M0,0L76.54,0L76.54,7.34L0,7.34L0,0Z '
                  />
                  <path
                    fill='url(#w_170)'
                    d='M76.54,0L90.16,0L90.16,7.34L76.54,7.34L76.54,0Z '
                  />
                  <path
                    fill='url(#w_170)'
                    d='M90.16,0L103.77,0L103.77,7.34L90.16,7.34L90.16,0Z '
                  />
                  <path
                    fill='url(#w_170)'
                    d='M103.77,0L117.38,0L117.38,7.34L103.77,7.34L103.77,0Z '
                  />
                  <path
                    fill='url(#w_170)'
                    d='M117.38,0L131,0L131,7.34L117.38,7.34L117.38,0Z '
                  />
                  <path
                    fill='url(#w_170)'
                    d='M131,0L144.61,0L144.61,7.34L131,7.34L131,0Z '
                  />
                  <path
                    d='M0,0 L0,58.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M76.54,0 L76.54,58.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M144.61,0 L144.61,58.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L144.82,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,7.34 L144.82,7.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,13.20 L144.82,13.20'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,19.07 L144.82,19.07'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,24.94 L144.82,24.94'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,30.80 L144.82,30.80'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,36.67 L144.82,36.67'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,42.54 L144.82,42.54'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,47.41 L144.82,47.41'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,52.27 L144.82,52.27'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,58.14 L144.82,58.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M144.61,0 L144.61,58.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M0,0 L0,58.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,58.14 L144.82,58.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L144.82,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "76.54mm",
                    height: "7.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.59mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "75.55mm",
                        }}
                      >
                        <span className='hrt cs60'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "76.54mm",
                    top: "0mm",
                    width: "13.61mm",
                    height: "7.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.62mm",
                        }}
                      >
                        <span className='hrt cs60'>전혀&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "12.62mm",
                        }}
                      >
                        <span className='hrt cs60'>필요없음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "90.16mm",
                    top: "0mm",
                    width: "13.61mm",
                    height: "7.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.59mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.62mm",
                        }}
                      >
                        <span className='hrt cs60'>필요없음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "103.77mm",
                    top: "0mm",
                    width: "13.61mm",
                    height: "7.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.59mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.62mm",
                        }}
                      >
                        <span className='hrt cs60'>보통</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "117.38mm",
                    top: "0mm",
                    width: "13.61mm",
                    height: "7.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.59mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.62mm",
                        }}
                      >
                        <span className='hrt cs60'>필요함</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "131mm",
                    top: "0mm",
                    width: "13.61mm",
                    height: "7.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.62mm",
                        }}
                      >
                        <span className='hrt cs60'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "12.62mm",
                        }}
                      >
                        <span className='hrt cs60'>필요함</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "7.34mm",
                    width: "76.54mm",
                    height: "5.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.50mm" }}>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "75.55mm",
                        }}
                      >
                        <span className='hrt cs1'>① 추가 R&amp;D 지원</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "76.54mm",
                    top: "7.34mm",
                    width: "68.07mm",
                    height: "5.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.85mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "67.07mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenOne === "1"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenOne: "1",
                                  },
                                });
                              }}
                              name='eleven_one'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenOne === "2"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenOne: "2",
                                  },
                                });
                              }}
                              name='eleven_one'
                              value='2'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenOne === "3"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenOne: "3",
                                  },
                                });
                              }}
                              name='eleven_one'
                              value='3'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenOne === "4"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenOne: "4",
                                  },
                                });
                              }}
                              name='eleven_one'
                              value='4'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenOne === "5"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenOne: "5",
                                  },
                                });
                              }}
                              name='eleven_one'
                              value='5'
                            />
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "13.20mm",
                    width: "76.54mm",
                    height: "5.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.50mm" }}>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "75.55mm",
                        }}
                      >
                        <span className='hrt cs1'>② 시제품 제작 지원</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "76.54mm",
                    top: "13.20mm",
                    width: "68.07mm",
                    height: "5.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.85mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "67.07mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenTwo === "1"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenTwo: "1",
                                  },
                                });
                              }}
                              name='eleven_two'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenTwo === "2"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenTwo: "2",
                                  },
                                });
                              }}
                              name='eleven_two'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenTwo === "3"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenTwo: "3",
                                  },
                                });
                              }}
                              name='eleven_two'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenTwo === "4"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenTwo: "4",
                                  },
                                });
                              }}
                              name='eleven_two'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenTwo === "5"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenTwo: "5",
                                  },
                                });
                              }}
                              name='eleven_two'
                              value='1'
                            />
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "19.07mm",
                    width: "76.54mm",
                    height: "5.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.50mm" }}>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "75.55mm",
                        }}
                      >
                        <span className='hrt cs1'>③ 양산 지원</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "76.54mm",
                    top: "19.07mm",
                    width: "68.07mm",
                    height: "5.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.85mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "67.07mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenThree === "1"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenThree: "1",
                                  },
                                });
                              }}
                              name='eleven_three'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenThree === "2"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenThree: "2",
                                  },
                                });
                              }}
                              name='eleven_three'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenThree === "3"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenThree: "3",
                                  },
                                });
                              }}
                              name='eleven_three'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenThree === "4"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenThree: "4",
                                  },
                                });
                              }}
                              name='eleven_three'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenThree === "5"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenThree: "5",
                                  },
                                });
                              }}
                              name='eleven_three'
                              value='1'
                            />
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "24.94mm",
                    width: "76.54mm",
                    height: "5.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.50mm" }}>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "75.55mm",
                        }}
                      >
                        <span className='hrt cs1'>④ 인력 지원</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "76.54mm",
                    top: "24.94mm",
                    width: "68.07mm",
                    height: "5.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.85mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "67.07mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenFour === "1"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenFour: "1",
                                  },
                                });
                              }}
                              name='eleven_four'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenFour === "2"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenFour: "2",
                                  },
                                });
                              }}
                              name='eleven_four'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenFour === "3"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenFour: "3",
                                  },
                                });
                              }}
                              name='eleven_four'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenFour === "4"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenFour: "4",
                                  },
                                });
                              }}
                              name='eleven_four'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenFour === "5"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenFour: "5",
                                  },
                                });
                              }}
                              name='eleven_four'
                              value='1'
                            />
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "30.80mm",
                    width: "76.54mm",
                    height: "5.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.50mm" }}>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "75.55mm",
                        }}
                      >
                        <span className='hrt cs1'>
                          ⑤ 국내 마케팅(판로개척) 지원
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "76.54mm",
                    top: "30.80mm",
                    width: "68.07mm",
                    height: "5.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.85mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "67.07mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenFive === "1"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenFive: "1",
                                  },
                                });
                              }}
                              name='eleven_five'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenFive === "2"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenFive: "2",
                                  },
                                });
                              }}
                              name='eleven_five'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenFive === "3"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenFive: "3",
                                  },
                                });
                              }}
                              name='eleven_five'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenFive === "4"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenFive: "4",
                                  },
                                });
                              }}
                              name='eleven_five'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenFive === "5"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenFive: "5",
                                  },
                                });
                              }}
                              name='eleven_five'
                              value='1'
                            />
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "36.67mm",
                    width: "76.54mm",
                    height: "5.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.50mm" }}>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "75.55mm",
                        }}
                      >
                        <span className='hrt cs1'>
                          ⑥ 해외 마케팅(수출) 지원
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "76.54mm",
                    top: "36.67mm",
                    width: "68.07mm",
                    height: "5.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.85mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "67.07mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenSix === "1"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenSix: "1",
                                  },
                                });
                              }}
                              name='eleven_six'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenSix === "2"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenSix: "2",
                                  },
                                });
                              }}
                              name='eleven_six'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenSix === "3"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenSix: "3",
                                  },
                                });
                              }}
                              name='eleven_six'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenSix === "4"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenSix: "4",
                                  },
                                });
                              }}
                              name='eleven_six'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenSix === "5"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenSix: "5",
                                  },
                                });
                              }}
                              name='eleven_six'
                              value='1'
                            />
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "42.54mm",
                    width: "76.54mm",
                    height: "4.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "75.55mm",
                        }}
                      >
                        <span className='hrt cs1'>
                          ⑦ 인프라(시설 및 장비 등) 지원
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "76.54mm",
                    top: "42.54mm",
                    width: "68.07mm",
                    height: "4.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.35mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "67.07mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenSeven === "1"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenSeven: "1",
                                  },
                                });
                              }}
                              name='eleven_seven'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenSeven === "2"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenSeven: "2",
                                  },
                                });
                              }}
                              name='eleven_seven'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenSeven === "3"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenSeven: "3",
                                  },
                                });
                              }}
                              name='eleven_seven'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenSeven === "4"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenSeven: "4",
                                  },
                                });
                              }}
                              name='eleven_seven'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenSeven === "5"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenSeven: "5",
                                  },
                                });
                              }}
                              name='eleven_seven'
                              value='1'
                            />
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "47.41mm",
                    width: "76.54mm",
                    height: "4.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "75.55mm",
                        }}
                      >
                        <span className='hrt cs1'>⑧ 경영컨설팅 지원</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "76.54mm",
                    top: "47.41mm",
                    width: "68.07mm",
                    height: "4.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.35mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "67.07mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenEight === "1"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenEight: "1",
                                  },
                                });
                              }}
                              name='eleven_eight'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenEight === "2"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenEight: "2",
                                  },
                                });
                              }}
                              name='eleven_eight'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenEight === "3"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenEight: "3",
                                  },
                                });
                              }}
                              name='eleven_eight'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenEight === "4"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenEight: "4",
                                  },
                                });
                              }}
                              name='eleven_eight'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={eightPageData.eleven.elevenEight === "5"}
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenEight: "5",
                                  },
                                });
                              }}
                              name='eleven_eight'
                              value='1'
                            />
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "52.27mm",
                    width: "76.54mm",
                    height: "10mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.50mm" }}>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "75.55mm",
                        }}
                      >
                        <span className='hrt cs1'>
                          ⑨ 기타:
                          <Input
                            className='eighteen-input-gita'
                            placeholder=''
                            style={{}}
                            onChange={(e) => {
                              setisUpdate(true);
                              setEightPageData({
                                ...eightPageData,
                                eleven: {
                                  ...eightPageData.eleven,
                                  elevenNine: {
                                    ...eightPageData.eleven.elevenNine,
                                    text: e.target.value,
                                  },
                                },
                              });
                            }}
                            value={eightPageData.eleven.elevenNine.text}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "76.54mm",
                    top: "52.27mm",
                    width: "68.07mm",
                    height: "5.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.85mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "67.07mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <label>
                            <input
                              type='radio'
                              checked={
                                eightPageData.eleven.elevenNine.number === "1"
                              }
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenNine: {
                                      ...eightPageData.eleven.elevenNine,
                                      number: "1",
                                    },
                                  },
                                });
                              }}
                              name='eleven_nine'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={
                                eightPageData.eleven.elevenNine.number === "2"
                              }
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenNine: {
                                      ...eightPageData.eleven.elevenNine,
                                      number: "2",
                                    },
                                  },
                                });
                              }}
                              name='eleven_nine'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={
                                eightPageData.eleven.elevenNine.number === "3"
                              }
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenNine: {
                                      ...eightPageData.eleven.elevenNine,
                                      number: "3",
                                    },
                                  },
                                });
                              }}
                              name='eleven_nine'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={
                                eightPageData.eleven.elevenNine.number === "4"
                              }
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenNine: {
                                      ...eightPageData.eleven.elevenNine,
                                      number: "4",
                                    },
                                  },
                                });
                              }}
                              name='eleven_nine'
                              value='1'
                            />
                          </label>
                          ………
                          <label>
                            <input
                              type='radio'
                              checked={
                                eightPageData.eleven.elevenNine.number === "5"
                              }
                              onChange={(e) => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  eleven: {
                                    ...eightPageData.eleven,
                                    elevenNine: {
                                      ...eightPageData.eleven.elevenNine,
                                      number: "5",
                                    },
                                  },
                                });
                              }}
                              name='eleven_nine'
                              value='1'
                            />
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "74.87mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "81.48mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                12. 정보통신응용기술개발지원(융자)사업 전반에 대한&nbsp;
              </span>
              <span className='hrt cs152'>
                미흡한 점이나 개선의견, 정책적&nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "88.08mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs152'>요구사항, 향후 사업추진 방향</span>
              <span className='hrt cs1'>
                &nbsp;등을 자유롭게 기술하여 주십시오. 적극 반영하겠습
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "94.69mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>니다.&nbsp;</span>
            </div>
            <div
              className='hls ps72'
              style={{
                lineHeight: "19.14mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "101.48mm",
                height: "19.14mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.50mm",
                  width: "151.56mm",
                  top: "0.50mm",
                  height: "19.14mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <TextArea
                  rows={5}
                  onChange={(e) => {
                    setisUpdate(true);
                    setEightPageData({
                      ...eightPageData,
                      twelve: e.target.value,
                    });
                  }}
                  value={
                    typeof eightPageData.twelve === "string"
                      ? eightPageData.twelve
                      : ""
                  }
                  className='textarea'
                />
              </div>
            </div>
            <div
              className='hls ps72'
              style={{
                lineHeight: "4.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "123.60mm",
                height: "4.94mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EighteenPageComponent;
