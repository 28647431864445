import { Input } from "antd";
import React from "react";
import { changeNumber } from "src/ts/operator";
import { FifteenPageTypes } from "gita";

import _ from "lodash";

interface FifteenPageComponentProps {
  fifteenPageData: FifteenPageTypes;
  setFifteenPageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}
const FifteenPageComponent = ({
  fifteenPageData,
  setFifteenPageData,
  setisUpdate,
}: FifteenPageComponentProps) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "347mm" }}>
        <div
          className='hpN'
          style={{
            left: "98.24mm",
            top: "332mm",
            width: "13.52mm",
            height: "35mm",
          }}
        >
          <span className='hrt cs0'>- 15 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.19mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>2. 다음은&nbsp;</span>
              <span className='hrt cs9'>
                정보통신응용기술개발지원(융자)사업의 재이용 및 추천의향
              </span>
              <span className='hrt cs1'>에 관한 질문입니</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "6.41mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                다. 귀사의 생각을 표시하여 주십시오.
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "30.7mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "13.21mm",
                height: "30.7mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "149.6mm",
                  top: "0.5mm",
                  height: "30.7mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 154.60 35.70'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "154.6mm",
                    height: "35.7mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_140'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_140)'
                    d='M0,0L85.53,0L85.53,10.51L0,10.51L0,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M85.53,0L98.14,0L98.14,10.51L85.53,10.51L85.53,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M98.14,0L110.76,0L110.76,10.51L98.14,10.51L98.14,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M110.76,0L123.37,0L123.37,10.51L110.76,10.51L110.76,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M123.37,0L135.99,0L135.99,10.51L123.37,10.51L123.37,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M135.99,0L148.60,0L148.60,10.51L135.99,10.51L135.99,0Z '
                  />
                  <path
                    d='M0,0 L0,29.71'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M85.53,0 L85.53,29.71'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M148.60,0 L148.60,29.71'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L148.81,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,10.51 L148.81,10.51'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,20.13 L148.81,20.13'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,29.71 L148.81,29.71'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M148.60,0 L148.60,29.71'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,29.71'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,29.71 L148.81,29.71'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L148.81,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "85.53mm",
                    height: "10.51mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.17mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "84.54mm",
                        }}
                      >
                        <span className='hrt cs60'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "85.53mm",
                    top: "0mm",
                    width: "12.62mm",
                    height: "10.51mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className='hrt cs60'>전혀&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className='hrt cs60'>그렇지&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "6.19mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className='hrt cs60'>않다</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "98.14mm",
                    top: "0mm",
                    width: "12.62mm",
                    height: "10.51mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.59mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className='hrt cs60'>그렇지&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className='hrt cs60'>않다</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.76mm",
                    top: "0mm",
                    width: "12.62mm",
                    height: "10.51mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.17mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className='hrt cs60'>보통</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "123.37mm",
                    top: "0mm",
                    width: "12.62mm",
                    height: "10.51mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.17mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className='hrt cs60'>그렇다</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "135.99mm",
                    top: "0mm",
                    width: "12.62mm",
                    height: "10.51mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.59mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className='hrt cs60'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "11.63mm",
                        }}
                      >
                        <span className='hrt cs60'>그렇다</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "10.51mm",
                    width: "85.53mm",
                    height: "9.61mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.37mm" }}>
                      <div
                        className='hls ps20'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "84.54mm",
                        }}
                      >
                        <span className='hrt cs1'>
                          1) 다음에 다시 이용할 의향이 있다.&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "85.53mm",
                    top: "10.51mm",
                    width: "63.08mm",
                    height: "9.61mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.72mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "62.09mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                two: {
                                  ...fifteenPageData.two,
                                  one: "1",
                                },
                              });
                            }}
                            checked={fifteenPageData.two.one === "1"}
                            name='page15_2-1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                two: {
                                  ...fifteenPageData.two,
                                  one: "2",
                                },
                              });
                            }}
                            checked={fifteenPageData.two.one === "2"}
                            name='page15_2-1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                two: {
                                  ...fifteenPageData.two,
                                  one: "3",
                                },
                              });
                            }}
                            checked={fifteenPageData.two.one === "3"}
                            name='page15_2-1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                two: {
                                  ...fifteenPageData.two,
                                  one: "4",
                                },
                              });
                            }}
                            checked={fifteenPageData.two.one === "4"}
                            name='page15_2-1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                two: {
                                  ...fifteenPageData.two,
                                  one: "5",
                                },
                              });
                            }}
                            checked={fifteenPageData.two.one === "5"}
                            name='page15_2-1'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "20.13mm",
                    width: "85.53mm",
                    height: "9.58mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.36mm" }}>
                      <div
                        className='hls ps20'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "84.54mm",
                        }}
                      >
                        <span className='hrt cs1'>
                          2) 다른 업체에 추천할 의향이 있다.&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "85.53mm",
                    top: "20.13mm",
                    width: "63.08mm",
                    height: "9.58mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.71mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "62.09mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <input
                            type='radio'
                            onChange={(e) => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                two: {
                                  ...fifteenPageData.two,
                                  two: "1",
                                },
                              });
                            }}
                            checked={fifteenPageData.two.two === "1"}
                            name='page15_2-2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={(e) => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                two: {
                                  ...fifteenPageData.two,
                                  two: "2",
                                },
                              });
                            }}
                            checked={fifteenPageData.two.two === "2"}
                            name='page15_2-2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={(e) => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                two: {
                                  ...fifteenPageData.two,
                                  two: "3",
                                },
                              });
                            }}
                            checked={fifteenPageData.two.two === "3"}
                            name='page15_2-2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={(e) => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                two: {
                                  ...fifteenPageData.two,
                                  two: "4",
                                },
                              });
                            }}
                            checked={fifteenPageData.two.two === "4"}
                            name='page15_2-2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={(e) => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                two: {
                                  ...fifteenPageData.two,
                                  two: "5",
                                },
                              });
                            }}
                            checked={fifteenPageData.two.two === "5"}
                            name='page15_2-2'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "46.44mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "53.04mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>3. 귀사가&nbsp;</span>
              <span className='hrt cs9'>
                정보통신응용기술개발지원(융자)사업&nbsp;
              </span>
              <span className='hrt cs1'>융자금을 집행한&nbsp;</span>
              <span className='hrt cs9'>기관에 대해 만족하는 정도</span>
              <span className='hrt cs1'>를</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "59.65mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'> 표시하여 주십시오.&nbsp;</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "66.25mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>■ 기술보증기금에 대한 만족도</span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "31.8mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "73.05mm",
                height: "31.8mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "148.59mm",
                  top: "0.5mm",
                  height: "31.8mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.59 36.80'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.59mm",
                    height: "36.8mm",
                  }}
                >
                  <path
                    fill='url(#w_140)'
                    d='M0,0L83.53,0L83.53,7.95L0,7.95L0,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M83.53,0L92.35,0L92.35,7.95L83.53,7.95L83.53,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M92.35,0L106.16,0L106.16,7.95L92.35,7.95L92.35,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M106.16,0L119.97,0L119.97,7.95L106.16,7.95L106.16,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M119.97,0L133.78,0L133.78,7.95L119.97,7.95L119.97,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M133.78,0L147.59,0L147.59,7.95L133.78,7.95L133.78,0Z '
                  />
                  <path
                    d='M0,0 L0,30.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M83.53,0 L83.53,22.27'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,30.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,7.95 L147.80,7.95'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,15.11 L147.80,15.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,22.27 L147.80,22.27'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,30.81 L147.80,30.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,30.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,30.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,30.81 L147.80,30.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "83.53mm",
                    height: "7.95mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.89mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "82.54mm",
                        }}
                      >
                        <span className='hrt cs66'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.53mm",
                    top: "0mm",
                    width: "8.82mm",
                    height: "7.95mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.31mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "7.83mm",
                        }}
                      >
                        <span className='hrt cs66'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "7.83mm",
                        }}
                      >
                        <span className='hrt cs66'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.35mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "7.95mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.89mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs66'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "106.16mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "7.95mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.89mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs66'>보통</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.97mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "7.95mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.89mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs66'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "133.78mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "7.95mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.31mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs66'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs66'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "7.95mm",
                    width: "83.53mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps98'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "82.54mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          1) 기술보증기금 융자담당 직원에 대한 만족도
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.53mm",
                    top: "7.95mm",
                    width: "64.06mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.5mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "63.08mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  one: {
                                    ...fifteenPageData.three.one,
                                    one: "1",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.one.one === "1"}
                            name='page15_3-1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  one: {
                                    ...fifteenPageData.three.one,
                                    one: "2",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.one.one === "2"}
                            name='page15_3-1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  one: {
                                    ...fifteenPageData.three.one,
                                    one: "3",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.one.one === "3"}
                            name='page15_3-1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  one: {
                                    ...fifteenPageData.three.one,
                                    one: "4",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.one.one === "4"}
                            name='page15_3-1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  one: {
                                    ...fifteenPageData.three.one,
                                    one: "5",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.one.one === "5"}
                            name='page15_3-1'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "15.11mm",
                    width: "83.53mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps98'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "82.54mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          2) 기술보증기금 절차에 대한 만족도
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.53mm",
                    top: "15.11mm",
                    width: "64.06mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.5mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "63.08mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  one: {
                                    ...fifteenPageData.three.one,
                                    two: "1",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.one.two === "1"}
                            name='page15_3-2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  one: {
                                    ...fifteenPageData.three.one,
                                    two: "2",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.one.two === "2"}
                            name='page15_3-2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  one: {
                                    ...fifteenPageData.three.one,
                                    two: "3",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.one.two === "3"}
                            name='page15_3-2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  one: {
                                    ...fifteenPageData.three.one,
                                    two: "4",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.one.two === "4"}
                            name='page15_3-2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  one: {
                                    ...fifteenPageData.three.one,
                                    two: "5",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.one.two === "5"}
                            name='page15_3-2'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "21.3mm",
                    width: "147.59mm",
                    height: "8.54mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.01mm" }}>
                      <div
                        className='hls ps98'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "146.6mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          3) 기술보증기금에 대한 기타의견&nbsp;
                        </span>
                        <span className='hrt cs136' />
                        <Input
                          onChange={(e) => {
                            setisUpdate(true);
                            setFifteenPageData({
                              ...fifteenPageData,
                              three: {
                                ...fifteenPageData.three,
                                one: {
                                  ...fifteenPageData.three.one,
                                  three: e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof fifteenPageData.three.one.three === "boolean"
                              ? ""
                              : fifteenPageData.three.one.three
                          }
                          className='fifteen-input-gita'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps109'
              style={{
                lineHeight: "1.03mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "107.51mm",
                height: "1.41mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "109.22mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                ■ 융자금 집행 시중은행에 대한 만족도
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "37.79mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "116.01mm",
                height: "37.79mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "148.59mm",
                  top: "0.5mm",
                  height: "37.79mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.59 42.79'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.59mm",
                    height: "42.79mm",
                  }}
                >
                  <path
                    fill='url(#w_140)'
                    d='M0,0L83.53,0L83.53,9.03L0,9.03L0,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M83.53,0L92.35,0L92.35,9.03L83.53,9.03L83.53,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M92.35,0L106.16,0L106.16,9.03L92.35,9.03L92.35,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M106.16,0L119.97,0L119.97,9.03L106.16,9.03L106.16,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M119.97,0L133.78,0L133.78,9.03L119.97,9.03L119.97,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M133.78,0L147.59,0L147.59,9.03L133.78,9.03L133.78,0Z '
                  />
                  <path
                    d='M0,0 L0,36.80'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M83.53,0 L83.53,24.69'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,36.80'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,9.03 L147.80,9.03'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,16.86 L147.80,16.86'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,24.69 L147.80,24.69'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,36.80 L147.80,36.80'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,36.80'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,36.80'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,36.80 L147.80,36.80'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "83.53mm",
                    height: "9.03mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.43mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "82.54mm",
                        }}
                      >
                        <span className='hrt cs66'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.53mm",
                    top: "0mm",
                    width: "8.82mm",
                    height: "9.03mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.85mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "7.83mm",
                        }}
                      >
                        <span className='hrt cs66'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "7.83mm",
                        }}
                      >
                        <span className='hrt cs66'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.35mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "9.03mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.43mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs66'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "106.16mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "9.03mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.43mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs66'>보통</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.97mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "9.03mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.43mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs66'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "133.78mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "9.03mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.85mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs66'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs66'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "9.03mm",
                    width: "83.53mm",
                    height: "7.83mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.65mm" }}>
                      <div
                        className='hls ps98'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "82.54mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          1) 은행 융자담당 직원에 대한 만족도
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.53mm",
                    top: "9.03mm",
                    width: "64.06mm",
                    height: "7.83mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.83mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "63.08mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  two: {
                                    ...fifteenPageData.three.two,
                                    one: "1",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.two.one === "1"}
                            name='page15_3_2-1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  two: {
                                    ...fifteenPageData.three.two,
                                    one: "2",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.two.one === "2"}
                            name='page15_3_2-1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  two: {
                                    ...fifteenPageData.three.two,
                                    one: "3",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.two.one === "3"}
                            name='page15_3_2-1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  two: {
                                    ...fifteenPageData.three.two,
                                    one: "4",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.two.one === "4"}
                            name='page15_3_2-1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  two: {
                                    ...fifteenPageData.three.two,
                                    one: "5",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.two.one === "5"}
                            name='page15_3_2-1'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "16.86mm",
                    width: "83.53mm",
                    height: "7.83mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.65mm" }}>
                      <div
                        className='hls ps98'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "82.54mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          2) 신청에서 지급까지의 은행절차에 대한 만족도
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.53mm",
                    top: "16.86mm",
                    width: "64.06mm",
                    height: "7.83mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.83mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "63.08mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  two: {
                                    ...fifteenPageData.three.two,
                                    two: "1",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.two.two === "1"}
                            name='page15_3_2-2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  two: {
                                    ...fifteenPageData.three.two,
                                    two: "2",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.two.two === "2"}
                            name='page15_3_2-2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  two: {
                                    ...fifteenPageData.three.two,
                                    two: "3",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.two.two === "3"}
                            name='page15_3_2-2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  two: {
                                    ...fifteenPageData.three.two,
                                    two: "4",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.two.two === "4"}
                            name='page15_3_2-2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  two: {
                                    ...fifteenPageData.three.two,
                                    two: "5",
                                  },
                                },
                              });
                            }}
                            checked={fifteenPageData.three.two.two === "5"}
                            name='page15_3_2-2'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "24.69mm",
                    width: "147.59mm",
                    height: "12.11mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.8mm" }}>
                      <div
                        className='hls ps98'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "146.6mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          3) 융자집행 은행에 대한 기타의견&nbsp;
                        </span>
                        <span className='hrt cs136'>
                          <Input
                            onChange={(e) => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                three: {
                                  ...fifteenPageData.three,
                                  two: {
                                    ...fifteenPageData.three.two,
                                    three: e.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              typeof fifteenPageData.three.two.three ===
                              "boolean"
                                ? ""
                                : fifteenPageData.three.two.three
                            }
                            className='fifteen-input-gita'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "156.34mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "162.94mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>4. 다음은 귀사의&nbsp;</span>
              <span className='hrt cs9'>융자자금 수요조사</span>
              <span className='hrt cs1'>에 관한 질문입니다.&nbsp;</span>
            </div>
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "169.55mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>
                4-1. 현재 또는 가까운 미래에 연구개발을 위한 융자금이
                필요하십니까?
              </span>
            </div>
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "175.84mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>
                <label htmlFor='page15_4_1-1'>
                  <input
                    type='radio'
                    onChange={(e) => {
                      setisUpdate(true);
                      setFifteenPageData({
                        ...fifteenPageData,
                        four: {
                          ...fifteenPageData.four,
                          one: "1",
                        },
                      });
                    }}
                    checked={fifteenPageData.four.one === "1"}
                    name='page15_4_1'
                    id='page15_4_1-1'
                  />{" "}
                  필요하다 ==&gt; Q4-2로 이동
                </label>
                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                <label htmlFor='page15_4_1-2'>
                  <input
                    type='radio'
                    onChange={(e) => {
                      setisUpdate(true);
                      setFifteenPageData({
                        ...fifteenPageData,
                        four: {
                          ...fifteenPageData.four,
                          one: "2",
                        },
                      });
                    }}
                    checked={fifteenPageData.four.one === "2"}
                    name='page15_4_1'
                    id='page15_4_1-2'
                  />{" "}
                  필요없다 ==&gt; Q5로 이동
                </label>{" "}
              </span>
            </div>
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "182.12mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>
                4-2. 융자금의 필요시기는 언제입니까?
              </span>
            </div>
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "188.41mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>
                <label htmlFor='page15_4_2-1'>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);
                      setFifteenPageData({
                        ...fifteenPageData,
                        four: {
                          ...fifteenPageData.four,
                          two: "1",
                        },
                      });
                    }}
                    checked={fifteenPageData.four.two === "1"}
                    disabled={fifteenPageData.four.one !== "1"}
                    name='page15_4_2'
                    id='page15_4_2-1'
                  />{" "}
                  1년 이내
                </label>
                &nbsp;&nbsp;&nbsp;{" "}
                <label htmlFor='page15_4_2-2'>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);
                      setFifteenPageData({
                        ...fifteenPageData,
                        four: {
                          ...fifteenPageData.four,
                          two: "2",
                        },
                      });
                    }}
                    checked={fifteenPageData.four.two === "2"}
                    disabled={fifteenPageData.four.one !== "1"}
                    name='page15_4_2'
                    id='page15_4_2-2'
                  />{" "}
                  2년 이내
                </label>
                &nbsp;&nbsp;
                <label htmlFor='page15_4_2-3'>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);
                      setFifteenPageData({
                        ...fifteenPageData,
                        four: {
                          ...fifteenPageData.four,
                          two: "3",
                        },
                      });
                    }}
                    checked={fifteenPageData.four.two === "3"}
                    disabled={fifteenPageData.four.one !== "1"}
                    name='page15_4_2'
                    id='page15_4_2-3'
                  />{" "}
                  3년 이내
                </label>
                &nbsp;&nbsp;
                <label htmlFor='page15_4_2-4'>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);
                      setFifteenPageData({
                        ...fifteenPageData,
                        four: {
                          ...fifteenPageData.four,
                          two: "4",
                        },
                      });
                    }}
                    checked={fifteenPageData.four.two === "4"}
                    disabled={fifteenPageData.four.one !== "1"}
                    name='page15_4_2'
                    id='page15_4_2-4'
                  />{" "}
                  4년 이내
                </label>
                <label htmlFor='page15_4_2-5'>
                  &nbsp;&nbsp;
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);
                      setFifteenPageData({
                        ...fifteenPageData,
                        four: {
                          ...fifteenPageData.four,
                          two: "5",
                        },
                      });
                    }}
                    checked={fifteenPageData.four.two === "5"}
                    disabled={fifteenPageData.four.one !== "1"}
                    name='page15_4_2'
                    id='page15_4_2-5'
                  />{" "}
                  5년 이내
                </label>
              </span>
            </div>
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "194.7mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>
                4-3. 융자금 필요금액은 어느 정도입니까?
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Input
                  type='number'
                  onKeyDown={(event) =>
                    event.keyCode === 69 && event.preventDefault()
                  }
                  disabled={fifteenPageData.four.one !== "1"}
                  onChange={(e) => {
                    setisUpdate(true);
                    setFifteenPageData({
                      ...fifteenPageData,
                      four: {
                        ...fifteenPageData.four,
                        three: e.target.value === "" ? false : e.target.value,
                      },
                    });
                  }}
                  value={
                    typeof fifteenPageData.four.three === "string"
                      ? fifteenPageData.four.three
                      : ""
                  }
                  className='fifteen-input-number'
                />{" "}
                억 원
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "200.98mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps122'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "207.58mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>5.&nbsp;</span>
              <span className='hrt cs37'>다음은&nbsp;</span>
              <span className='hrt cs9'>
                정보통신응용기술개발지원(융자)사업
              </span>
              <span className='hrt cs29'>의 필요성</span>
              <span className='hrt cs37'>에 관한 질문입니다. 귀사의 생</span>
            </div>
            <div
              className='hls ps122'
              style={{
                paddingLeft: "5.28mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "214.19mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs37'>각을 표시하여 주십시오.</span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "33.8mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "220.98mm",
                height: "33.8mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "157.58mm",
                  top: "0.5mm",
                  height: "33.8mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 162.57 38.79'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "162.57mm",
                    height: "38.79mm",
                  }}
                >
                  <path
                    fill='url(#w_140)'
                    d='M0,0L102.50,0L102.50,11.53L0,11.53L0,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M102.50,0L113.32,0L113.32,11.53L102.50,11.53L102.50,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M113.32,0L124.13,0L124.13,11.53L113.32,11.53L113.32,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M124.13,0L134.95,0L134.95,11.53L124.13,11.53L124.13,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M134.95,0L145.76,0L145.76,11.53L134.95,11.53L134.95,0Z '
                  />
                  <path
                    fill='url(#w_140)'
                    d='M145.76,0L156.58,0L156.58,11.53L145.76,11.53L145.76,0Z '
                  />
                  <path
                    d='M0,0 L0,32.80'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M102.50,0 L102.50,32.80'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M156.58,0 L156.58,32.80'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L156.78,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,11.53 L156.78,11.53'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,21.67 L156.78,21.67'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,32.80 L156.78,32.80'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M156.58,0 L156.58,32.80'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,32.80'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,32.80 L156.78,32.80'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L156.78,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "102.5mm",
                    height: "11.53mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.69mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "101.52mm",
                        }}
                      >
                        <span className='hrt cs66'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "102.5mm",
                    top: "0mm",
                    width: "10.82mm",
                    height: "11.53mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.52mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "9.84mm",
                        }}
                      >
                        <span className='hrt cs66'>전혀&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "9.84mm",
                        }}
                      >
                        <span className='hrt cs66'>그렇지&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "6.19mm",
                          height: "3.17mm",
                          width: "9.84mm",
                        }}
                      >
                        <span className='hrt cs66'>않다</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "113.32mm",
                    top: "0mm",
                    width: "10.82mm",
                    height: "11.53mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.1mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "9.84mm",
                        }}
                      >
                        <span className='hrt cs66'>그렇지&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "9.84mm",
                        }}
                      >
                        <span className='hrt cs66'>않다</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "124.13mm",
                    top: "0mm",
                    width: "10.82mm",
                    height: "11.53mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.1mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "9.84mm",
                        }}
                      >
                        <span className='hrt cs66'>보통이</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "9.84mm",
                        }}
                      >
                        <span className='hrt cs66'>다</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "134.95mm",
                    top: "0mm",
                    width: "10.82mm",
                    height: "11.53mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.69mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "9.84mm",
                        }}
                      >
                        <span className='hrt cs66'>그렇다</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "145.76mm",
                    top: "0mm",
                    width: "10.82mm",
                    height: "11.53mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.1mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "9.84mm",
                        }}
                      >
                        <span className='hrt cs66'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "9.84mm",
                        }}
                      >
                        <span className='hrt cs66'>그렇다</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "11.53mm",
                    width: "102.5mm",
                    height: "10.14mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.82mm" }}>
                      <div
                        className='hls ps116'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "101.52mm",
                        }}
                      >
                        <span className='hrt cs202'>
                          1) 정보통신응용기술개발지원(융자)사업을 지속할
                          필요성이 있다.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "102.5mm",
                    top: "11.53mm",
                    width: "54.08mm",
                    height: "10.14mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.99mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "53.1mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                five: {
                                  ...fifteenPageData.five,
                                  one: "1",
                                },
                              });
                            }}
                            checked={fifteenPageData.five.one === "1"}
                            name='page15_5-1'
                          />
                          ……
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                five: {
                                  ...fifteenPageData.five,
                                  one: "2",
                                },
                              });
                            }}
                            checked={fifteenPageData.five.one === "2"}
                            name='page15_5-1'
                          />
                          ……
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                five: {
                                  ...fifteenPageData.five,
                                  one: "3",
                                },
                              });
                            }}
                            checked={fifteenPageData.five.one === "3"}
                            name='page15_5-1'
                          />
                          ……
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                five: {
                                  ...fifteenPageData.five,
                                  one: "4",
                                },
                              });
                            }}
                            checked={fifteenPageData.five.one === "4"}
                            name='page15_5-1'
                          />
                          ……
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                five: {
                                  ...fifteenPageData.five,
                                  one: "5",
                                },
                              });
                            }}
                            checked={fifteenPageData.five.one === "5"}
                            name='page15_5-1'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "21.67mm",
                    width: "102.5mm",
                    height: "11.13mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.32mm" }}>
                      <div
                        className='hls ps116'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "101.52mm",
                        }}
                      >
                        <span className='hrt cs202'>
                          2) 정보통신응용기술개발지원(융자)사업을 확대할
                          필요성이 있다.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "102.5mm",
                    top: "21.67mm",
                    width: "54.08mm",
                    height: "11.13mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.49mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "53.1mm",
                        }}
                      >
                        <span className='hrt cs136'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                five: {
                                  ...fifteenPageData.five,
                                  two: "1",
                                },
                              });
                            }}
                            checked={fifteenPageData.five.two === "1"}
                            name='page15_5-2'
                          />
                          ……
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                five: {
                                  ...fifteenPageData.five,
                                  two: "2",
                                },
                              });
                            }}
                            checked={fifteenPageData.five.two === "2"}
                            name='page15_5-2'
                          />
                          ……
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                five: {
                                  ...fifteenPageData.five,
                                  two: "3",
                                },
                              });
                            }}
                            checked={fifteenPageData.five.two === "3"}
                            name='page15_5-2'
                          />
                          ……
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                five: {
                                  ...fifteenPageData.five,
                                  two: "4",
                                },
                              });
                            }}
                            checked={fifteenPageData.five.two === "4"}
                            name='page15_5-2'
                          />
                          ……
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setFifteenPageData({
                                ...fifteenPageData,
                                five: {
                                  ...fifteenPageData.five,
                                  two: "5",
                                },
                              });
                            }}
                            checked={fifteenPageData.five.two === "5"}
                            name='page15_5-2'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps21'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "257.27mm",
                height: "4.59mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "264.61mm",
                height: "4.59mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifteenPageComponent;
