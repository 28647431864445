import { Input } from "antd";
import React from "react";
import { changeNumber } from "src/ts/operator";

import { SixPageTypes } from "gita";

interface SixPageComponentProps {
  sixPageData: SixPageTypes;
  setSixPageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

const SixPageComponent = ({
  sixPageData,
  setSixPageData,
  setisUpdate,
}: SixPageComponentProps) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "297mm" }}>
        <div
          className='hpN'
          style={{
            left: "99.27mm",
            top: "282mm",
            width: "11.46mm",
            height: "3.53mm",
          }}
        >
          <span className='hrt cs0'>- 6 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "1.25mm",
                height: "4.59mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs15'>Ⅲ. Activity/Process (과정)&nbsp;</span>
            </div>
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "10.11mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "16.32mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                1. 본 융자사업과제의 수행기간(과제선정년도~과제종료년도) 동안
                다음의&nbsp;
              </span>
              <span className='hrt cs9'>기술교류 활</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "22.92mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs9'>동(</span>
              <span className='hrt cs182'>오프라인/온라인 활동 모두 포함)</span>
              <span className='hrt cs9'>을 얼마나 수행</span>
              <span className='hrt cs1'>하셨는지 기입해 주시기 바랍니</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "29.52mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>다.&nbsp;</span>
              <span
                style={{
                  fontSize: "0.8rem",
                }}
                className='hrt cs1'
              >
                &nbsp;&nbsp;(활동 건수가 없으면 0을 기입)
              </span>
            </div>
            <div
              className='hls ps21'
              style={{
                lineHeight: "122.39mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "36.32mm",
                height: "122.39mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "150.14mm",
                  top: "0.5mm",
                  height: "122.39mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.14 127.38'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "155.14mm",
                    height: "127.38mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_50'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_50)'
                    d='M0,0L79.88,0L79.88,13.48L0,13.48L0,0Z '
                  />
                  <defs>
                    <pattern
                      id='w_51'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_51)'
                    d='M79.88,0L149.14,0L149.14,13.48L79.88,13.48L79.88,0Z '
                  />
                  <path
                    d='M0,0 L0,121.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M51.92,13.48 L51.92,94.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M79.88,0 L79.88,121.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M149.14,0 L149.14,121.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L149.35,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,13.48 L149.35,13.48'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M51.86,26.97 L149.35,26.97'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,40.45 L149.35,40.45'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M51.86,53.93 L149.35,53.93'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,67.42 L149.35,67.42'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M51.86,80.90 L149.35,80.90'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,94.38 L149.35,94.38'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,107.87 L149.35,107.87'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,121.39 L149.35,121.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M149.14,0 L149.14,121.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,121.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,121.39 L149.35,121.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L149.35,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "79.88mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "78.88mm",
                        }}
                      >
                        <span className='hrt cs132'>기술교류 활동 유형</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "79.88mm",
                    top: "0mm",
                    width: "69.27mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "68.27mm",
                        }}
                      >
                        <span className='hrt cs132'>활동 &nbsp;건수</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "13.48mm",
                    width: "51.92mm",
                    height: "26.97mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "11.22mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "50.91mm",
                        }}
                      >
                        <span className='hrt cs144'>학회 참석</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "51.92mm",
                    top: "13.48mm",
                    width: "27.96mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.95mm",
                        }}
                      >
                        <span className='hrt cs144'>국내</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "79.88mm",
                    top: "13.48mm",
                    width: "69.27mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "68.27mm",
                        }}
                      >
                        <span className='hrt cs116'>
                          <Input
                            value={
                              typeof sixPageData.one === "number"
                                ? sixPageData.one
                                : ""
                            }
                            onChange={(e) => {
                              setisUpdate(true);
                              const data = changeNumber(e.target.value);
                              const {
                                two,
                                three,
                                four,
                                five,
                                six,
                                seven: { number: seven },
                              } = sixPageData;

                              const newData =
                                Number(two) +
                                Number(three) +
                                Number(four) +
                                Number(five) +
                                Number(six) +
                                Number(seven) +
                                Number(data);

                              setSixPageData({
                                ...sixPageData,
                                one:
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value),
                                eight: newData,
                              });
                            }}
                            style={{ width: "40%", textAlign: "right" }}
                          />
                          &nbsp;건
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "51.92mm",
                    top: "26.97mm",
                    width: "27.96mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.95mm",
                        }}
                      >
                        <span className='hrt cs144'>국외</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "79.88mm",
                    top: "26.97mm",
                    width: "69.27mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "68.27mm",
                        }}
                      >
                        <span className='hrt cs116'>
                          <Input
                            value={
                              typeof sixPageData.two === "number"
                                ? sixPageData.two
                                : ""
                            }
                            onChange={(e) => {
                              setisUpdate(true);

                              const data = changeNumber(e.target.value);
                              const {
                                one,
                                three,
                                four,
                                five,
                                six,
                                seven: { number: seven },
                              } = sixPageData;

                              const newData =
                                Number(one) +
                                Number(three) +
                                Number(four) +
                                Number(five) +
                                Number(six) +
                                Number(seven) +
                                Number(data);

                              setSixPageData({
                                ...sixPageData,
                                two:
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value),
                                eight: newData,
                              });
                            }}
                            style={{ width: "40%", textAlign: "right" }}
                          />
                          &nbsp;건
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "40.45mm",
                    width: "51.92mm",
                    height: "26.97mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "11.22mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "50.91mm",
                        }}
                      >
                        <span className='hrt cs144'>포럼 및 세미나 참석</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "51.92mm",
                    top: "40.45mm",
                    width: "27.96mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.95mm",
                        }}
                      >
                        <span className='hrt cs144'>국내</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "79.88mm",
                    top: "40.45mm",
                    width: "69.27mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "68.27mm",
                        }}
                      >
                        <span className='hrt cs116'>
                          <Input
                            value={
                              typeof sixPageData.three === "number"
                                ? sixPageData.three
                                : ""
                            }
                            onChange={(e) => {
                              setisUpdate(true);

                              const data = changeNumber(e.target.value);
                              const {
                                one,
                                two,
                                four,
                                five,
                                six,
                                seven: { number: seven },
                              } = sixPageData;

                              const newData =
                                Number(one) +
                                Number(two) +
                                Number(four) +
                                Number(five) +
                                Number(six) +
                                Number(seven) +
                                Number(data);

                              setSixPageData({
                                ...sixPageData,
                                three:
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value),
                                eight: newData,
                              });
                            }}
                            style={{ width: "40%", textAlign: "right" }}
                          />
                          &nbsp;건
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "51.92mm",
                    top: "53.93mm",
                    width: "27.96mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.95mm",
                        }}
                      >
                        <span className='hrt cs144'>국외</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "79.88mm",
                    top: "53.93mm",
                    width: "69.27mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "68.27mm",
                        }}
                      >
                        <span className='hrt cs116'>
                          <Input
                            value={
                              typeof sixPageData.four === "number"
                                ? sixPageData.four
                                : ""
                            }
                            onChange={(e) => {
                              setisUpdate(true);

                              const data = changeNumber(e.target.value);
                              const {
                                one,
                                two,
                                three,
                                five,
                                six,
                                seven: { number: seven },
                              } = sixPageData;

                              const newData =
                                Number(one) +
                                Number(two) +
                                Number(three) +
                                Number(five) +
                                Number(six) +
                                Number(seven) +
                                Number(data);

                              setSixPageData({
                                ...sixPageData,
                                four:
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value),
                                eight: newData,
                              });
                            }}
                            style={{ width: "40%", textAlign: "right" }}
                          />
                          &nbsp;건
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "67.42mm",
                    width: "51.92mm",
                    height: "26.97mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "11.22mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "50.91mm",
                        }}
                      >
                        <span className='hrt cs144'>박람회 참석</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "51.92mm",
                    top: "67.42mm",
                    width: "27.96mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.95mm",
                        }}
                      >
                        <span className='hrt cs144'>국내</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "79.88mm",
                    top: "67.42mm",
                    width: "69.27mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "68.27mm",
                        }}
                      >
                        <span className='hrt cs116'>
                          <Input
                            value={
                              typeof sixPageData.five === "number"
                                ? sixPageData.five
                                : ""
                            }
                            onChange={(e) => {
                              setisUpdate(true);

                              const data = changeNumber(e.target.value);
                              const {
                                one,
                                two,
                                three,
                                four,
                                six,
                                seven: { number: seven },
                              } = sixPageData;

                              const newData =
                                Number(one) +
                                Number(two) +
                                Number(three) +
                                Number(four) +
                                Number(six) +
                                Number(seven) +
                                Number(data);

                              setSixPageData({
                                ...sixPageData,
                                five:
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value),
                                eight: newData,
                              });
                            }}
                            style={{ width: "40%", textAlign: "right" }}
                          />
                          &nbsp;건
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "51.92mm",
                    top: "80.9mm",
                    width: "27.96mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.95mm",
                        }}
                      >
                        <span className='hrt cs144'>국외</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "79.88mm",
                    top: "80.9mm",
                    width: "69.27mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "68.27mm",
                        }}
                      >
                        <span className='hrt cs116'>
                          <Input
                            value={
                              typeof sixPageData.six === "number"
                                ? sixPageData.six
                                : ""
                            }
                            onChange={(e) => {
                              setisUpdate(true);

                              const data = changeNumber(e.target.value);
                              const {
                                one,
                                two,
                                three,
                                five,
                                four,
                                seven: { number: seven },
                              } = sixPageData;

                              const newData =
                                Number(one) +
                                Number(two) +
                                Number(three) +
                                Number(five) +
                                Number(four) +
                                Number(seven) +
                                Number(data);

                              setSixPageData({
                                ...sixPageData,
                                six:
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value),
                                eight: newData,
                              });
                            }}
                            style={{ width: "40%", textAlign: "right" }}
                          />
                          &nbsp;건
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "94.38mm",
                    width: "79.88mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "78.88mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          기타 ({" "}
                          <Input
                            type='text'
                            value={
                              typeof sixPageData.seven.text === "string"
                                ? sixPageData.seven.text
                                : ""
                            }
                            onChange={(e) => {
                              setisUpdate(true);
                              setSixPageData({
                                ...sixPageData,
                                seven: {
                                  ...sixPageData.seven,
                                  text: e.target.value,
                                },
                              });
                            }}
                            style={{ width: "70%", padding: "0px 2px" }}
                          />{" "}
                          )
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "79.88mm",
                    top: "94.38mm",
                    width: "69.27mm",
                    height: "13.48mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.48mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "68.27mm",
                        }}
                      >
                        <span className='hrt cs116'>
                          <Input
                            value={
                              typeof sixPageData.seven.number === "number"
                                ? sixPageData.seven.number
                                : ""
                            }
                            onChange={(e) => {
                              setisUpdate(true);

                              const data = changeNumber(e.target.value);
                              const { one, two, three, five, four, six } =
                                sixPageData;

                              const newData =
                                Number(one) +
                                Number(two) +
                                Number(three) +
                                Number(five) +
                                Number(four) +
                                Number(six) +
                                Number(data);

                              setSixPageData({
                                ...sixPageData,
                                seven: {
                                  ...sixPageData.seven,
                                  number:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                },
                                eight: newData,
                              });
                            }}
                            style={{ width: "40%", textAlign: "right" }}
                          />
                          &nbsp;건
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "107.87mm",
                    width: "79.88mm",
                    height: "13.53mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.5mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "78.88mm",
                        }}
                      >
                        <span className='hrt cs144'>합계</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "79.88mm",
                    top: "107.87mm",
                    width: "69.27mm",
                    height: "13.53mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.5mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "68.27mm",
                        }}
                      >
                        <span className='hrt cs116'>
                          <Input
                            value={
                              typeof sixPageData.eight === "number"
                                ? sixPageData.eight
                                : ""
                            }
                            disabled={true}
                            onChange={(e) => {
                              setisUpdate(true);

                              setSixPageData({
                                ...sixPageData,
                                eight:
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value),
                              });
                            }}
                            style={{ width: "40%", textAlign: "right" }}
                          />
                          &nbsp;건
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps21'
              style={{
                lineHeight: "2.63mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "160.75mm",
                height: "3.35mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs156'>
                &nbsp;&nbsp;&nbsp;※박람회에서 세미나가 개최되어 둘 다 참석한
                경우, 각각 기입
              </span>
            </div>
            <div
              className='hls ps21'
              style={{
                lineHeight: "2.63mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "166.11mm",
                height: "3.35mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs156'>
                &nbsp;&nbsp;&nbsp;※오프라인/온라인 유형 기술교류활동 모두
                포함하여 기입
              </span>
            </div>
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "172.89mm",
                height: "4.59mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "181.7mm",
                height: "4.59mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "190.52mm",
                height: "4.59mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "199.34mm",
                height: "4.59mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "208.16mm",
                height: "4.59mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "216.98mm",
                height: "4.59mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "225.8mm",
                height: "4.59mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "234.62mm",
                height: "4.59mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "243.44mm",
                height: "4.59mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SixPageComponent;
