import { createStore } from "@reduxjs/toolkit";
import reducer from "src/store/reducers";

const makeStore = () => {
  const store = createStore(reducer);
  return store;
};

export default makeStore();
export type RootState = ReturnType<typeof reducer>;
