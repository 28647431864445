import React from "react";
import { Button, Modal } from "antd";

const NineteenContent = ({ detailPage, setDetailPage }: any) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "297mm" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "60px 20px 0px 20px",
            alignItems: "center",
          }}
        >
          <Button
            disabled={detailPage === 0}
            onClick={() => {
              setDetailPage((pre: number) => pre - 1);
            }}
          >
            이전
          </Button>

          <span
            style={{
              display: "inline-block",
              margin: "0px 20px",
            }}
          >
            {detailPage + 1} / 12
          </span>

          <Button
            style={{}}
            disabled={detailPage === 11}
            onClick={() => {
              setDetailPage((pre: number) => pre + 1);
            }}
          >
            다음
          </Button>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps73'
              style={{
                lineHeight: "4.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.25mm",
                height: "4.94mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs141'>
                ICT연구개발 기술분류체계(제14조제1항 관련)
              </span>
            </div>
            <div
              className='hls ps73'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "7.93mm",
                height: "4.59mm",
                width: "0mm",
              }}
            />
          </div>
        </div>
        <div
          className='htb'
          style={{
            left: "30mm",
            width: "149.82mm",
            top: "43.15mm",
            height: "223.49mm",
          }}
        >
          <svg
            className='hs'
            viewBox='-2.50 -2.50 154.82 228.49'
            style={{
              left: "-2.50mm",
              top: "-2.50mm",
              width: "154.82mm",
              height: "228.49mm",
            }}
          >
            <defs>
              <pattern
                id='w_180'
                width={10}
                height={10}
                patternUnits='userSpaceOnUse'
              >
                <rect width={10} height={10} fill='rgb(216,216,216)' />
              </pattern>
            </defs>
            <path fill='url(#w_180)' d='M0,0L21.59,0L21.59,7.48L0,7.48L0,0Z ' />
            <path
              fill='url(#w_180)'
              d='M21.59,0L44.36,0L44.36,7.48L21.59,7.48L21.59,0Z '
            />
            <path
              fill='url(#w_180)'
              d='M44.36,0L79.95,0L79.95,7.48L44.36,7.48L44.36,0Z '
            />
            <path
              fill='url(#w_180)'
              d='M79.95,0L149.82,0L149.82,7.48L79.95,7.48L79.95,0Z '
            />
            <path
              d='M0,0 L0,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M0,7.48 L0,223.50'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M21.59,0 L21.59,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M21.59,7.48 L21.59,223.50'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M21.59,85.87 L21.59,157.13'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M21.59,157.13 L21.59,223.50'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M44.36,0 L44.36,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.36,7.48 L44.36,85.87'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M44.36,85.87 L44.36,157.13'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.36,157.13 L44.36,223.50'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.95,0 L79.95,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.95,7.48 L79.95,85.87'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.95,85.87 L79.95,157.13'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.95,157.13 L79.95,223.50'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M149.82,0 L149.82,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M149.82,7.48 L149.82,85.87'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M149.82,85.87 L149.82,157.13'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M149.82,157.13 L149.82,223.50'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M-0.07,0 L149.90,0'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.07,7.48 L149.90,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.90,14.60 L149.87,14.60'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.90,21.73 L149.87,21.73'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M44.31,28.86 L149.87,28.86'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.90,35.98 L149.87,35.98'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.90,43.11 L149.87,43.11'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M44.31,50.24 L149.87,50.24'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.90,57.36 L149.87,57.36'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.90,64.49 L149.87,64.49'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M44.31,71.61 L149.87,71.61'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.90,78.74 L149.87,78.74'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M21.54,85.87 L44.41,85.87'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.12",
              }}
            />
            <path
              d='M44.31,85.87 L149.87,85.87'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.88,92.99 L149.90,92.99'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,100.12 L149.90,100.12'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,107.24 L80.03,107.24'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.88,107.24 L149.90,107.24'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,114.37 L149.90,114.37'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,121.50 L149.90,121.50'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.28,128.62 L80.03,128.62'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.88,128.62 L149.90,128.62'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,135.75 L149.90,135.75'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,142.87 L149.90,142.87'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.88,150 L149.90,150'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M21.51,157.13 L44.44,157.13'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M21.54,157.13 L44.41,157.13'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.12",
              }}
            />
            <path
              d='M44.28,157.13 L80.03,157.13'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M44.31,157.13 L80,157.13'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.88,157.13 L149.90,157.13'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M79.90,157.13 L149.87,157.13'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.90,164.25 L149.87,164.25'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.90,171.38 L149.87,171.38'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M44.31,178.51 L149.87,178.51'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.90,185.63 L149.87,185.63'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.90,192.76 L149.87,192.76'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M44.31,199.88 L149.87,199.88'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M79.90,207.01 L149.87,207.01'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M44.31,214.14 L149.87,214.14'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M-0.05,223.49 L44.41,223.49'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.12",
              }}
            />
            <path
              d='M44.31,223.49 L149.87,223.49'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M149.82,0 L149.82,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M149.82,7.48 L149.82,85.87'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M149.82,85.87 L149.82,157.13'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M149.82,157.13 L149.82,223.50'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M0,7.48 L0,223.50'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M0,0 L0,7.48'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
            <path
              d='M-0.05,223.49 L44.41,223.49'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.12",
              }}
            />
            <path
              d='M44.31,223.49 L149.87,223.49'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.10",
              }}
            />
            <path
              d='M-0.07,0 L149.90,0'
              style={{
                stroke: "#000000",
                strokeLinecap: "butt",
                strokeWidth: "0.15",
              }}
            />
          </svg>
          <div
            className='hce'
            style={{
              left: "0mm",
              top: "0mm",
              width: "21.59mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "19.59mm",
                  }}
                >
                  <span className='hrt cs190'>대분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "0mm",
              width: "22.77mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs190'>중분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "0mm",
              width: "35.59mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs190'>소분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "0mm",
              width: "69.87mm",
              height: "7.48mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs190'>세분류</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "0mm",
              top: "7.48mm",
              width: "21.59mm",
              height: "216.02mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "101.74mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "19.59mm",
                  }}
                >
                  <span className='hrt cs191'>미래통신·</span>
                </div>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "4.86mm",
                    height: "3.88mm",
                    width: "19.59mm",
                  }}
                >
                  <span className='hrt cs191'>전파</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "7.48mm",
              width: "22.77mm",
              height: "78.39mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "35.45mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs191'>차세대통신</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "7.48mm",
              width: "35.59mm",
              height: "21.38mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "6.95mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>통신 서비스</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "7.48mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>통신 서비스/플랫폼</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "14.60mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>통신 컴퓨팅 융합</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "21.73mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>시험/인증</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "28.86mm",
              width: "35.59mm",
              height: "21.38mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "6.95mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>무선통신 시스템</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "28.86mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>셀룰러 이동통신 시스템</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "35.98mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>근거리 무선통신 시스템</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "43.11mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>특수융합통신 시스템</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "50.24mm",
              width: "35.59mm",
              height: "21.38mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "6.95mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>유선통신 시스템</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "50.24mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>전달 네트워크 시스템</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "57.36mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>액세스/에지 네트워크 시스템</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "64.49mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>데이터센터 네트워크 시스템</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "71.61mm",
              width: "35.59mm",
              height: "14.25mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "3.39mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>통신 단말/부품</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "71.61mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>통신모듈/부품</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "78.74mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>단말기</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "85.87mm",
              width: "22.77mm",
              height: "71.26mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "29.37mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs191'>양자정보통</span>
                </div>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "4.86mm",
                    height: "3.88mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs191'>신</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "85.87mm",
              width: "35.59mm",
              height: "21.38mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "6.95mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>양자통신</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "85.87mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>양자암호</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "92.99mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>양자전송</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "100.12mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>양자네트워크</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "107.24mm",
              width: "35.59mm",
              height: "21.38mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "6.95mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>양자센서/이미징</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "107.24mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>양자측정</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "114.37mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>양자센싱</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "121.50mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>양자이미징</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "128.62mm",
              width: "35.59mm",
              height: "28.50mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "10.51mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>양자컴퓨팅</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "128.62mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>양자시뮬레이터</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "135.75mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>물리적 양자비트</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "142.87mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>결함허용 양자컴퓨팅</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "150mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>양자 소프트웨어 및 정보이론</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "21.59mm",
              top: "157.13mm",
              width: "22.77mm",
              height: "66.37mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "29.44mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "20.77mm",
                  }}
                >
                  <span className='hrt cs191'>전파·위성</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "157.13mm",
              width: "35.59mm",
              height: "21.38mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "6.95mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>전파기반</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "157.13mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>안테나/전자파해석</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "164.25mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>전파기반 디바이스</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "171.38mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>전파계측</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "178.51mm",
              width: "35.59mm",
              height: "21.38mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "6.95mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>전파응용</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "178.51mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>전파 에너지 응용</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "185.63mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>전파 센싱 응용</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "192.76mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>정보전송</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "199.88mm",
              width: "35.59mm",
              height: "14.25mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "3.39mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>전파자원/환경</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "199.88mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>스펙트럼 공학</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "207.01mm",
              width: "69.87mm",
              height: "7.13mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI'>
                <div
                  className='hls ps96'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs195'>전파환경 보호</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "44.36mm",
              top: "214.14mm",
              width: "35.59mm",
              height: "9.36mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "0.94mm" }}>
                <div
                  className='hls ps95'
                  style={{
                    lineHeight: "3.10mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.19mm",
                    height: "3.88mm",
                    width: "33.58mm",
                  }}
                >
                  <span className='hrt cs191'>위성/무인기</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className='hce'
            style={{
              left: "79.95mm",
              top: "214.14mm",
              width: "69.87mm",
              height: "9.36mm",
            }}
          >
            <div className='hcD' style={{ left: "1mm", top: "1.80mm" }}>
              <div className='hcI' style={{ top: "1.11mm" }}>
                <div
                  className='hls ps94'
                  style={{
                    lineHeight: "2.79mm",
                    whiteSpace: "nowrap",
                    left: "0mm",
                    top: "-0.18mm",
                    height: "3.53mm",
                    width: "67.87mm",
                  }}
                >
                  <span className='hrt cs194'>탑재체</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NineteenContent;
