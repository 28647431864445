import React from "react";

const TwelvePageBlock = ({ eightNumber }: any) => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: eightNumber === false ? "25%" : "20%",
          zIndex: 1000,
        }}
      >
        {eightNumber === false ? (
          <div>
            <div style={{}}>
              <h1
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0px 20px",
                }}
              >
                8페이지 작성 후 이용해 주세요. <br />
              </h1>
            </div>
          </div>
        ) : (
          <div>
            <h1
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px 20px",
              }}
            >
              [서비스 개발]에 해당하는 질문입니다. <br />
            </h1>
            <h3
              style={{
                position: "absolute",
                top: "100%",
                left: "15%",
              }}
            >
              10페이지를 작성하셨다면 13페이지로 이동해주세요.
            </h3>
          </div>
        )}
      </div>
      <div
        className='hpa'
        style={{ width: "210mm", height: "297mm", opacity: 0.3 }}
      >
        <div
          className='hpN'
          style={{
            left: "98.24mm",
            top: "282mm",
            width: "13.52mm",
            height: "3.53mm",
          }}
        >
          <span className='hrt cs0'>- 12 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps5'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.19mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                2. 본 융자사업과제를 통해 개발된 기술을 사업화하여 발생한 매출액
                이외에&nbsp;
              </span>
            </div>
            <div
              className='hls ps5'
              style={{
                paddingLeft: "7.06mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "6.41mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs9'>
                귀사가 개발한 기술로 인해 비용절감효과
              </span>
              <span className='hrt cs1'>
                가 있었습니까? ( &nbsp;&nbsp;&nbsp;&nbsp;)
              </span>
            </div>
            <div
              className='hls ps68'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "13.02mm",
                height: "3.70mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs86'>
                &nbsp;&nbsp;① 예
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span
                className='htC'
                style={{ left: "0.92mm", width: "11.81mm", height: "100%" }}
              />
              <span className='hrt cs86'>&nbsp;&nbsp;&nbsp;&nbsp;② 아니오</span>
            </div>
            <div
              className='hls ps68'
              style={{
                lineHeight: "1.59mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "17.92mm",
                height: "2.12mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "20.59mm",
                height: "3.70mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>
                2-1. 위 항목에서 ‘예’라고 응답한 경우, 비용절감액을 기입해
                주시기 바랍니다.
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "18.18mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "27.07mm",
                height: "18.18mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "150.01mm",
                  top: "1mm",
                  height: "18.18mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.01 23.17'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "155.01mm",
                    height: "23.17mm",
                  }}
                >
                  <path
                    d='M0,0 L0,16.18'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,16.18'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,16.18 L148.07,16.18'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,16.18'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M0,0 L0,16.18'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,16.18 L148.07,16.18'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.01mm",
                    height: "16.18mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "1.80mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps122'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※예: 2017년 선정기업은 2018년부터 기입, 2019년
                          선정기업은 2020년부터 기입
                        </span>
                      </div>
                      <div
                        className='hls ps122'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "5.65mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※비용절감액이 없는 경우에는 0을 기입
                        </span>
                      </div>
                      <div
                        className='hls ps122'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "11.48mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※2022년 선정기업은 2022년만 기입
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps36'
              style={{
                lineHeight: "29.13mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "47.96mm",
                height: "29.13mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "150.52mm",
                  top: "1mm",
                  height: "29.13mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.52 34.13'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "155.52mm",
                    height: "34.13mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_110'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_110)'
                    d='M0,0L55.92,0L55.92,4.52L0,4.52L0,0Z '
                  />
                  <defs>
                    <pattern
                      id='w_111'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_111)'
                    d='M55.92,0L148.53,0L148.53,4.52L55.92,4.52L55.92,0Z '
                  />
                  <path
                    d='M0,0 L0,27.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M55.92,0 L55.92,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.92,4.52 L55.92,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M55.92,9.05 L55.92,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.92,9.05 L55.92,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M55.92,13.57 L55.92,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.92,13.57 L55.92,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M55.92,18.09 L55.92,22.62'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.92,18.09 L55.92,22.62'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M55.92,22.61 L55.92,27.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.92,22.61 L55.92,27.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M148.53,0 L148.53,27.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,0 L148.78,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,4.52 L148.78,4.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.84,4.52 L148.78,4.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,9.05 L55.98,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.84,9.05 L148.78,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,13.57 L55.98,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.84,13.57 L148.78,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,18.09 L55.98,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.84,18.09 L148.78,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,22.61 L55.98,22.61'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.84,22.61 L148.78,22.61'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,27.14 L148.78,27.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M148.53,0 L148.53,27.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M0,0 L0,27.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,27.14 L148.78,27.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,0 L148.78,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "55.92mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className='hrt cs129'>연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.92mm",
                    top: "0mm",
                    width: "92.61mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      >
                        <span className='hrt cs129'>
                          &nbsp;비용절감액(백만원)
                        </span>
                        <span className='hrt cs130'>1)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "4.52mm",
                    width: "55.92mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className='hrt cs131'>2018년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.92mm",
                    top: "4.52mm",
                    width: "92.61mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "9.05mm",
                    width: "55.92mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className='hrt cs131'>2019년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.92mm",
                    top: "9.05mm",
                    width: "92.61mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "13.57mm",
                    width: "55.92mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className='hrt cs131'>2020년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.92mm",
                    top: "13.57mm",
                    width: "92.61mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "18.09mm",
                    width: "55.92mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className='hrt cs131'>2021년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.92mm",
                    top: "18.09mm",
                    width: "92.61mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "22.61mm",
                    width: "55.92mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className='hrt cs131'>2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.92mm",
                    top: "22.61mm",
                    width: "92.61mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps9'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "5.28mm",
                top: "79.67mm",
                height: "3mm",
                width: "144.72mm",
              }}
            >
              <span className='hrt cs13'>
                ※ 비용절감액 : 개발된 기술을 이용하여 서비스 공정의 효율성
                개선을 유도함으로써 직접적으로 유발된 비용절감액(추정)&nbsp;
              </span>
            </div>
            <div
              className='hls ps9'
              style={{
                paddingLeft: "3.53mm",
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "5.28mm",
                top: "83.57mm",
                height: "3mm",
                width: "144.72mm",
              }}
            >
              <span className='hrt cs13'>
                또는 타 사(국내 및 외국)로부터 비싸게 구입 및 수입해 오던
                기술이나 서비스를 본 기술과제를 통해 개발된 기술로 대체함으
              </span>
            </div>
            <div
              className='hls ps9'
              style={{
                paddingLeft: "3.53mm",
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "5.28mm",
                top: "87.47mm",
                height: "3mm",
                width: "144.72mm",
              }}
            >
              <span className='hrt cs13'>
                로써 간접적으로 유발된 비용절감액(추정)&nbsp;
              </span>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "91.37mm",
                height: "3mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "95.23mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                3. 본 융자사업과제를 수행하거나 사업화하는 과정에서
              </span>
              <span className='hrt cs19'>&nbsp;2022년&nbsp;</span>
              <span className='hrt cs9'>신규 고용한 인력</span>
              <span className='hrt cs1'>을 연</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "101.84mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                구개발(R&amp;D)과 연구개발 외(비R&amp;D)로 나누어 기입해
                주십시오.
              </span>
            </div>
            <div
              className='hls ps36'
              style={{
                lineHeight: "20.09mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "108.63mm",
                height: "20.09mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.50mm",
                  width: "148.76mm",
                  top: "1mm",
                  height: "20.09mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.76 25.09'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "153.76mm",
                    height: "25.09mm",
                  }}
                >
                  <path
                    fill='url(#w_110)'
                    d='M0,0L23.10,0L23.10,13.57L0,13.57L0,0Z '
                  />
                  <path
                    fill='url(#w_110)'
                    d='M23.10,0L48.91,0L48.91,13.57L23.10,13.57L23.10,0Z '
                  />
                  <path
                    fill='url(#w_110)'
                    d='M48.91,0L147.76,0L147.76,4.52L48.91,4.52L48.91,0Z '
                  />
                  <path
                    fill='url(#w_110)'
                    d='M48.91,4.52L98.34,4.52L98.34,9.05L48.91,9.05L48.91,4.52Z '
                  />
                  <path
                    fill='url(#w_111)'
                    d='M98.34,4.52L147.76,4.52L147.76,9.05L98.34,9.05L98.34,4.52Z '
                  />
                  <path
                    fill='url(#w_110)'
                    d='M48.91,9.05L73.62,9.05L73.62,13.57L48.91,13.57L48.91,9.05Z '
                  />
                  <path
                    fill='url(#w_110)'
                    d='M73.62,9.05L98.34,9.05L98.34,13.57L73.62,13.57L73.62,9.05Z '
                  />
                  <path
                    fill='url(#w_111)'
                    d='M98.34,9.05L123.05,9.05L123.05,13.57L98.34,13.57L98.34,9.05Z '
                  />
                  <path
                    fill='url(#w_111)'
                    d='M123.05,9.05L147.76,9.05L147.76,13.57L123.05,13.57L123.05,9.05Z '
                  />
                  <path
                    d='M0,0 L0,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M23.10,0 L23.10,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.10,13.57 L23.10,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M48.91,4.52 L48.91,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M73.62,9.05 L73.62,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M73.62,13.57 L73.62,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M98.34,4.52 L98.34,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M98.34,13.57 L98.34,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M123.05,9.05 L123.05,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M147.76,0 L147.76,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,0 L148.01,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M48.86,4.52 L148.01,4.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M48.84,9.05 L148.01,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.25,13.57 L48.98,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.03,13.57 L48.99,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M48.84,13.57 L73.69,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M48.84,13.57 L73.70,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M73.57,13.57 L98.40,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M73.55,13.57 L98.41,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M98.28,13.57 L123.13,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M98.26,13.57 L148.01,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,18.09 L148.01,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M147.76,0 L147.76,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M0,0 L0,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,18.09 L148.01,18.09'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,0 L148.01,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "23.10mm",
                    height: "13.57mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "4.52mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.10mm",
                        }}
                      >
                        <span className='hrt cs129'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.10mm",
                    top: "0mm",
                    width: "25.81mm",
                    height: "13.57mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.99mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.81mm",
                        }}
                      >
                        <span className='hrt cs129'>신규고용인력&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.35mm",
                          height: "3.53mm",
                          width: "24.81mm",
                        }}
                      >
                        <span className='hrt cs129'>합계</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "6.88mm",
                          height: "3.53mm",
                          width: "24.81mm",
                        }}
                      >
                        <span className='hrt cs129'>(A=B+C)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "48.91mm",
                    top: "0mm",
                    width: "98.85mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "97.85mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "48.91mm",
                    top: "4.52mm",
                    width: "49.42mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "48.43mm",
                        }}
                      >
                        <span className='hrt cs129'>
                          연구개발(R&amp;D)인력(B)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "98.34mm",
                    top: "4.52mm",
                    width: "49.42mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "48.43mm",
                        }}
                      >
                        <span className='hrt cs40'>
                          연구개발 외(비R&amp;D)인력
                        </span>
                        <span className='hrt cs129'>(C)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "48.91mm",
                    top: "9.05mm",
                    width: "24.71mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className='hrt cs129'>정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "73.62mm",
                    top: "9.05mm",
                    width: "24.71mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className='hrt cs129'>비정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "98.34mm",
                    top: "9.05mm",
                    width: "24.71mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className='hrt cs40'>정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "123.05mm",
                    top: "9.05mm",
                    width: "24.71mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className='hrt cs40'>비정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "13.57mm",
                    width: "23.10mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.10mm",
                        }}
                      >
                        <span className='hrt cs131'>2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.10mm",
                    top: "13.57mm",
                    width: "25.81mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.81mm",
                        }}
                      >
                        <span className='hrt cs133'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "48.91mm",
                    top: "13.57mm",
                    width: "24.71mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className='hrt cs133'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "73.62mm",
                    top: "13.57mm",
                    width: "24.71mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className='hrt cs133'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "98.34mm",
                    top: "13.57mm",
                    width: "24.71mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className='hrt cs133'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "123.05mm",
                    top: "13.57mm",
                    width: "24.71mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className='hrt cs133'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps86'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "131.29mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                ※ 본 설문응답 인력은 ‘4대 보험 적용자’를 대상으로 함.
              </span>
              <span className='hrt cs13'>&nbsp;</span>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "136.08mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs13'>
                ※ 년중 비정규직 근로자가 정규직으로 전환되었을 경우, 정규직으로
                기입
              </span>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "139.99mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs13'>
                ※ 비정규직 근로자는 파견근로자, 용역근로자, 일일근로자,
                단시간근로자, 기간제근로자 등을 의미
              </span>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "143.89mm",
                height: "3mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "147.75mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs10'>
                4. 본 융자사업과제 선정 직전년도부터&nbsp;
              </span>
              <span className='hrt cs19'>2022</span>
              <span className='hrt cs19'>년까지 종업원 수</span>
              <span className='hrt cs10'>를 기입해 주십시오.</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "17.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "154.15mm",
                height: "17.10mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.50mm",
                  width: "149.01mm",
                  top: "1mm",
                  height: "17.10mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 154.01 22.10'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "154.01mm",
                    height: "22.10mm",
                  }}
                >
                  <path
                    d='M0,0 L0,15.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,15.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,15.11 L148.07,15.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,15.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M0,0 L0,15.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,15.11 L148.07,15.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.01mm",
                    height: "15.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.80mm", top: "3mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps121'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※예: 2017년 선정기업은 2016년부터 기입, 2019년
                          선정기업은 2018년부터 기입
                        </span>
                      </div>
                      <div
                        className='hls ps121'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "5.12mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※종업원이 없는 경우에는 0을 기입
                        </span>
                      </div>
                      <div
                        className='hls ps120'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "10.41mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          {/* ※2022년 선정기업은 2022년만 기입 */}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps36'
              style={{
                lineHeight: "15.56mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "173.98mm",
                height: "15.56mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.50mm",
                  width: "148.77mm",
                  top: "1mm",
                  height: "15.56mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.77 20.56'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "153.77mm",
                    height: "20.56mm",
                  }}
                >
                  <path
                    fill='url(#w_110)'
                    d='M0,0L18.47,0L18.47,4.52L0,4.52L0,0Z '
                  />
                  <path
                    fill='url(#w_110)'
                    d='M18.47,0L36.94,0L36.94,4.52L18.47,4.52L18.47,0Z '
                  />
                  <path
                    fill='url(#w_110)'
                    d='M36.94,0L55.41,0L55.41,4.52L36.94,4.52L36.94,0Z '
                  />
                  <path
                    fill='url(#w_110)'
                    d='M55.41,0L73.89,0L73.89,4.52L55.41,4.52L55.41,0Z '
                  />
                  <path
                    fill='url(#w_110)'
                    d='M73.89,0L92.36,0L92.36,4.52L73.89,4.52L73.89,0Z '
                  />
                  <path
                    fill='url(#w_110)'
                    d='M92.36,0L110.83,0L110.83,4.52L92.36,4.52L92.36,0Z '
                  />
                  <path
                    fill='url(#w_110)'
                    d='M110.83,0L129.30,0L129.30,4.52L110.83,4.52L110.83,0Z '
                  />
                  <path
                    fill='url(#w_110)'
                    d='M129.30,0L147.77,0L147.77,4.52L129.30,4.52L129.30,0Z '
                  />
                  <path
                    d='M0,0 L0,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M18.47,0 L18.47,4.53'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M18.47,0 L18.47,4.53'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M18.47,4.52 L18.47,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M18.47,4.52 L18.47,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M18.47,9.05 L18.47,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M18.47,9.05 L18.47,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M36.94,0 L36.94,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M55.41,0 L55.41,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M73.89,0 L73.89,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M92.36,0 L92.36,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M110.83,0 L110.83,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M129.30,0 L129.30,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M147.77,0 L147.77,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,0 L148.03,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,4.52 L18.53,4.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M18.40,4.52 L148.03,4.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,9.05 L18.53,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M18.40,9.05 L148.03,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,13.57 L148.03,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M147.77,0 L147.77,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M0,0 L0,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,13.57 L148.03,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,0 L148.03,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "18.47mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>2016년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "36.94mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>2017년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.41mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>2018년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "73.89mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>2019년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.36mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>2020년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.83mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs24'>2021년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "129.30mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs24'>2022</span>
                        <span className='hrt cs24'>년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "4.52mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs22'>정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "18.47mm",
                    top: "4.52mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "36.94mm",
                    top: "4.52mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.41mm",
                    top: "4.52mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "73.89mm",
                    top: "4.52mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.36mm",
                    top: "4.52mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.83mm",
                    top: "4.52mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "129.30mm",
                    top: "4.52mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "9.05mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs22'>비정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "18.47mm",
                    top: "9.05mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "36.94mm",
                    top: "9.05mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.41mm",
                    top: "9.05mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "73.89mm",
                    top: "9.05mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.36mm",
                    top: "9.05mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.83mm",
                    top: "9.05mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "129.30mm",
                    top: "9.05mm",
                    width: "18.47mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs23'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;)명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps86'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "192.11mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs20'>
                ※ 본 설문응답 인력은 ‘4대 보험 적용자’를 대상으로 함.
              </span>
              <span className='hrt cs21'>&nbsp;</span>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "196.90mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs21'>
                ※ 년 중 비정규직 근로자가 정규직으로 전환되었을 경우, 정규직으로
                기입
              </span>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "200.80mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs21'>
                ※ 비정규직 근로자는 파견근로자, 용역근로자, 일일근로자,
                단시간근로자, 기간제근로자 등을 의미
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "204.71mm",
                height: "3mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps110'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "209.75mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>5</span>
              <span className='hrt cs1'>. 본&nbsp;</span>
              <span className='hrt cs152'>
                융자사업과제로 개발된 서비스로 인하여&nbsp;
              </span>
              <span className='hrt cs9'>2022년 외부로부터 투자유치한 실적</span>
              <span className='hrt cs1'>을&nbsp;</span>
            </div>
            <div
              className='hls ps110'
              style={{
                paddingLeft: "4.99mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "214.80mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>기재해 주시기 바랍니다.</span>
            </div>
            <div
              className='hls ps113'
              style={{
                lineHeight: "11.04mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "220.05mm",
                height: "11.04mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "148.53mm",
                  top: "1mm",
                  height: "11.04mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.53 16.04'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "153.53mm",
                    height: "16.04mm",
                  }}
                >
                  <path
                    fill='url(#w_110)'
                    d='M0,0L57.91,0L57.91,4.52L0,4.52L0,0Z '
                  />
                  <path
                    fill='url(#w_111)'
                    d='M57.91,0L146.53,0L146.53,4.52L57.91,4.52L57.91,0Z '
                  />
                  <path
                    d='M0,0 L0,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M57.91,0 L57.91,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M57.91,4.52 L57.91,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M146.53,0 L146.53,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,0 L146.78,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,4.52 L146.78,4.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M57.84,4.52 L146.78,4.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,9.05 L146.78,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M146.53,0 L146.53,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M0,0 L0,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,9.05 L146.78,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,0 L146.78,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "57.91mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "56.91mm",
                        }}
                      >
                        <span className='hrt cs132'>연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "57.91mm",
                    top: "0mm",
                    width: "88.62mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "87.62mm",
                        }}
                      >
                        <span className='hrt cs132'>투자유치액(백만원)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "4.52mm",
                    width: "57.91mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "56.91mm",
                        }}
                      >
                        <span className='hrt cs131'>2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "57.91mm",
                    top: "4.52mm",
                    width: "88.62mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "87.62mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwelvePageBlock;
