import Layout from "./layouts/Layout";
import "./App.css";
import LoadingComponent from "./layouts/components/Loading";
import { useState } from "react";
import { useEffect } from "react";
import { axiosApi } from "./helpers/apis/api_helper";
import { message } from "antd";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { useDispatch } from "react-redux";
import { setMessageApi } from "./store/actions/user";
import { setLoading } from "src/store/actions/user";

function App() {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  const { apimessage, isLoading } = useSelector((state: RootState) => {
    return {
      apimessage: state.user.messageApi,
      isLoading: state.user.isLoading,
    };
  });

  if (!apimessage) {
    dispatch(setMessageApi(messageApi));
  }

  useEffect(() => {
    axiosApi.interceptors.request.use(
      (config: any) => {
        // 요청이 전달되기 전에 작업 수행
        dispatch(setLoading(true));
        return config;
      },
      (error) => {
        // 요청 오류가 있는 작업 수행
        dispatch(setLoading(false));

        return Promise.reject(error);
      }
    );

    // 응답 인터셉터 추가하기
    axiosApi.interceptors.response.use(
      (response) => {
        dispatch(setLoading(false));
        return response;
      },
      async (err) => {
        dispatch(setLoading(false));
        return Promise.reject(err);
      }
    );
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App" style={{ margin: "50px" }}>
      {contextHolder}
      {isLoading && <LoadingComponent />}
      <Layout />
    </div>
  );
}

export default App;
