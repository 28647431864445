import { Input } from "antd";
import React from "react";
import { changeNumber } from "src/ts/operator";
import { TwelvePageTypes } from "gita";

import _ from "lodash";

interface twelvePageComponentProps {
  twelvePageData: TwelvePageTypes;
  setTwelvePageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  targetYear: number;
}

const TwelvePageComponent = ({
  twelvePageData,
  setTwelvePageData,
  setisUpdate,
  targetYear,
}: twelvePageComponentProps) => {
  return (
    <div>
      <div className="hpa" style={{ width: "210mm", height: "347mm" }}>
        <div
          className="hpN"
          style={{
            left: "98.24mm",
            top: "326.33mm",
            width: "13.52mm",
            height: "35mm",
          }}
        >
          <span className="hrt cs0">- 12 -</span>
        </div>
        <div className="hcD" style={{ left: "30mm", top: "35mm" }}>
          <div className="hcI">
            <div
              className="hls ps5"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.19mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">
                2. 본 융자사업과제를 통해 개발된 기술을 사업화하여 발생한 매출액
                이외에&nbsp;
              </span>
            </div>
            <div
              className="hls ps5"
              style={{
                paddingLeft: "7.06mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "6.41mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs9">
                귀사가 개발한 기술로 인해 비용절감효과
              </span>
              <span className="hrt cs1">
                가 있었습니까? ( &nbsp;&nbsp;&nbsp;&nbsp;)
              </span>
            </div>
            <div
              className="hls ps68"
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "13.02mm",
                height: "3.7mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs86">
                <label htmlFor="page12_2_yes">
                  <input
                    onChange={() => {
                      setisUpdate(true);
                      setTwelvePageData({
                        ...twelvePageData,
                        two: "1",
                      });
                    }}
                    checked={twelvePageData.two === "1"}
                    type="radio"
                    name="page12_2_yesorno"
                    id="page12_2_yes"
                  />
                  &nbsp;예
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span
                className="htC"
                style={{ left: "0.92mm", width: "11.81mm", height: "100%" }}
              />
              <span className="hrt cs86">
                <label htmlFor="page12_2_no">
                  <input
                    onChange={() => {
                      setisUpdate(true);
                      setTwelvePageData({
                        ...twelvePageData,
                        two: "2",
                      });
                    }}
                    checked={twelvePageData.two === "2"}
                    type="radio"
                    name="page12_2_yesorno"
                    id="page12_2_no"
                  />
                  &nbsp;아니오
                </label>
              </span>
            </div>
            <div
              className="hls ps68"
              style={{
                lineHeight: "1.59mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "17.92mm",
                height: "2.12mm",
                width: "150mm",
              }}
            />
            <div
              className="hls ps5"
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "20.59mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className="hrt cs8">
                2-1. 위 항목에서 ‘예’라고 응답한 경우, 비용절감액을 기입해
                주시기 바랍니다.
              </span>
            </div>
            <div
              className="hls ps0"
              style={{
                lineHeight: "18.18mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "27.07mm",
                height: "18.18mm",
                width: "150mm",
              }}
            >
              <div
                className="htb"
                style={{
                  left: "1mm",
                  width: "150.01mm",
                  top: "1mm",
                  height: "18.18mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 155.01 23.17"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "155.01mm",
                    height: "23.17mm",
                  }}
                >
                  <path
                    d="M0,0 L0,16.18"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M148.01,0 L148.01,16.18"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,0 L148.07,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,16.18 L148.07,16.18"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M148.01,0 L148.01,16.18"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M0,0 L0,16.18"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,16.18 L148.07,16.18"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,0 L148.07,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.01mm",
                    height: "16.18mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps121"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.18mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className="hrt cs209">
                          ※예: 2018년 선정기업은 2019년부터 기입, 2020년
                          선정기업은 2021년부터 기입
                        </span>
                      </div>
                      <div
                        className="hls ps121"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "8.65mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className="hrt cs209">
                          ※비용절감액이 없는 경우에는 0을 기입
                        </span>
                      </div>
                      {/* <div
                        className="hls ps121"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "11.48mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className="hrt cs209">
                          ※2023년 선정기업은 2023년만 기입
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hls ps36"
              style={{
                lineHeight: "44.11mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "47.96mm",
                height: "44.11mm",
                width: "150mm",
              }}
            >
              <div
                className="htb"
                style={{
                  left: "1mm",
                  width: "150.52mm",
                  top: "1mm",
                  height: "44.11mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 155.52 49.11"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "155.52mm",
                    height: "49.11mm",
                  }}
                >
                  <defs>
                    <pattern
                      id="w_110"
                      width={10}
                      height={10}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect width={10} height={10} fill="rgb(229,229,229)" />
                    </pattern>
                  </defs>
                  <path
                    fill="url(#w_110)"
                    d="M0,0L55.92,0L55.92,7.02L0,7.02L0,0Z "
                  />
                  <defs>
                    <pattern
                      id="w_111"
                      width={10}
                      height={10}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect width={10} height={10} fill="rgb(229,229,229)" />
                    </pattern>
                  </defs>
                  <path
                    fill="url(#w_111)"
                    d="M55.92,0L148.53,0L148.53,7.02L55.92,7.02L55.92,0Z "
                  />
                  <path
                    d="M0,0 L0,42.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M55.92,0 L55.92,14.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.92,7.02 L55.92,14.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M55.92,14.04 L55.92,21.06"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.92,14.04 L55.92,21.06"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M55.92,21.06 L55.92,28.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.92,21.06 L55.92,28.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M55.92,28.08 L55.92,35.10"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.92,28.08 L55.92,35.10"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M55.92,35.10 L55.92,42.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.92,35.10 L55.92,42.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M148.53,0 L148.53,42.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L148.78,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,7.02 L148.78,7.02"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.84,7.02 L148.78,7.02"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,14.04 L55.98,14.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.84,14.04 L148.78,14.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,21.06 L55.98,21.06"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.84,21.06 L148.78,21.06"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,28.08 L55.98,28.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.84,28.08 L148.78,28.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,35.10 L55.98,35.10"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M55.84,35.10 L148.78,35.10"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,42.11 L148.78,42.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M148.53,0 L148.53,42.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M0,0 L0,42.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,42.11 L148.78,42.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L148.78,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "55.92mm",
                    height: "7.02mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.25mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className="hrt cs129">연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.92mm",
                    top: "0mm",
                    width: "92.61mm",
                    height: "7.02mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.25mm" }}>
                      <div
                        className="hls ps22"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      >
                        <span className="hrt cs129">
                          &nbsp;비용절감액(백만원)
                        </span>
                        <span className="hrt cs130">1)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "7.02mm",
                    width: "55.92mm",
                    height: "7.02mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.25mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className="hrt cs131">2019년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.92mm",
                    top: "7.02mm",
                    width: "92.61mm",
                    height: "7.02mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.5mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      >
                        <Input
                          disabled={
                            twelvePageData.two !== "1" || targetYear > 2018
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setTwelvePageData({
                              ...twelvePageData,
                              twoOne: {
                                ...twelvePageData.twoOne,
                                one:
                                  e.target.value === ""
                                    ? false
                                    : e.target.value,
                              },
                            });
                          }}
                          value={
                            typeof twelvePageData.twoOne.one === "string"
                              ? twelvePageData.twoOne.one
                              : ""
                          }
                          className="twelve-input-number"
                          style={{ width: "40%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "14.04mm",
                    width: "55.92mm",
                    height: "7.02mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.25mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className="hrt cs131">2020년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.92mm",
                    top: "14.04mm",
                    width: "92.61mm",
                    height: "7.02mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.6mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      >
                        <Input
                          disabled={
                            twelvePageData.two !== "1" || targetYear > 2019
                          }
                          type="number"
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setTwelvePageData({
                              ...twelvePageData,
                              twoOne: {
                                ...twelvePageData.twoOne,
                                two:
                                  e.target.value === ""
                                    ? false
                                    : e.target.value,
                              },
                            });
                          }}
                          value={
                            typeof twelvePageData.twoOne.two === "string"
                              ? twelvePageData.twoOne.two
                              : ""
                          }
                          className="twelve-input-number"
                          style={{ width: "40%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "21.06mm",
                    width: "55.92mm",
                    height: "7.02mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.25mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className="hrt cs131">2021년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.92mm",
                    top: "21.06mm",
                    width: "92.61mm",
                    height: "7.02mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.6mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      >
                        <Input
                          disabled={
                            twelvePageData.two !== "1" || targetYear > 2020
                          }
                          type="number"
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setTwelvePageData({
                              ...twelvePageData,
                              twoOne: {
                                ...twelvePageData.twoOne,
                                three:
                                  e.target.value === ""
                                    ? false
                                    : e.target.value,
                              },
                            });
                          }}
                          value={
                            typeof twelvePageData.twoOne.three === "string"
                              ? twelvePageData.twoOne.three
                              : ""
                          }
                          className="twelve-input-number"
                          style={{ width: "40%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "28.08mm",
                    width: "55.92mm",
                    height: "7.02mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.25mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className="hrt cs131">2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.92mm",
                    top: "28.08mm",
                    width: "92.61mm",
                    height: "7.02mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.6mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      >
                        <Input
                          disabled={
                            twelvePageData.two !== "1" || targetYear > 2021
                          }
                          type="number"
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setTwelvePageData({
                              ...twelvePageData,
                              twoOne: {
                                ...twelvePageData.twoOne,
                                four:
                                  e.target.value === ""
                                    ? false
                                    : e.target.value,
                              },
                            });
                          }}
                          value={
                            typeof twelvePageData.twoOne.four === "string"
                              ? twelvePageData.twoOne.four
                              : ""
                          }
                          className="twelve-input-number"
                          style={{ width: "40%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "35.1mm",
                    width: "55.92mm",
                    height: "7.01mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "1.24mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "54.92mm",
                        }}
                      >
                        <span className="hrt cs131">2023년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.92mm",
                    top: "35.1mm",
                    width: "92.61mm",
                    height: "7.01mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.6mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "91.61mm",
                        }}
                      >
                        <Input
                          disabled={twelvePageData.two !== "1"}
                          type="number"
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setTwelvePageData({
                              ...twelvePageData,
                              twoOne: {
                                ...twelvePageData.twoOne,
                                five:
                                  e.target.value === ""
                                    ? false
                                    : e.target.value,
                              },
                            });
                          }}
                          value={
                            typeof twelvePageData.twoOne.five === "string"
                              ? twelvePageData.twoOne.five
                              : ""
                          }
                          className="twelve-input-number"
                          style={{ width: "40%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hls ps9"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "5.28mm",
                top: "94.64mm",
                height: "3mm",
                width: "144.72mm",
              }}
            >
              <span className="hrt cs13">
                ※ 비용절감액 : 개발된 기술을 이용하여 서비스 공정의 효율성
                개선을 유도함으로써 직접적으로 유발된 비용절감액(추정)&nbsp;
              </span>
            </div>
            <div
              className="hls ps9"
              style={{
                paddingLeft: "3.53mm",
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "5.28mm",
                top: "98.54mm",
                height: "3mm",
                width: "144.72mm",
              }}
            >
              <span className="hrt cs13">
                또는 타 사(국내 및 외국)로부터 비싸게 구입 및 수입해 오던
                기술이나 서비스를 본 기술과제를 통해 개발된 기술로 대체함으
              </span>
            </div>
            <div
              className="hls ps9"
              style={{
                paddingLeft: "3.53mm",
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "5.28mm",
                top: "102.45mm",
                height: "3mm",
                width: "144.72mm",
              }}
            >
              <span className="hrt cs13">
                로써 간접적으로 유발된 비용절감액(추정)&nbsp;
              </span>
            </div>
            <div
              className="hls ps8"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "106.35mm",
                height: "3mm",
                width: "150mm",
              }}
            />
            <div
              className="hls ps0"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "110.21mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">
                3. 본 융자사업과제를 수행하거나 사업화하는 과정에서
              </span>
              <span className="hrt cs19">&nbsp;2023년&nbsp;</span>
              <span className="hrt cs9">신규 고용한 인력</span>
              <span className="hrt cs1">을 연</span>
            </div>
            <div
              className="hls ps0"
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "116.81mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">
                구개발(R&amp;D)과 연구개발 외(비R&amp;D)로 나누어 기입해
                주십시오.
              </span>
            </div>
            <div
              className="hls ps36"
              style={{
                lineHeight: "29.07mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "123.61mm",
                height: "29.07mm",
                width: "150mm",
              }}
            >
              <div
                className="htb"
                style={{
                  left: "0.5mm",
                  width: "148.76mm",
                  top: "1mm",
                  height: "29.07mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 153.76 34.07"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.76mm",
                    height: "34.07mm",
                  }}
                >
                  <path
                    fill="url(#w_110)"
                    d="M0,0L23.10,0L23.10,15.82L0,15.82L0,0Z "
                  />
                  <path
                    fill="url(#w_110)"
                    d="M23.10,0L48.91,0L48.91,15.82L23.10,15.82L23.10,0Z "
                  />
                  <path
                    fill="url(#w_110)"
                    d="M48.91,0L147.76,0L147.76,5.20L48.91,5.20L48.91,0Z "
                  />
                  <path
                    fill="url(#w_110)"
                    d="M48.91,5.20L98.34,5.20L98.34,10.47L48.91,10.47L48.91,5.20Z "
                  />
                  <path
                    fill="url(#w_111)"
                    d="M98.34,5.20L147.76,5.20L147.76,10.47L98.34,10.47L98.34,5.20Z "
                  />
                  <path
                    fill="url(#w_110)"
                    d="M48.91,10.47L73.62,10.47L73.62,15.82L48.91,15.82L48.91,10.47Z "
                  />
                  <path
                    fill="url(#w_110)"
                    d="M73.62,10.47L98.34,10.47L98.34,15.82L73.62,15.82L73.62,10.47Z "
                  />
                  <path
                    fill="url(#w_111)"
                    d="M98.34,10.47L123.05,10.47L123.05,15.82L98.34,15.82L98.34,10.47Z "
                  />
                  <path
                    fill="url(#w_111)"
                    d="M123.05,10.47L147.76,10.47L147.76,15.82L123.05,15.82L123.05,10.47Z "
                  />
                  <path
                    d="M0,0 L0,27.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M23.10,0 L23.10,27.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M23.10,15.82 L23.10,27.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M48.91,5.20 L48.91,27.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M73.62,10.47 L73.62,15.82"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M73.62,15.82 L73.62,27.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M98.34,5.20 L98.34,15.82"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M98.34,15.82 L98.34,27.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M123.05,10.47 L123.05,27.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M147.76,0 L147.76,27.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L148.01,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M48.86,5.20 L148.01,5.20"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M48.84,10.47 L148.01,10.47"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.25,15.82 L48.98,15.82"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M23.03,15.82 L48.99,15.82"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M48.84,15.82 L73.69,15.82"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M48.84,15.82 L73.70,15.82"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M73.57,15.82 L98.40,15.82"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M73.55,15.82 L98.41,15.82"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M98.28,15.82 L123.13,15.82"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M98.26,15.82 L148.01,15.82"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,27.08 L148.01,27.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M147.76,0 L147.76,27.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M0,0 L0,27.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,27.08 L148.01,27.08"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L148.01,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "23.1mm",
                    height: "15.82mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "5.64mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.1mm",
                        }}
                      >
                        <span className="hrt cs129">구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "23.1mm",
                    top: "0mm",
                    width: "25.81mm",
                    height: "15.82mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.12mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.81mm",
                        }}
                      >
                        <span className="hrt cs129">신규고용인력&nbsp;</span>
                      </div>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.35mm",
                          height: "3.53mm",
                          width: "24.81mm",
                        }}
                      >
                        <span className="hrt cs129">합계</span>
                      </div>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "6.88mm",
                          height: "3.53mm",
                          width: "24.81mm",
                        }}
                      >
                        <span className="hrt cs129">(A=B+C)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "48.91mm",
                    top: "0mm",
                    width: "98.85mm",
                    height: "5.2mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.34mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "97.85mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "48.91mm",
                    top: "5.2mm",
                    width: "49.42mm",
                    height: "5.27mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.37mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "48.43mm",
                        }}
                      >
                        <span className="hrt cs129">
                          연구개발(R&amp;D)인력(B)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "98.34mm",
                    top: "5.2mm",
                    width: "49.42mm",
                    height: "5.27mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.37mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "48.43mm",
                        }}
                      >
                        <span className="hrt cs40">
                          연구개발 외(비R&amp;D)인력
                        </span>
                        <span className="hrt cs129">(C)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "48.91mm",
                    top: "10.47mm",
                    width: "24.71mm",
                    height: "5.34mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.41mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs129">정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "73.62mm",
                    top: "10.47mm",
                    width: "24.71mm",
                    height: "5.34mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.41mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs129">비정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "98.34mm",
                    top: "10.47mm",
                    width: "24.71mm",
                    height: "5.34mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.41mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs40">정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "123.05mm",
                    top: "10.47mm",
                    width: "24.71mm",
                    height: "5.34mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.41mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs40">비정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "15.82mm",
                    width: "23.1mm",
                    height: "11.26mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "3.37mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.1mm",
                        }}
                      >
                        <span className="hrt cs131">2023년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "23.1mm",
                    top: "15.82mm",
                    width: "25.81mm",
                    height: "11.26mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "3.37mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.81mm",
                        }}
                      >
                        <span className="hrt cs133">
                          <Input
                            value={
                              typeof twelvePageData.three.one === "number"
                                ? twelvePageData.three.one
                                : ""
                            }
                            disabled={true}
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "48.91mm",
                    top: "15.82mm",
                    width: "24.71mm",
                    height: "11.26mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "3.37mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs133">
                          <Input
                            onChange={(e) => {
                              const data =
                                changeNumber(e.target.value) === ""
                                  ? false
                                  : changeNumber(e.target.value);

                              const { three, four, five } =
                                twelvePageData.three;

                              const newData =
                                Number(data) +
                                Number(three) +
                                Number(four) +
                                Number(five);

                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                three: {
                                  ...twelvePageData.three,
                                  one: newData,
                                  two: data,
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.three.two === "number"
                                ? twelvePageData.three.two
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "73.62mm",
                    top: "15.82mm",
                    width: "24.71mm",
                    height: "11.26mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "3.37mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs133">
                          <Input
                            onChange={(e) => {
                              const data =
                                changeNumber(e.target.value) === ""
                                  ? false
                                  : changeNumber(e.target.value);

                              const { two, four, five } = twelvePageData.three;

                              const newData =
                                Number(data) +
                                Number(two) +
                                Number(four) +
                                Number(five);

                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                three: {
                                  ...twelvePageData.three,
                                  one: newData,
                                  three: data,
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.three.three === "number"
                                ? twelvePageData.three.three
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "98.34mm",
                    top: "15.82mm",
                    width: "24.71mm",
                    height: "11.26mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "3.37mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs133">
                          <Input
                            onChange={(e) => {
                              const data =
                                changeNumber(e.target.value) === ""
                                  ? false
                                  : changeNumber(e.target.value);

                              const { three, two, five } = twelvePageData.three;

                              const newData =
                                Number(data) +
                                Number(three) +
                                Number(two) +
                                Number(five);

                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                three: {
                                  ...twelvePageData.three,
                                  one: newData,
                                  four: data,
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.three.four === "number"
                                ? twelvePageData.three.four
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "123.05mm",
                    top: "15.82mm",
                    width: "24.71mm",
                    height: "11.26mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "3.37mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.71mm",
                        }}
                      >
                        <span className="hrt cs133">
                          <Input
                            onChange={(e) => {
                              const data =
                                changeNumber(e.target.value) === ""
                                  ? false
                                  : changeNumber(e.target.value);

                              const { three, four, two } = twelvePageData.three;

                              const newData =
                                Number(data) +
                                Number(three) +
                                Number(four) +
                                Number(two);

                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                three: {
                                  ...twelvePageData.three,
                                  one: newData,
                                  five: data,
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.three.five === "number"
                                ? twelvePageData.three.five
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hls ps86"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "155.25mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs5">
                ※ 본 설문응답 인력은 ‘4대 보험 적용자’를 대상으로 함.
              </span>
              <span className="hrt cs13">&nbsp;</span>
            </div>
            <div
              className="hls ps8"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "160.04mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs13">
                ※ 년중 비정규직 근로자가 정규직으로 전환되었을 경우, 정규직으로
                기입
              </span>
            </div>
            <div
              className="hls ps8"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "163.95mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs13">
                ※ 비정규직 근로자는 파견근로자, 용역근로자, 일일근로자,
                단시간근로자, 기간제근로자 등을 의미
              </span>
            </div>
            <div
              className="hls ps8"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "167.85mm",
                height: "3mm",
                width: "150mm",
              }}
            />
            <div
              className="hls ps0"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "171.71mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs10">
                4. 본 융자사업과제 선정 직전년도부터&nbsp;
              </span>
              <span className="hrt cs19">2023</span>
              <span className="hrt cs19">년까지 종업원 수</span>
              <span className="hrt cs10">를 기입해 주십시오.</span>
            </div>
            <div
              className="hls ps0"
              style={{
                lineHeight: "17.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "178.11mm",
                height: "17.1mm",
                width: "150mm",
              }}
            >
              <div
                className="htb"
                style={{
                  left: "0.5mm",
                  width: "149.01mm",
                  top: "1mm",
                  height: "17.1mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 154.01 22.10"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "154.01mm",
                    height: "22.1mm",
                  }}
                >
                  <path
                    d="M0,0 L0,15.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M148.01,0 L148.01,15.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,0 L148.07,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,15.11 L148.07,15.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M148.01,0 L148.01,15.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M0,0 L0,15.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,15.11 L148.07,15.11"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M-0.06,0 L148.07,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.01mm",
                    height: "15.11mm",
                  }}
                >
                  <div className="hcD" style={{ left: "1.8mm", top: "3mm" }}>
                    <div className="hcI">
                      <div
                        className="hls ps120"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className="hrt cs209">
                          ※예: 2018년 선정기업은 2017년부터 기입, 2020년
                          선정기업은 2019년부터 기입
                        </span>
                      </div>
                      <div
                        className="hls ps120"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "5.12mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className="hrt cs209">
                          ※종업원이 없는 경우에는 0을 기입
                        </span>
                      </div>
                      <div
                        className="hls ps119"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "10.41mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className="hrt cs209">
                          {/* ※2022년 선정기업은 2022년만 기입 */}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hls ps36"
              style={{
                lineHeight: "26.55mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "197.94mm",
                height: "26.55mm",
                width: "150mm",
              }}
            >
              <div
                className="htb"
                style={{
                  left: "0.5mm",
                  width: "148.77mm",
                  top: "1mm",
                  height: "26.55mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 153.77 31.55"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.77mm",
                    height: "31.55mm",
                  }}
                >
                  <path
                    fill="url(#w_110)"
                    d="M0,0L18.47,0L18.47,6.25L0,6.25L0,0Z "
                  />
                  <path
                    fill="url(#w_110)"
                    d="M18.47,0L36.94,0L36.94,6.25L18.47,6.25L18.47,0Z "
                  />
                  <path
                    fill="url(#w_110)"
                    d="M36.94,0L55.41,0L55.41,6.25L36.94,6.25L36.94,0Z "
                  />
                  <path
                    fill="url(#w_110)"
                    d="M55.41,0L73.89,0L73.89,6.25L55.41,6.25L55.41,0Z "
                  />
                  <path
                    fill="url(#w_110)"
                    d="M73.89,0L92.36,0L92.36,6.25L73.89,6.25L73.89,0Z "
                  />
                  <path
                    fill="url(#w_110)"
                    d="M92.36,0L110.83,0L110.83,6.25L92.36,6.25L92.36,0Z "
                  />
                  <path
                    fill="url(#w_110)"
                    d="M110.83,0L129.30,0L129.30,6.25L110.83,6.25L110.83,0Z "
                  />
                  <path
                    fill="url(#w_110)"
                    d="M129.30,0L147.77,0L147.77,6.25L129.30,6.25L129.30,0Z "
                  />
                  <path
                    d="M0,0 L0,24.55"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M18.47,0 L18.47,6.26"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M18.47,0 L18.47,6.26"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M18.47,6.25 L18.47,15.91"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M18.47,6.25 L18.47,15.91"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M18.47,15.90 L18.47,24.55"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M18.47,15.90 L18.47,24.55"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M36.94,0 L36.94,24.55"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M55.41,0 L55.41,24.55"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M73.89,0 L73.89,24.55"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M92.36,0 L92.36,24.55"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M110.83,0 L110.83,24.55"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M129.30,0 L129.30,24.55"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M147.77,0 L147.77,24.55"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L148.03,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,6.25 L18.53,6.25"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M18.40,6.25 L148.03,6.25"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,15.90 L18.53,15.90"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M18.40,15.90 L148.03,15.90"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,24.55 L148.03,24.55"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M147.77,0 L147.77,24.55"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M0,0 L0,24.55"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,24.55 L148.03,24.55"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L148.03,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "6.25mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.86mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "18.47mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "6.25mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.86mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">2017년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "36.94mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "6.25mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.86mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">2018년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.41mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "6.25mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.86mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">2019년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "73.89mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "6.25mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.86mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">2020년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "92.36mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "6.25mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.86mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">2021년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "110.83mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "6.25mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.86mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs24">2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "129.3mm",
                    top: "0mm",
                    width: "18.47mm",
                    height: "6.25mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.86mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs24">2023</span>
                        <span className="hrt cs24">년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "6.25mm",
                    width: "18.47mm",
                    height: "9.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.56mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs22">정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "18.47mm",
                    top: "6.25mm",
                    width: "18.47mm",
                    height: "9.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.56mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2018}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                four: {
                                  ...twelvePageData.four,
                                  one: {
                                    ...twelvePageData.four.one,
                                    one:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.four.one.one === "number"
                                ? twelvePageData.four.one.one
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "36.94mm",
                    top: "6.25mm",
                    width: "18.47mm",
                    height: "9.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.56mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2019}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                four: {
                                  ...twelvePageData.four,
                                  two: {
                                    ...twelvePageData.four.two,
                                    one:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.four.two.one === "number"
                                ? twelvePageData.four.two.one
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.41mm",
                    top: "6.25mm",
                    width: "18.47mm",
                    height: "9.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.56mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2020}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                four: {
                                  ...twelvePageData.four,
                                  three: {
                                    ...twelvePageData.four.three,
                                    one:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.four.three.one === "number"
                                ? twelvePageData.four.three.one
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "73.89mm",
                    top: "6.25mm",
                    width: "18.47mm",
                    height: "9.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.56mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2021}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                four: {
                                  ...twelvePageData.four,
                                  four: {
                                    ...twelvePageData.four.four,
                                    one:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.four.four.one === "number"
                                ? twelvePageData.four.four.one
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "92.36mm",
                    top: "6.25mm",
                    width: "18.47mm",
                    height: "9.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.56mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2022}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                four: {
                                  ...twelvePageData.four,
                                  five: {
                                    ...twelvePageData.four.five,
                                    one:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.four.five.one === "number"
                                ? twelvePageData.four.five.one
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "110.83mm",
                    top: "6.25mm",
                    width: "18.47mm",
                    height: "9.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.56mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear >= 2023}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                four: {
                                  ...twelvePageData.four,
                                  six: {
                                    ...twelvePageData.four.six,
                                    one:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.four.six.one === "number"
                                ? twelvePageData.four.six.one
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "129.3mm",
                    top: "6.25mm",
                    width: "18.47mm",
                    height: "9.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.56mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                four: {
                                  ...twelvePageData.four,
                                  seven: {
                                    ...twelvePageData.four.seven,
                                    one:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.four.seven.one === "number"
                                ? twelvePageData.four.seven.one
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "15.9mm",
                    width: "18.47mm",
                    height: "8.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.06mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs22">비정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "18.47mm",
                    top: "15.9mm",
                    width: "18.47mm",
                    height: "8.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.06mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2018}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                four: {
                                  ...twelvePageData.four,
                                  one: {
                                    ...twelvePageData.four.one,
                                    two:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.four.one.two === "number"
                                ? twelvePageData.four.one.two
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "36.94mm",
                    top: "15.9mm",
                    width: "18.47mm",
                    height: "8.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.06mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2019}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                four: {
                                  ...twelvePageData.four,
                                  two: {
                                    ...twelvePageData.four.two,
                                    two:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.four.two.two === "number"
                                ? twelvePageData.four.two.two
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "55.41mm",
                    top: "15.9mm",
                    width: "18.47mm",
                    height: "8.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.06mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2020}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                four: {
                                  ...twelvePageData.four,
                                  three: {
                                    ...twelvePageData.four.three,
                                    two:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.four.three.two === "number"
                                ? twelvePageData.four.three.two
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "73.89mm",
                    top: "15.9mm",
                    width: "18.47mm",
                    height: "8.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.06mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2021}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                four: {
                                  ...twelvePageData.four,
                                  four: {
                                    ...twelvePageData.four.four,
                                    two:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.four.four.two === "number"
                                ? twelvePageData.four.four.two
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "92.36mm",
                    top: "15.9mm",
                    width: "18.47mm",
                    height: "8.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.06mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear > 2022}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                four: {
                                  ...twelvePageData.four,
                                  five: {
                                    ...twelvePageData.four.five,
                                    two:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.four.five.two === "number"
                                ? twelvePageData.four.five.two
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "110.83mm",
                    top: "15.9mm",
                    width: "18.47mm",
                    height: "8.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.06mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            disabled={targetYear >= 2023}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                four: {
                                  ...twelvePageData.four,
                                  six: {
                                    ...twelvePageData.four.six,
                                    two:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.four.six.two === "number"
                                ? twelvePageData.four.six.two
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "129.3mm",
                    top: "15.9mm",
                    width: "18.47mm",
                    height: "8.65mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.06mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className="hrt cs23">
                          <Input
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwelvePageData({
                                ...twelvePageData,
                                four: {
                                  ...twelvePageData.four,
                                  seven: {
                                    ...twelvePageData.four.seven,
                                    two:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twelvePageData.four.seven.two === "number"
                                ? twelvePageData.four.seven.two
                                : ""
                            }
                            className="twelve-input-number"
                            style={{ width: "60%", height: "90%" }}
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hls ps86"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "227.05mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs20">
                ※ 본 설문응답 인력은 ‘4대 보험 적용자’를 대상으로 함.
              </span>
              <span className="hrt cs21">&nbsp;</span>
            </div>
            <div
              className="hls ps8"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "231.85mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs21">
                ※ 년 중 비정규직 근로자가 정규직으로 전환되었을 경우, 정규직으로
                기입
              </span>
            </div>
            <div
              className="hls ps8"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "235.75mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs21">
                ※ 비정규직 근로자는 파견근로자, 용역근로자, 일일근로자,
                단시간근로자, 기간제근로자 등을 의미
              </span>
            </div>
            <div
              className="hls ps0"
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "239.65mm",
                height: "3mm",
                width: "150mm",
              }}
            />
            <div
              className="hls ps109"
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "244.69mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">5</span>
              <span className="hrt cs1">. 본&nbsp;</span>
              <span className="hrt cs152">
                융자사업과제로 개발된 서비스로 인하여&nbsp;
              </span>
              <span className="hrt cs9">2023년 외부로부터 투자유치한 실적</span>
              <span className="hrt cs1">을&nbsp;</span>
            </div>
            <div
              className="hls ps109"
              style={{
                paddingLeft: "4.99mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "249.75mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className="hrt cs1">기재해 주시기 바랍니다.</span>
            </div>
            <div
              className="hls ps112"
              style={{
                lineHeight: "17.03mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "254.99mm",
                height: "17.03mm",
                width: "150mm",
              }}
            >
              <div
                className="htb"
                style={{
                  left: "1mm",
                  width: "148.53mm",
                  top: "1mm",
                  height: "17.03mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className="hs"
                  viewBox="-2.50 -2.50 153.53 22.03"
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.53mm",
                    height: "22.03mm",
                  }}
                >
                  <path
                    fill="url(#w_110)"
                    d="M0,0L57.91,0L57.91,5.52L0,5.52L0,0Z "
                  />
                  <path
                    fill="url(#w_111)"
                    d="M57.91,0L146.53,0L146.53,5.52L57.91,5.52L57.91,0Z "
                  />
                  <path
                    d="M0,0 L0,15.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M57.91,0 L57.91,15.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M57.91,5.52 L57.91,15.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M146.53,0 L146.53,15.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L146.78,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,5.52 L146.78,5.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d="M57.84,5.52 L146.78,5.52"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d="M-0.25,15.04 L146.78,15.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M146.53,0 L146.53,15.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M0,0 L0,15.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,15.04 L146.78,15.04"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d="M-0.25,0 L146.78,0"
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                </svg>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "57.91mm",
                    height: "5.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.5mm" }}>
                      <div
                        className="hls ps23"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "56.91mm",
                        }}
                      >
                        <span className="hrt cs132">연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "57.91mm",
                    top: "0mm",
                    width: "88.62mm",
                    height: "5.52mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "0.5mm" }}>
                      <div
                        className="hls ps22"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "87.62mm",
                        }}
                      >
                        <span className="hrt cs132">투자유치액(백만원)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "0mm",
                    top: "5.52mm",
                    width: "57.91mm",
                    height: "9.51mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.49mm" }}>
                      <div
                        className="hls ps36"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "56.91mm",
                        }}
                      >
                        <span className="hrt cs131">2023년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hce"
                  style={{
                    left: "57.91mm",
                    top: "5.52mm",
                    width: "88.62mm",
                    height: "9.51mm",
                  }}
                >
                  <div className="hcD" style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className="hcI" style={{ top: "2.49mm" }}>
                      <div
                        className="hls ps6"
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "87.62mm",
                        }}
                      >
                        <Input
                          type="number"
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setTwelvePageData({
                              ...twelvePageData,
                              five:
                                e.target.value === "" ? false : e.target.value,
                            });
                          }}
                          value={
                            typeof twelvePageData.five === "string"
                              ? twelvePageData.five
                              : ""
                          }
                          className="twelve-input-number"
                          style={{ width: "40%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwelvePageComponent;
