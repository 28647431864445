const changeNumber = (value: string) => {
  const number = value.replace(/[^0-9]/g, "");

  if (number === "") {
    return "";
  }
  return Number(number);
};

const changeNumberString = (value: string) => {
  const number = value.replace(/[^0-9.]/g, "");

  if (number === "") {
    return "";
  }

  return number;
};

const returnCurrentDate = (data: any) => {
  const resUtc = new Date(data);
  const year = resUtc.getFullYear();
  const month = ("0" + (resUtc.getMonth() + 1)).slice(-2);
  const date = ("0" + resUtc.getDate()).slice(-2);

  const hours = ("0" + resUtc.getHours()).slice(-2);
  const minutes = ("0" + resUtc.getMinutes()).slice(-2);
  const seconds = ("0" + resUtc.getSeconds()).slice(-2);

  return `${year}/${month}/${date}`;
};

const returnCurrentDateTime = (data: any) => {
  const resUtc = new Date(data);
  const year = resUtc.getFullYear();
  const month = ("0" + (resUtc.getMonth() + 1)).slice(-2);
  const date = ("0" + resUtc.getDate()).slice(-2);

  const hours = ("0" + resUtc.getHours()).slice(-2);
  const minutes = ("0" + resUtc.getMinutes()).slice(-2);
  const seconds = ("0" + resUtc.getSeconds()).slice(-2);

  return `${year}/${month}/${date} ${hours}:${minutes}:${seconds}`;
};

function timeForToday(value: string | number) {
  const today = new Date();
  const timeValue = new Date(value);

  const betweenTime = Math.floor(
    (today.getTime() - timeValue.getTime()) / 1000 / 60
  );
  if (betweenTime < 1) return "방금전";
  if (betweenTime < 60) {
    return `${betweenTime}분전`;
  }

  const betweenTimeHour = Math.floor(betweenTime / 60);
  if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간전`;
  }

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
  if (betweenTimeDay < 365) {
    return `${betweenTimeDay}일전`;
  }

  return `${Math.floor(betweenTimeDay / 365)}년전`;
}

// 자동 하이푼 붙이기
function autoHypenPhone(value: string) {
  const str = value.replace(/[^0-9]/g, "");

  var tmp = "";

  if (str.length < 4) {
    return str;
  } else if (str.length < 7) {
    tmp += str.substr(0, 3);
    tmp += "-";
    tmp += str.substr(3);
    return tmp;
  } else if (str.length < 11) {
    tmp += str.substr(0, 3);
    tmp += "-";
    tmp += str.substr(3, 3);
    tmp += "-";
    tmp += str.substr(6);
    return tmp;
  } else {
    tmp += str.substr(0, 3);
    tmp += "-";
    tmp += str.substr(3, 4);
    tmp += "-";
    tmp += str.substr(7);

    return tmp.slice(0, 13);
  }
}

// 셀 자동 넓이
const autoWidth = async (worksheet: any, minimalWidth = 10) => {
  await Promise.all(
    worksheet.columns.map((column: any) => {
      let maxColumnLength = 0;
      column.eachCell({ includeEmpty: true }, (cell: any) => {
        maxColumnLength = Math.max(
          maxColumnLength,
          minimalWidth,
          cell.value
            ? cell.value.toString().length <= 10
              ? cell.value.toString().length + 5
              : cell.value.toString().length + 10
            : 0
        );
      });
      column.width = maxColumnLength + 2;
    })
  );
};

export {
  changeNumber,
  returnCurrentDate,
  returnCurrentDateTime,
  timeForToday,
  autoHypenPhone,
  changeNumberString,
  autoWidth,
};
