import React from "react";

// loading
import { MutatingDots } from "react-loader-spinner";
import styles from "./loading.module.css";

const LoadingComponent = () => {
  return (
    <>
      <div className={styles.loading}>
        <div className={styles.loading_child}>
          <MutatingDots
            height='100'
            width='100'
            color='#4fa94d'
            secondaryColor='#4fa94d'
            radius='12.5'
            ariaLabel='mutating-dots-loading'
            wrapperStyle={{}}
            wrapperClass=''
            visible={true}
          />
        </div>
      </div>
    </>
  );
};

export default LoadingComponent;
