import React from "react";

const FivePageBlock = () => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "16%",
          zIndex: 1000,
        }}
      >
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 20px",
          }}
        >
          2페이지 [선정년도] 선택 후 작성해 주세요. <br />
        </h1>
        {/* <h3
        style={{
          position: "absolute",
          top: "100%",
          left: "10%",
        }}
      >
        11페이지를 작성하셨다면 13페이지로 이동해주세요.
      </h3> */}
      </div>
      <div
        className='hpa'
        style={{ width: "210mm", height: "297mm", opacity: 0.3 }}
      >
        <div
          className='hpN'
          style={{
            left: "99.27mm",
            top: "282mm",
            width: "11.46mm",
            height: "3.53mm",
          }}
        >
          <span className='hrt cs0'>- 5 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps2'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.23mm",
                height: "4.59mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs15'>Ⅱ. Input (투입)</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "12.90mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                1. 본 융자사업과제의 수행기간(과제선정년도~과제종료년도)
                동안&nbsp;
              </span>
              <span className='hrt cs9'>총사업비 현황</span>
              <span className='hrt cs1'>을 기</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "19.51mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>재하여 주십시오.</span>
              <span className='hrt cs208'>
                (선정된 융자사업과제가 2개 이상인 경우에는 가장 최근 선정된 과
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "26.11mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs208'>제를 기준으로 작성해 주십시오)</span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "26.01mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "32.91mm",
                height: "26.01mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.50mm",
                  width: "151mm",
                  top: "0.50mm",
                  height: "26.01mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 156 31.01'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "156mm",
                    height: "31.01mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_40'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(214,214,214)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_40)'
                    d='M0,0L32.07,0L32.07,25.01L0,25.01L0,0Z '
                  />
                  <path
                    d='M0,0 L0,25.01'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M32.07,0 L32.07,25.01'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M92.37,0 L92.37,25.01'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M150,0 L150,25.01'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L150.21,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M32.01,5.30 L150.21,5.30'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M32.01,10.60 L150.21,10.60'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M32.01,19.71 L150.21,19.71'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,25.01 L150.21,25.01'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M150,0 L150,25.01'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M0,0 L0,25.01'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,25.01 L150.21,25.01'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L150.21,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "32.07mm",
                    height: "25.01mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "7.25mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.07mm",
                        }}
                      >
                        <span className='hrt cs171'>과제총사업비</span>
                      </div>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "5.82mm",
                          height: "3.53mm",
                          width: "31.07mm",
                        }}
                      >
                        <span className='hrt cs171'>(백만원)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "32.07mm",
                    top: "0mm",
                    width: "60.30mm",
                    height: "5.30mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.39mm" }}>
                      <div
                        className='hls ps103'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "1.89mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "57.42mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          &nbsp;본 융자사업 융자지원금(a)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.37mm",
                    top: "0mm",
                    width: "57.63mm",
                    height: "5.30mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.39mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "56.64mm",
                        }}
                      >
                        <span className='hrt cs165'>( &nbsp;&nbsp;</span>
                        <span className='hrt cs125'>&nbsp;&nbsp;</span>
                        <span className='hrt cs165'>
                          &nbsp;&nbsp;&nbsp;&nbsp;)백만원
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "32.07mm",
                    top: "5.30mm",
                    width: "60.30mm",
                    height: "5.30mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.39mm" }}>
                      <div
                        className='hls ps103'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "1.89mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "57.42mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          &nbsp;기업 자체자금 (b)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.37mm",
                    top: "5.30mm",
                    width: "57.63mm",
                    height: "5.30mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.39mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "56.64mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          (
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)백만원
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "32.07mm",
                    top: "10.60mm",
                    width: "60.30mm",
                    height: "9.10mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps104'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "1.89mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "57.42mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          &nbsp;타 기관 차입금 (c)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.37mm",
                    top: "10.60mm",
                    width: "57.63mm",
                    height: "9.10mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "56.64mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          (
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)백만원
                        </span>
                      </div>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "56.64mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          (기관명 :
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "32.07mm",
                    top: "19.71mm",
                    width: "60.30mm",
                    height: "5.30mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.39mm" }}>
                      <div
                        className='hls ps103'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "1.89mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "57.42mm",
                        }}
                      >
                        <span className='hrt cs165'>&nbsp;계( a+b + c )</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.37mm",
                    top: "19.71mm",
                    width: "57.63mm",
                    height: "5.30mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.39mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "56.64mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          (
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)백만원
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "2.63mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "61.47mm",
                height: "3.35mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs134'>
                &nbsp;&nbsp;※ 선정 과제가 2개 이상일 경우는 가장 최근 과제만
                기입
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "2.63mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "67.17mm",
                height: "3.35mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs134'>
                &nbsp;&nbsp;※ 타기관 차입금은 시중은행, 신용보증기금,
                기술보증기금, 중소기업진흥공단, 지방자치단체, 기타 등으로부터
                차입
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "2.63mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "72.86mm",
                height: "3.35mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs134'>한 금액을 합산하여 기입</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "2.63mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "78.55mm",
                height: "3.35mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs134'>
                &nbsp;&nbsp;※ 타기관 차입금이 없는 경우 금액을 0으로 기입
              </span>
            </div>
            <div
              className='hls ps65'
              style={{
                lineHeight: "2.63mm",
                whiteSpace: "nowrap",
                left: "2.91mm",
                top: "84.25mm",
                height: "3.35mm",
                width: "144.09mm",
              }}
            >
              <span className='hrt cs134'>
                ※ (c)의 기관명 : 예시&gt; 시중은행, 신용보증기금, 기술보증기금,
                중소기업진흥공단, &nbsp;지방자치단체, 기타 등
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "88.58mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "95.18mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                2. 귀사는 본 융자사업과제를 신청하기 전에
                제1금융권(시중은행)에서&nbsp;
              </span>
              <span className='hrt cs9'>대출을 시도하였</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "101.78mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs9'>거나</span>
              <span className='hrt cs183'>,</span>
              <span className='hrt cs11'>&nbsp;</span>
              <span className='hrt cs9'>대출을 받아본 경험</span>
              <span className='hrt cs1'>
                이 있습니까? ( &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "108.39mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;① 예 ☞ 2-1번으로 이동 &nbsp;&nbsp;&nbsp;
              </span>
              <span
                className='htC'
                style={{ left: "0.97mm", width: "1.12mm", height: "100%" }}
              />
              <span className='hrt cs1'>
                &nbsp;&nbsp;&nbsp;&nbsp;② 아니오 ☞ 3번으로 이동
              </span>
            </div>
            <div
              className='hls ps109'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "1.46mm",
                top: "114.99mm",
                height: "3.88mm",
                width: "148.54mm",
              }}
            />
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "121.60mm",
                height: "3.70mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>
                2-1. 위 2번 항목에 예라고 응답한 경우,&nbsp;
              </span>
              <span className='hrt cs124'>대출액(대출신청액) 및 대출금리</span>
              <span className='hrt cs8'>를 기재해주시기&nbsp;</span>
            </div>
            <div
              className='hls ps5'
              style={{
                paddingLeft: "7.06mm",
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "127.89mm",
                height: "3.70mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>바랍니다.&nbsp;</span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "19.27mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "134.36mm",
                height: "19.27mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "148.53mm",
                  top: "1mm",
                  height: "19.27mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.53 24.27'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "153.53mm",
                    height: "24.27mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_41'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_41)'
                    d='M0,0L51.92,0L51.92,9.11L0,9.11L0,0Z '
                  />
                  <defs>
                    <pattern
                      id='w_42'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_42)'
                    d='M51.92,0L99.23,0L99.23,9.11L51.92,9.11L51.92,0Z '
                  />
                  <path
                    fill='url(#w_42)'
                    d='M99.23,0L146.53,0L146.53,9.11L99.23,9.11L99.23,0Z '
                  />
                  <path
                    d='M0,0 L0,17.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M51.92,0 L51.92,17.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M99.23,0 L99.23,17.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M146.53,0 L146.53,17.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L146.73,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,9.11 L146.73,9.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,17.27 L146.73,17.27'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M146.53,0 L146.53,17.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M0,0 L0,17.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,17.27 L146.73,17.27'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L146.73,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "51.92mm",
                    height: "9.11mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "50.93mm",
                        }}
                      >
                        <span className='hrt cs132'>신청년도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "51.92mm",
                    top: "0mm",
                    width: "47.30mm",
                    height: "9.11mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "46.30mm",
                        }}
                      >
                        <span className='hrt cs132'>
                          제1금융권 대출액(백만원)
                        </span>
                      </div>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "46.30mm",
                        }}
                      >
                        <span className='hrt cs132'>(혹은 대출신청액)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "99.23mm",
                    top: "0mm",
                    width: "47.30mm",
                    height: "9.11mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "46.30mm",
                        }}
                      >
                        <span className='hrt cs132'>대출금리(%)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "9.11mm",
                    width: "51.92mm",
                    height: "8.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "50.93mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          (
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)년
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "51.92mm",
                    top: "9.11mm",
                    width: "47.30mm",
                    height: "8.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "46.30mm",
                        }}
                      >
                        <span className='hrt cs172'>
                          (
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <span className='hrt cs173'>&nbsp;</span>
                        <span className='hrt cs172'>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)백만원
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "99.23mm",
                    top: "9.11mm",
                    width: "47.30mm",
                    height: "8.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "46.30mm",
                        }}
                      >
                        <span className='hrt cs174'>
                          ( &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps107'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "156.16mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "162.37mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>3. 귀사가 본 융자사업과제에&nbsp;</span>
              <span className='hrt cs152'>투입한 종업원 수</span>
              <span className='hrt cs1'>
                를 과제선정년도부터 과제로 인한 매출
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "168.97mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                발생년도까지 기재해 주시기 바랍니다.
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "18.51mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "175.77mm",
                height: "18.51mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "150.01mm",
                  top: "1mm",
                  height: "18.51mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.01 23.51'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "155.01mm",
                    height: "23.51mm",
                  }}
                >
                  <path
                    d='M0,0 L0,16.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,16.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,16.52 L148.07,16.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,16.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M0,0 L0,16.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,16.52 L148.07,16.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.01mm",
                    height: "16.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "1.80mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs202'>
                          ※ 과제 선정년도 이전의 년도 투입인력은 “0”으로 기재
                        </span>
                      </div>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "5.82mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs202'>
                          &nbsp;&nbsp;&nbsp;&nbsp;(예: 2019년 선정기업은
                          2017년과 2018년은 투입인력을 “0”으로 기재
                        </span>
                      </div>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "11.82mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs202'>
                          ※ 과제로 인해 매출이 발생한 첫해년도까지만 기입하고 그
                          다음년도부터는 ‘0’으로 기입
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps24'
              style={{
                lineHeight: "20.31mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "197.01mm",
                height: "20.31mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "148.90mm",
                  top: "1mm",
                  height: "20.31mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.90 25.31'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "153.90mm",
                    height: "25.31mm",
                  }}
                >
                  <path
                    fill='url(#w_42)'
                    d='M0,0L20.13,0L20.13,7.16L0,7.16L0,0Z '
                  />
                  <path
                    fill='url(#w_42)'
                    d='M20.13,0L41.26,0L41.26,7.16L20.13,7.16L20.13,0Z '
                  />
                  <path
                    fill='url(#w_42)'
                    d='M41.26,0L62.39,0L62.39,7.16L41.26,7.16L41.26,0Z '
                  />
                  <path
                    fill='url(#w_42)'
                    d='M62.39,0L83.51,0L83.51,7.16L62.39,7.16L62.39,0Z '
                  />
                  <path
                    fill='url(#w_42)'
                    d='M83.51,0L104.64,0L104.64,7.16L83.51,7.16L83.51,0Z '
                  />
                  <path
                    fill='url(#w_42)'
                    d='M104.64,0L125.77,0L125.77,7.16L104.64,7.16L104.64,0Z '
                  />
                  <path
                    fill='url(#w_42)'
                    d='M125.77,0L146.90,0L146.90,7.16L125.77,7.16L125.77,0Z '
                  />
                  <path
                    d='M0,0 L0,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M20.13,0 L20.13,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M41.26,0 L41.26,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M62.39,0 L62.39,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M83.51,0 L83.51,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M104.64,0 L104.64,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M125.77,0 L125.77,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M146.90,0 L146.90,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.10,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,7.16 L147.10,7.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,18.31 L147.10,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M146.90,0 L146.90,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M0,0 L0,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,18.31 L147.10,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.10,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.40",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "20.13mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "19.13mm",
                        }}
                      >
                        <span className='hrt cs164'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "20.13mm",
                    top: "0mm",
                    width: "21.13mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs164'>2017년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "41.26mm",
                    top: "0mm",
                    width: "21.13mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs164'>2018년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "62.39mm",
                    top: "0mm",
                    width: "21.13mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs164'>2019년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.51mm",
                    top: "0mm",
                    width: "21.13mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs164'>2020년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "104.64mm",
                    top: "0mm",
                    width: "21.13mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs164'>2021년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "125.77mm",
                    top: "0mm",
                    width: "21.13mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs164'>2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "7.16mm",
                    width: "20.13mm",
                    height: "11.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "19.13mm",
                        }}
                      >
                        <span className='hrt cs122'>투입인력</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "20.13mm",
                    top: "7.16mm",
                    width: "21.13mm",
                    height: "11.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs175'>( &nbsp;&nbsp;)명</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "41.26mm",
                    top: "7.16mm",
                    width: "21.13mm",
                    height: "11.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs175'>( &nbsp;&nbsp;)명</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "62.39mm",
                    top: "7.16mm",
                    width: "21.13mm",
                    height: "11.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs175'>( &nbsp;&nbsp;)명</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.51mm",
                    top: "7.16mm",
                    width: "21.13mm",
                    height: "11.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs175'>( &nbsp;&nbsp;)명</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "104.64mm",
                    top: "7.16mm",
                    width: "21.13mm",
                    height: "11.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs175'>( &nbsp;&nbsp;)명</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "125.77mm",
                    top: "7.16mm",
                    width: "21.13mm",
                    height: "11.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs175'>( &nbsp;&nbsp;)명</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps4'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "218.28mm",
                height: "3mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FivePageBlock;
