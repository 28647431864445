import React, { useState, useEffect } from "react";
import SeventeenPageComponent from "./SeventeenPageComponent";
import _ from "lodash";
import { SeventeenPageDataTypes } from "gita";
import { useDispatch } from "react-redux";
import VoteAPI from "src/helpers/apis/vote/vote";
import { useLocation, useNavigate } from "react-router-dom";
import { login } from "src/store/actions/user";
import { Button, message } from "antd";
import useUnitSave from "../../../../../hooks/useUnitSave";
import { checkSubmitForm } from "src/ts/formSubmit";
import useSubmit from "src/hooks/useSubmit";
import SeventeenBlock from "./SeventeenBlock";

const SeventeenPageContainer = ({
  voteData,
  setisUpdate,
  serverUpdate,
  companyName,
  nextPage,
  unitSave,
  setUnitSave,
  setServerUpdate,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [isBlock, setIsBlock] = useState<number | boolean>(false);

  const [seventeenPageData, setSeventeenPageData] =
    useState<SeventeenPageDataTypes>();

  // 해당 페이지의 문항만 따로 저장
  useEffect(() => {
    const {
      vote: { seventeenPage, sixteenPage },
    } = voteData;

    if (sixteenPage.six) {
      setIsBlock(Number(sixteenPage.six));
    } else {
      setIsBlock(false);
    }
    setSeventeenPageData(_.cloneDeep(seventeenPage));
  }, [voteData]);

  // 데이터 저장하기
  useEffect(() => {
    if (serverUpdate && seventeenPageData) {
      console.log("제출");
      const seventeenPage: SeventeenPageDataTypes = { ...seventeenPageData };
      VoteAPI.updateVote({
        companyName,
        vote: {
          ...voteData,
          seventeenPage,
        },
      })
        .then((res) => {
          navigate(`/vote/${nextPage}/${companyName}`);
          console.log(res);
        })
        .catch((err) => {
          if (err.response.status < 500) {
            dispatch(login(false));
            messageApi.info(
              "로그인 정보가 만료 됐습니다. 다시 로그인 해 주세요."
            );
            const token = localStorage.getItem("vote_token");
          } else {
            messageApi.error("관리자에게 문의해 주세요.");
          }
        });
    }
  }, [serverUpdate]);

  useUnitSave({
    companyName,
    messageApi,
    unitSave,
    setUnitSave,
    setServerUpdate,
    setisUpdate,
    vote: {
      ...voteData,
      seventeenPage: { ...seventeenPageData },
    },
  });

  useSubmit({
    companyName,
    setUnitSave,
    setServerUpdate,
    setisUpdate,
    vote: {
      ...voteData.vote,
      seventeenPage: {
        ...seventeenPageData,
      },
    },
  });

  if (!isBlock) {
    return <SeventeenBlock />;
  }

  return (
    <div>
      {contextHolder}

      {seventeenPageData && (
        <SeventeenPageComponent
          seventeenPageData={seventeenPageData}
          setSeventeenPageData={setSeventeenPageData}
          setisUpdate={setisUpdate}
          isBlock={isBlock}
        />
      )}
    </div>
  );
};

export default SeventeenPageContainer;
