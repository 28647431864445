import { Button, Form, Input, Radio } from "antd";
import { useState } from "react";
import styles from "./gate.module.css";

const GateComponent = ({ password, setPassword, handleLogin }: any) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("vertical");
  const onFormLayoutChange = ({ layout }: any) => {
    setFormLayout(layout);
  };
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;

  return (
    <Form
      {...formItemLayout}
      layout={"vertical"}
      form={form}
      initialValues={{
        layout: formLayout,
      }}
      onValuesChange={onFormLayoutChange}
      style={{
        maxWidth: 600,
      }}
      className={styles.form_container}
      onKeyDown={(e: any) => {
        if (e.code === "Enter") {
          if (password) {
            handleLogin();
          }
        }
      }}
    >
      <div style={{ marginBottom: "40px", textAlign: "center" }}>
        <h2>인증번호를 입력해주세요.</h2>
      </div>
      <Form.Item label=''>
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value.trim())}
          style={{ height: "40px" }}
          placeholder='Input Password'
          className='form-input'
        />
      </Form.Item>
      <Button
        disabled={!password}
        onClick={handleLogin}
        style={{ width: "100%" }}
        type='primary'
      >
        Submit
      </Button>
    </Form>
  );
};
export default GateComponent;
