import React, { useEffect } from "react";

const handleRefresh = (e: any) => {
  e.preventDefault();
  e.returnValue = "";
};

const useRefresh = ({ isLogin }: any) => {
  // useEffect(() => {
  //   if (isLogin) {
  //     window.addEventListener("beforeunload", handleRefresh);
  //   }
  //   return () => {
  //     window.removeEventListener("beforeunload", handleRefresh);
  //   };
  // }, [isLogin]);
  return;
};

export default useRefresh;
