import React from "react";
import NineteenContent from "./contentList/NineteenContent";
import TwentyContent from "./contentList/TwentyContent";
import TwentyOneContent from "./contentList/TwentyOneContent";
import TwentyTwoContent from "./contentList/TwentyTwoContent";
import TwentyThreeContent from "./contentList/TwentyThreeContent";
import TwentyFourContent from "./contentList/TwentyFourContent";
import TwentyFiveContent from "./contentList/TwentyFiveContent";
import TwentySixContent from "./contentList/TwentySixContent";
import TwentySevenContent from "./contentList/TwentySevenContent";
import TwentyEightContent from "./contentList/TwentyEightContent";
import TwentyNineContent from "./contentList/TwentyNineContent";
import ThirtyContent from "./contentList/ThirtyContent";

const ModalContent = ({ setDetailPage, detailPage }: any) => {
  const pages = [
    <NineteenContent detailPage={detailPage} setDetailPage={setDetailPage} />,
    <TwentyContent detailPage={detailPage} setDetailPage={setDetailPage} />,
    <TwentyOneContent detailPage={detailPage} setDetailPage={setDetailPage} />,
    <TwentyTwoContent detailPage={detailPage} setDetailPage={setDetailPage} />,
    <TwentyThreeContent
      detailPage={detailPage}
      setDetailPage={setDetailPage}
    />,
    <TwentyFourContent detailPage={detailPage} setDetailPage={setDetailPage} />,
    <TwentyFiveContent detailPage={detailPage} setDetailPage={setDetailPage} />,
    <TwentySixContent detailPage={detailPage} setDetailPage={setDetailPage} />,
    <TwentySevenContent
      detailPage={detailPage}
      setDetailPage={setDetailPage}
    />,
    <TwentyEightContent
      detailPage={detailPage}
      setDetailPage={setDetailPage}
    />,
    <TwentyNineContent detailPage={detailPage} setDetailPage={setDetailPage} />,
    <ThirtyContent detailPage={detailPage} setDetailPage={setDetailPage} />,
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {pages[detailPage]}
    </div>
  );
};

export default ModalContent;
