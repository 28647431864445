import { get, post, put, deleteAxios } from "src/helpers/apis/api_helper";

interface LoginDataTypes {
  companyName: string;
  code: string;
}

class CompanyAPI {
  BASE_URL: string;

  constructor() {
    this.BASE_URL = "/api/company";
  }

  // 로그인
  async loginCompany(data: LoginDataTypes, config = {}) {
    const url = this.BASE_URL + "/login";
    return post(url, data, config);
  }

  // 모든 회사 리스트 가져오기
  async getCompanyList(config = {}) {
    const url = this.BASE_URL + "/all";
    return get(url, config);
  }

  // 회사 정보 가져오기
  async getCompany(config = {}) {
    const url = this.BASE_URL;
    return get(url, config);
  }

  // 제출 미제출 수정
  async putCompanyStatus(data: any, config = {}) {
    const url = this.BASE_URL + "/status";
    return put(url, data, config);
  }

  // 만들기
  async createCompany(data: any, config = {}) {
    const url = this.BASE_URL;
    return post(url, data, config);
  }

  // 만들기
  async createCompanyList(data: any, config = {}) {
    const url = this.BASE_URL + "/list";
    return post(url, data, config);
  }

  // 회사 정보 수정
  async updateCompany(data: any, config = {}) {
    const url = this.BASE_URL;
    return put(url, data, config);
  }

  // 회사 정보 삭제
  async deleteCompany(data: any) {
    const url = this.BASE_URL;
    return deleteAxios(url, data);
  }
}

export default new CompanyAPI();
