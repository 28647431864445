import React from "react";

const SeventeenBlock = () => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "23%",
          zIndex: 1000,
        }}
      >
        <div>
          <h1
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 20px",
            }}
          >
            16페이지 작성 후 이용해 주세요. <br />
          </h1>
        </div>
      </div>
      <div
        className='hpa'
        style={{ width: "210mm", height: "297mm", opacity: 0.3 }}
      >
        <div
          className='hpN'
          style={{
            left: "98.24mm",
            top: "282mm",
            width: "13.52mm",
            height: "3.53mm",
          }}
        >
          <span className='hrt cs0'>- 17 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps124'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.19mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs204'>
                &nbsp;&nbsp;6-1. 위 6번 질문에서 “매우 불충분” 또는 “불충분”으로
                답하신 경우, 융자 한도는&nbsp;
              </span>
            </div>
            <div
              className='hls ps124'
              style={{
                paddingLeft: "12.34mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "6.41mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs204'>
                어느 정도 규모가 되어야 한다고 생각하십니까?
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "13.01mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs204'>
                &nbsp;&nbsp;① 1억원 미만
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;② 1억원~2억원 미만
                &nbsp;③ 2억원~3억원 미만 &nbsp;&nbsp;
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "19.62mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs204'>
                &nbsp;&nbsp;④ 3억원~4억원 미만 &nbsp;⑤ 4억원~5억원 미만 &nbsp;⑥
                5억원~10억원 미만 ⑦ 10억원 이상
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "26.22mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "32.83mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                7. &nbsp;귀사가 본 정보통신응용기술개발지원(융자)사업의 지원을
                받지 못했을 경우,&nbsp;
              </span>
              <span className='hrt cs152'>외부&nbsp;</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "39.43mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs152'>
                금융기관으로부터 융자자금 조달 시 가장 큰 문제점
              </span>
              <span className='hrt cs1'>
                은 무엇이었다고 생각하십니까?&nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "46.03mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                (중복응답 가능) &nbsp;&nbsp;&nbsp;(
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "52.64mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;① 일반담보 부족 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;②
                금융기관의 까다로운 대출심사 &nbsp;&nbsp;&nbsp;&nbsp;③ 높은 금리
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "59.24mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;④ 적은 대출 금액 &nbsp;&nbsp;&nbsp;&nbsp;⑤ 기타(
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "65.85mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "72.45mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                8. 만약 당시 귀사가 본 정보통신응용기술개발지원(융자)사업의
                융자지원을&nbsp;
              </span>
              <span className='hrt cs152'>지원 받지&nbsp;</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "79.05mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs152'>
                못하였다면, 다음 중 어떤 상황에 직면
              </span>
              <span className='hrt cs1'>
                했을 것으로 생각하십니까? (
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "85.66mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;① 사실상 자력으로 기술개발에 필요한 자금조달이
                어려웠을 것
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "92.26mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;② 일부 자체 조달이 가능했겠지만, 금액은 융자자금에
                크게 미달했을 것
              </span>
            </div>
            <div
              className='hls ps33'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "98.87mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;③ 융자자금과 동등한 금액을 자체 조달할 수는 있었지만
                대출금리, 적기대출,&nbsp;
              </span>
            </div>
            <div
              className='hls ps33'
              style={{
                paddingLeft: "8.81mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "105.47mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                대출기간 등의 조건에서 크게 불리했을 것
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "112.07mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;④ 융자자금을 못 받았어도 별다른 영향이 없었을 것
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "118.68mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "125.28mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                9. 정보통신응용기술개발지원(융자)사업의 융자지원이 일반
                금융기관의 중소기업대출&nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "131.89mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>상품과 비교할 때&nbsp;</span>
              <span className='hrt cs152'>
                어떤 측면에서 차별성이 있어야 하는지
              </span>
              <span className='hrt cs1'>
                &nbsp;우선순위로 2순위까지만&nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "138.49mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>응답해 주십시오.</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "145.09mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs119'>
                &nbsp;&nbsp;&nbsp;&nbsp;1위: ( &nbsp;&nbsp;&nbsp;&nbsp;)
                &nbsp;&nbsp;2위: ( &nbsp;&nbsp;&nbsp;&nbsp;)&nbsp;
              </span>
            </div>
            <div
              className='hls ps102'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "151.70mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs150'>
                &nbsp;&nbsp;&nbsp;① 일반담보가 아닌 기술담보 형태의 대출
                &nbsp;&nbsp;② 적기대출, 신속한 대출절차 &nbsp;&nbsp;
              </span>
            </div>
            <div
              className='hls ps102'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "159.26mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs150'>
                &nbsp;&nbsp;&nbsp;③ 낮은 대출금리
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;④
                상대적으로 큰 금액의 대출 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
            <div
              className='hls ps102'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "166.82mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs150'>
                &nbsp;&nbsp;&nbsp;⑤ 대출기간의 장기성
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;⑥
                기타(
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
              </span>
            </div>
            <div
              className='hls ps116'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "174.39mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "178.27mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                10. 정보통신응용기술개발지원(융자)사업
              </span>
              <span className='hrt cs59'>이&nbsp;</span>
              <span className='hrt cs170'>어떻게 개선되어야 하는지</span>
              <span className='hrt cs59'>&nbsp;우선순위로 3순위</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "184.87mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs59'>까지만 응답해 주십시오.</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "191.48mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs119'>
                &nbsp;&nbsp;1위: ( &nbsp;&nbsp;&nbsp;) &nbsp;&nbsp;2위: (
                &nbsp;&nbsp;&nbsp;) &nbsp;&nbsp;3위: ( &nbsp;&nbsp;&nbsp;)
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "198.08mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;① 공고방식의 다양화 등을 통한 홍보강화 &nbsp;&nbsp;②
                지원절차 간소화
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "204.68mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;③ 융자금리 인하
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;④
                융자금액 확대
              </span>
              <span className='hrt cs1'>(융자규모 확대)</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "211.29mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;⑤ 융자금 집행 및 정산 기준 완화
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;⑥ 과제
                성과평가 기준 완화 &nbsp;
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "217.89mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs10'>
                &nbsp;&nbsp;⑦ 융자 상환기간 연장
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span className='hrt cs10'>&nbsp;&nbsp;</span>
              <span className='hrt cs10'>&nbsp;&nbsp;&nbsp;&nbsp;⑧&nbsp;</span>
              <span className='hrt cs10'>
                기타( &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span className='hrt cs10'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span className='hrt cs10'>&nbsp;)&nbsp;</span>
            </div>
            <div
              className='hls ps116'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "224.50mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeventeenBlock;
