import React from "react";

import { SixteenPageDataTypes } from "gita";
import { Input } from "antd";

interface SixteenPageComponentProps {
  sixteenPageData: SixteenPageDataTypes;
  setSixteenPageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

const SixteenPageComponent = ({
  sixteenPageData,
  setSixteenPageData,
  setisUpdate,
}: SixteenPageComponentProps) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "297mm" }}>
        <div
          className='hpN'
          style={{
            left: "98.24mm",
            top: "282mm",
            width: "13.52mm",
            height: "3.53mm",
          }}
        >
          <span className='hrt cs0'>- 16 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.23mm",
                height: "4.59mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs15'>Ⅷ</span>
              <span className='hrt cs15'>
                . 정보통신응용기술개발지원(융자)사업 개선의견
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "7.61mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "14.21mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>1. 귀사는&nbsp;</span>
              <span className='hrt cs152'>
                정보통신응용기술개발지원(융자)사업의 홍보
              </span>
              <span className='hrt cs1'>가 충분히 이루어지고 있다고 생</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "20.82mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>각하십니까?</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "27.42mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <div className='hrt cs1' style={{ marginTop: "8px" }}>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='one'
                    checked={sixteenPageData.one === "1"}
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        one: "1",
                      });
                    }}
                  />{" "}
                  전혀 그렇지 않다
                </label>{" "}
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='one'
                    checked={sixteenPageData.one === "2"}
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        one: "2",
                      });
                    }}
                  />{" "}
                  그렇지 않다
                </label>{" "}
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='one'
                    checked={sixteenPageData.one === "3"}
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        one: "3",
                      });
                    }}
                  />{" "}
                  보통이다
                </label>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='one'
                    checked={sixteenPageData.one === "4"}
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        one: "4",
                      });
                    }}
                  />{" "}
                  그렇다
                </label>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='one'
                    checked={sixteenPageData.one === "5"}
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        one: "5",
                      });
                    }}
                  />{" "}
                  매우 그렇다
                </label>
              </div>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "34.03mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "40.63mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                2. 귀사는 정보통신응용기술개발지원(융자)사업을&nbsp;
              </span>
              <span className='hrt cs152'>어떤 경로로</span>
              <span className='hrt cs1'>&nbsp;알게 되셨습니까?</span>
            </div>
            <div
              // className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "47.23mm",
                height: "3.88mm",
                width: "150mm",
              }}
            ></div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "48mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <div className='hrt cs1' style={{ marginTop: "8px" }}>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='two'
                    checked={sixteenPageData.two.number === "1"}
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        two: {
                          ...sixteenPageData.two,
                          number: "1",
                        },
                      });
                    }}
                  />{" "}
                  IITP 홈페이지
                </label>{" "}
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='two'
                    checked={sixteenPageData.two.number === "2"}
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        two: {
                          ...sixteenPageData.two,
                          number: "2",
                        },
                      });
                    }}
                  />{" "}
                  SNS
                </label>{" "}
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='two'
                    checked={sixteenPageData.two.number === "3"}
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        two: {
                          ...sixteenPageData.two,
                          number: "3",
                        },
                      });
                    }}
                  />{" "}
                  언론매체
                </label>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='two'
                    checked={sixteenPageData.two.number === "4"}
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        two: {
                          ...sixteenPageData.two,
                          number: "4",
                        },
                      });
                    }}
                  />{" "}
                  지인 소개
                </label>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='two'
                    checked={sixteenPageData.two.number === "5"}
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        two: {
                          ...sixteenPageData.two,
                          number: "5",
                        },
                      });
                    }}
                  />{" "}
                  기타
                </label>
                <Input
                  type='text'
                  disabled={sixteenPageData.two.number !== "5"}
                  onChange={(e: any) => {
                    setisUpdate(true);

                    setSixteenPageData({
                      ...sixteenPageData,
                      two: {
                        ...sixteenPageData.two,
                        text: e.target.value,
                      },
                    });
                  }}
                  value={sixteenPageData.two.text}
                  style={{ marginLeft: "8px", width: "200px" }}
                />
                <br />
              </div>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "60.44mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "67.05mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>3. 귀사는</span>
              <span className='hrt cs119'>
                &nbsp;정보통신응용기술개발지원(융자)사업을&nbsp;
              </span>
              <span className='hrt cs152'>
                어떤 방법으로 홍보하는 것이 가장&nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "73.65mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs152'>좋다</span>
              <span className='hrt cs1'>고 생각하십니까?</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "78.25mm",
                height: "5.88mm",
                width: "150mm",
              }}
            >
              <div className='hrt cs1' style={{ marginTop: "8px" }}>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='three'
                    checked={sixteenPageData.three.number === "1"}
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        three: {
                          ...sixteenPageData.three,
                          number: "1",
                        },
                      });
                    }}
                  />{" "}
                  IITP 홈페이지
                </label>{" "}
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='three'
                    checked={sixteenPageData.three.number === "2"}
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        three: {
                          ...sixteenPageData.three,
                          number: "2",
                        },
                      });
                    }}
                  />{" "}
                  SNS
                </label>{" "}
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='three'
                    checked={sixteenPageData.three.number === "3"}
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        three: {
                          ...sixteenPageData.three,
                          number: "3",
                        },
                      });
                    }}
                  />{" "}
                  언론매체
                </label>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='three'
                    checked={sixteenPageData.three.number === "4"}
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        three: {
                          ...sixteenPageData.three,
                          number: "4",
                        },
                      });
                    }}
                  />{" "}
                  지인 소개
                </label>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='three'
                    checked={sixteenPageData.three.number === "5"}
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        three: {
                          ...sixteenPageData.three,
                          number: "5",
                        },
                      });
                    }}
                  />{" "}
                  기타
                </label>
                <Input
                  type='text'
                  disabled={sixteenPageData.three.number !== "5"}
                  onChange={(e: any) => {
                    setisUpdate(true);

                    setSixteenPageData({
                      ...sixteenPageData,
                      three: {
                        ...sixteenPageData.three,
                        text: e.target.value,
                      },
                    });
                  }}
                  value={sixteenPageData.three.text}
                  style={{ marginLeft: "8px", width: "200px" }}
                />
              </div>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "86.86mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "93.46mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                4. 귀사가 정보통신응용기술개발지원(융자)사업의&nbsp;
              </span>
              <span className='hrt cs152'>융자를 신청한 가장 주된 이유</span>
              <span className='hrt cs1'>는 다</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "100.07mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                음 중 무엇이었습니까? (중복응답 가능)
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "31.63mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "106.86mm",
                height: "31.63mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>&nbsp;&nbsp;</span>
              <div
                className='htb'
                style={{
                  left: "0.50mm",
                  width: "146.01mm",
                  top: "0.50mm",
                  height: "31.63mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "67.02mm",
                    height: "40.64mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps26'
                        style={{
                          lineHeight: "2.94mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.70mm",
                          width: "65.01mm",
                        }}
                      >
                        <span className='hrt cs8'>
                          <label>
                            <input
                              type='checkbox'
                              checked={sixteenPageData.four[0]?.number}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setisUpdate(true);
                                setSixteenPageData({
                                  ...sixteenPageData,
                                  four: sixteenPageData.four.map(
                                    (item, index) => {
                                      if (index === 0) {
                                        return {
                                          number: e.target.checked,
                                          text: "",
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                });
                              }}
                            />{" "}
                            기술담보로 융자가 가능해서
                          </label>
                        </span>
                      </div>
                      <div
                        className='hls ps26'
                        style={{
                          lineHeight: "2.94mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "6.30mm",
                          height: "3.70mm",
                          width: "65.01mm",
                        }}
                      >
                        <span className='hrt cs8'>
                          <label>
                            <input
                              type='checkbox'
                              checked={sixteenPageData.four[2]?.number}
                              onChange={(e) => {
                                setisUpdate(true);

                                setSixteenPageData({
                                  ...sixteenPageData,
                                  four: sixteenPageData.four.map(
                                    (item, index) => {
                                      if (index === 2) {
                                        return {
                                          number: e.target.checked,
                                          text: "",
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                });
                              }}
                            />{" "}
                            저금리여서
                          </label>
                        </span>
                      </div>
                      <div
                        className='hls ps26'
                        style={{
                          lineHeight: "2.94mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "12.78mm",
                          height: "3.70mm",
                          width: "65.01mm",
                        }}
                      >
                        <span className='hrt cs8'>
                          <label>
                            <input
                              type='checkbox'
                              checked={sixteenPageData.four[4]?.number}
                              onChange={(e) => {
                                setisUpdate(true);

                                setSixteenPageData({
                                  ...sixteenPageData,
                                  four: sixteenPageData.four.map(
                                    (item, index) => {
                                      if (index === 4) {
                                        return {
                                          number: e.target.checked,
                                          text: "",
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                });
                              }}
                            />{" "}
                            은행 대출이 곤란해서 (담보부족 등)&nbsp;
                          </label>
                        </span>
                      </div>
                      <div
                        className='hls ps26'
                        style={{
                          lineHeight: "2.94mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "19.27mm",
                          height: "3.70mm",
                          width: "65.01mm",
                        }}
                      >
                        <span className='hrt cs8'>
                          <label>
                            <input
                              type='checkbox'
                              checked={sixteenPageData.four[6]?.number}
                              onChange={(e) => {
                                setisUpdate(true);

                                setSixteenPageData({
                                  ...sixteenPageData,
                                  four: sixteenPageData.four.map(
                                    (item, index) => {
                                      if (index === 6) {
                                        return {
                                          number: e.target.checked,
                                          text: "",
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                });
                              }}
                            />{" "}
                            대출절차가 편리해서
                          </label>
                        </span>
                      </div>
                      <div
                        className='hls ps26'
                        style={{
                          lineHeight: "2.94mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "25.75mm",
                          height: "3.70mm",
                          width: "65.01mm",
                        }}
                      >
                        <span className='hrt cs8'>
                          <label>
                            <input
                              type='checkbox'
                              checked={sixteenPageData.four[8]?.number}
                              onChange={(e) => {
                                setisUpdate(true);

                                setSixteenPageData({
                                  ...sixteenPageData,
                                  four: sixteenPageData.four.map(
                                    (item, index) => {
                                      if (index === 8) {
                                        return {
                                          number: e.target.checked,
                                          text: "",
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                });
                              }}
                            />{" "}
                            기타
                          </label>
                        </span>
                        <Input
                          type='text'
                          disabled={!sixteenPageData.four[8].number}
                          onChange={(e: any) => {
                            setisUpdate(true);

                            setSixteenPageData({
                              ...sixteenPageData,
                              four: sixteenPageData.four.map((item, index) => {
                                if (index === 8) {
                                  return {
                                    number: true,
                                    text: e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={sixteenPageData.four[8].text}
                          style={{ marginLeft: "8px", width: "200px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "67.02mm",
                    top: "0mm",
                    width: "78mm",
                    height: "30.64mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.50mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps26'
                        style={{
                          lineHeight: "2.94mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.70mm",
                          width: "76mm",
                        }}
                      >
                        <span className='hrt cs8'>
                          <label>
                            <input
                              type='checkbox'
                              checked={sixteenPageData.four[1]?.number}
                              onChange={(e) => {
                                setisUpdate(true);

                                setSixteenPageData({
                                  ...sixteenPageData,
                                  four: sixteenPageData.four.map(
                                    (item, index) => {
                                      if (index === 1) {
                                        return {
                                          number: e.target.checked,
                                          text: "",
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                });
                              }}
                            />{" "}
                            적기에 대출(신속한 대출)이 가능하기 때문
                          </label>
                        </span>
                      </div>
                      <div
                        className='hls ps26'
                        style={{
                          lineHeight: "2.94mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "6.30mm",
                          height: "3.70mm",
                          width: "76mm",
                        }}
                      >
                        <span className='hrt cs8'>
                          <label>
                            <input
                              type='checkbox'
                              checked={sixteenPageData.four[3]?.number}
                              onChange={(e) => {
                                setisUpdate(true);

                                setSixteenPageData({
                                  ...sixteenPageData,
                                  four: sixteenPageData.four.map(
                                    (item, index) => {
                                      if (index === 3) {
                                        return {
                                          number: e.target.checked,
                                          text: "",
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                });
                              }}
                            />{" "}
                            대출상환기간이 길어서&nbsp;
                          </label>
                        </span>
                      </div>
                      <div
                        className='hls ps26'
                        style={{
                          lineHeight: "2.94mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "12.78mm",
                          height: "3.70mm",
                          width: "76mm",
                        }}
                      >
                        <span className='hrt cs8'>
                          <label>
                            <input
                              type='checkbox'
                              checked={sixteenPageData.four[5]?.number}
                              onChange={(e) => {
                                setisUpdate(true);

                                setSixteenPageData({
                                  ...sixteenPageData,
                                  four: sixteenPageData.four.map(
                                    (item, index) => {
                                      if (index === 5) {
                                        return {
                                          number: e.target.checked,
                                          text: "",
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                });
                              }}
                            />{" "}
                            대출 금액이 커서 &nbsp;
                          </label>
                        </span>
                      </div>
                      <div
                        className='hls ps26'
                        style={{
                          lineHeight: "2.94mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "19.27mm",
                          height: "3.70mm",
                          width: "76mm",
                        }}
                      >
                        <span className='hrt cs8'>
                          <label>
                            <input
                              type='checkbox'
                              checked={sixteenPageData.four[7]?.number}
                              onChange={(e) => {
                                setisUpdate(true);

                                setSixteenPageData({
                                  ...sixteenPageData,
                                  four: sixteenPageData.four.map(
                                    (item, index) => {
                                      if (index === 7) {
                                        return {
                                          number: e.target.checked,
                                          text: "",
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                });
                              }}
                            />{" "}
                            정부출연지원사업(보조금)이 부족하기 때문
                          </label>
                        </span>
                      </div>

                      <div
                        className='hls ps26'
                        style={{
                          lineHeight: "2.94mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "25.75mm",
                          height: "3.70mm",
                          width: "76mm",
                        }}
                      >
                        <span className='hrt cs8'>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "141.02mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "147.63mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                5. 귀사가 본 정보통신응용기술개발지원(융자)사업의 융자지원을
                받지 못하였다면,&nbsp;
              </span>
              <span className='hrt cs152'>자력</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "154.23mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs152'>
                으로 조달 가능한 금액은 필요한 기술개발비 대비 어느 정도
              </span>
              <span className='hrt cs1'>였다고 생각하십니까?&nbsp;</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "160.83mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              {/* <span className='hrt cs1'>( &nbsp;&nbsp;&nbsp;&nbsp;)</span> */}
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "167.44mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='five'
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        five: "1",
                      });
                    }}
                    checked={sixteenPageData.five === "1"}
                  />{" "}
                  필요 기술개발 자금의 90% 이상
                </label>
                &nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='five'
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        five: "2",
                      });
                    }}
                    checked={sixteenPageData.five === "2"}
                  />{" "}
                  필요 기술개발 자금의 80~89%
                </label>
                &nbsp;&nbsp;&nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "174.04mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='five'
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        five: "3",
                      });
                    }}
                    checked={sixteenPageData.five === "3"}
                  />{" "}
                  필요 기술개발 자금의 70~79%
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='five'
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        five: "4",
                      });
                    }}
                    checked={sixteenPageData.five === "4"}
                  />{" "}
                  필요 기술개발 자금의 60~69%
                </label>
                &nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "180.65mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='five'
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        five: "5",
                      });
                    }}
                    checked={sixteenPageData.five === "5"}
                  />{" "}
                  필요 기술개발 자금의 50~59%
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='five'
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        five: "6",
                      });
                    }}
                    checked={sixteenPageData.five === "6"}
                  />{" "}
                  필요 기술개발 자금의 50% 미만
                </label>
                &nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "187.25mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='five'
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        five: "7",
                      });
                    }}
                    checked={sixteenPageData.five === "7"}
                  />{" "}
                  잘 모르겠음
                </label>
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "193.85mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "200.46mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                6. 귀사는 정보통신응용기술개발지원(융자)사업의&nbsp;
              </span>
              <span className='hrt cs152'>융자 한도가 충분한 규모</span>
              <span className='hrt cs1'>였다고 생각</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "207.06mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>하십니까?</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "213.67mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        six: "1",
                      });
                    }}
                    checked={sixteenPageData.six === "1"}
                    name='six'
                    value='1'
                  />{" "}
                  매우불충분
                </label>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        six: "2",
                      });
                    }}
                    checked={sixteenPageData.six === "2"}
                    name='six'
                  />{" "}
                  불충분
                </label>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        six: "3",
                      });
                    }}
                    checked={sixteenPageData.six === "3"}
                    name='six'
                  />{" "}
                  보통
                </label>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        six: "4",
                      });
                    }}
                    checked={sixteenPageData.six === "4"}
                    name='six'
                  />{" "}
                  충분
                </label>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);

                      setSixteenPageData({
                        ...sixteenPageData,
                        six: "5",
                      });
                    }}
                    checked={sixteenPageData.six === "5"}
                    name='six'
                  />{" "}
                  매우 충분
                </label>
                &nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "220.27mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "226.87mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SixteenPageComponent;
