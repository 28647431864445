import { Route, Routes, Navigate } from "react-router-dom";
import { routes } from "../routes";

function Layout() {
  const layoutRoute = () => {
    return routes.map((item, index) => (
      <Route key={index} path={item.path} element={<item.component />} />
    ));
  };

  return (
    <div className='App'>
      <Routes>
        {layoutRoute()}
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </div>
  );
}

export default Layout;
