import { get, post, put, deleteAxios } from "src/helpers/apis/api_helper";

interface LoginDataTypes {
  companyName: string;
  code: string;
}

class VoteAPI {
  BASE_URL: string;

  constructor() {
    this.BASE_URL = "/api/vote";
  }

  // 설문조사 자료 가져오기
  async getVote(config = {}) {
    const url = this.BASE_URL;
    return get(url, config);
  }

  // 설문조사 자료 업데이트
  async updateVote(data: any, config = {}) {
    const url = this.BASE_URL;
    return put(url, data, config);
  }

  // 체크한 회사들 다운받기
  async getVoteList(config = {}) {
    const url = this.BASE_URL + "/list";
    return get(url, config);
  }
}

export default new VoteAPI();
